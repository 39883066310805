import React from "react";
import PropTypes from "prop-types";
import SimpleBarReact from "simplebar-react";
import { Table } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  noDataMessage,
  isLoading,
}) => {
  // if (page.length === 0) {
  //   return (
  //     <div className="text-center mt-4">
  //       <h4>No match found</h4>
  //       <h6>Try searching again or expanding the range of your filters</h6>
  //     </div>
  //   );
  // }

  if (page && page.length === 0 && noDataMessage) {
    return (
      <div className="text-center mt-4">
        <h4>{noDataMessage.title || "No Data Found"}</h4>
        <h6>{noDataMessage.subtitle || ""}</h6>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="text-center mt-4">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <SimpleBarReact>
      <Table {...getTableProps(tableProps)}>
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}
              >
                {column.render("Header")}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )
                ) : (
                  ""
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} className={rowClassName} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      {...cell.getCellProps(cell.column.cellProps)}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </SimpleBarReact>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  noDataMessage: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
};

export default AdvanceTable;
