import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Row, Button, FloatingLabel, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Flex from "../../../components/common/Flex";
import AppContext from "../../../context/Context";
import { faCartPlus, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { addSolution, getSelectedSolutions } from "../solutionsAPI";
import { SolutionContext } from "../../../context/Context";

const SolutionModal = () => {
  console.log("In SolutionModal");

  const {
    solutionsState: {
      cartModal: { show, solution, type, businessId, userId, solutionId },
    },
    solutionsDispatch,
  } = useContext(SolutionContext);

  const [itemKey, setItemKey] = useState();
  const [selectedSolutions, setSelectedSolutions] = useState();
  const [locationData, setLocationData] = useState("");
  const [nameId, setNameId] = useState();
  const [selectedLocation, setSelectedLocation] = useState([]);

  //add selected solution to list
  async function handleAddSolution(businessId, solutionId) {
    const index = selectedLocation.findIndex(
      (l) => l.solutionId === solutionId
    );

    const body = {
      businessId: businessId,
      officeId: nameId[selectedLocation[index]["location"]],
      solutionId: solutionId,
      status: "InProgress",
      tasks: solution.tasks,
      solution: solution.solution,
      createdBy: userId,
      updatedBy: userId,
    };
    console.log(body);
    await addSolution(body);
    window.location = "/welcome/solutions/my-solutions";
  }

  //when a solution is selected for a location
  function handleSelection(e, id) {
    console.log(e.currentTarget.value, id);
    e.preventDefault();
    setItemKey(e.currentTarget.value);
    const jsonSelectedLocation = {
      solutionId: id,
      location: e.currentTarget.value,
    };
    let locationList = [];
    locationList.push(jsonSelectedLocation);
    setSelectedLocation(locationList);
  }

  //fetch list of all selected solution w.r.t office
  async function getSelectedLocationsList() {
    const solutions = await getSelectedSolutions();
    //store all selected solutions in session as string
    let selectedSolutions = [];
    for (let i in solutions) selectedSolutions.push(solutions[i]);
    sessionStorage.setItem(
      "selectedSolutions",
      JSON.stringify(selectedSolutions)
    );
    setSelectedSolutions(solutions);
  }

  //fetch list of offices for the user
  function getOffice() {
    const businessOffices = JSON.parse(
      sessionStorage.getItem("businessOffices")
    );
    let nameIdDict = {};
    for (let i = 0; i < businessOffices.length; i++) {
      let str = businessOffices[i].split(",");
      nameIdDict[str[0]] = str[1];
    }
    setNameId(nameIdDict);
    setLocationData(JSON.parse(sessionStorage.getItem("officeName")));
  }

  useEffect(() => {
    getOffice();
    getSelectedLocationsList();
  }, []);

  const {
    config: { isDark },
  } = useContext(AppContext);

  const handleClose = () => {
    solutionsDispatch({
      type: "TOGGLE_CART_MODAL",
    });
  };

  console.log(show, solution, type, businessId, userId, solutionId);
  console.log(selectedLocation);
  return (
    <Modal show={show} onHide={handleClose} size="lg" key={solutionId}>
      <Modal.Header
        closeButton
        closeVariant={isDark ? "white" : null}
        className="border-200"
      >
        <Modal.Title as="h5">
          <Flex alignItems="center">
            <div className="icon-item bg-soft-success shadow-none">
              <FontAwesomeIcon icon={faPenToSquare} className="text-success" />
            </div>
            {/* <div className="ms-2">
              You just {type === "remove" ? "removed" : "added"} {quantity} item
              {quantity === 1 ? "" : "s"}
            </div> */}
            <div className="ms-2">Select a location to save your solution</div>
          </Flex>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-0 align-items-center">
          <Col>
            <Flex alignItems="center" justify-content="space-between">
              {/* <Link to={`/e-commerce/product/product-details/${product.id}`}>
                <Image
                  src={product.files && product.files[0].src}
                  rounded
                  className="me-3 d-none d-md-block"
                  width="80"
                  alt="product image"
                />
              </Link> */}
              <div className="flex-1">
                <h5 className="fs-0">{solution.title}</h5>
              </div>
              <FloatingLabel
                controlId="floatingSelectGrid"
                label="Location Selection"
              >
                <Form.Select
                  size="sm"
                  className="me-2"
                  required
                  onChange={(e) => {
                    handleSelection(e, solutionId);
                  }}
                >
                  <option>Select...</option>
                  {locationData
                    ? locationData.map((item, i) => (
                        <option key={item.id} value={item}>
                          {item}
                        </option>
                      ))
                    : null}
                </Form.Select>
              </FloatingLabel>
            </Flex>
          </Col>
          {/* <Col sm="auto" className="ps-sm-3 d-none d-sm-block">
            ${product.totalPrice}
          </Col> */}
        </Row>
      </Modal.Body>
      {type !== "remove" && (
        <Modal.Footer className="border-200">
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              handleAddSolution(businessId, solutionId);
            }}
            disabled={
              (selectedLocation.findIndex(
                (l) => l.solutionId === solutionId
              ) === -1 &&
                selectedLocation.findIndex((l) => l.location === itemKey) ===
                  -1) ||
              (selectedLocation.findIndex(
                (l) => l.solutionId === solutionId
              ) !== -1 &&
                selectedLocation.findIndex((l) => l.location === itemKey) !==
                  -1 &&
                selectedLocation.findIndex(
                  (l) => l.solutionId === solutionId && l.location === itemKey
                ) !== -1 &&
                selectedSolutions.findIndex(
                  (s) => s.solutionId === solutionId
                ) !== -1 &&
                selectedSolutions.findIndex(
                  (s) => s.officeId === nameId[itemKey]
                ) !== -1 &&
                selectedSolutions.findIndex(
                  (s) =>
                    s.officeId === nameId[itemKey] &&
                    s.solutionId === solutionId
                ) !== -1) ||
              selectedLocation.findIndex((l) => l.solutionId === solutionId) ===
                -1 ||
              selectedLocation.findIndex((l) => l.location === itemKey) === -1
            }
          >
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default SolutionModal;
