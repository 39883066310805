import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import Divider from "../../components/common/Divider";
import SocialAuthButtons from "./SocialAuthButtons";
import { useAuth } from "../../Contexts/AuthContext";
import { errorMessage } from "./ErrorData";
import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";
import { auth } from "../../firebase";
import { setIdToken, setUserData } from "../../helpers/store";
import { jwtDecode } from "jwt-decode";
import { getAllWfhFormData } from "../wfh/wfhAPI";

const RegistrationForm = ({ hasLabel }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [inviteeBusinessId, setInviteeBusinessId] = useState(
    queryParams.get("inviteeBusinessId")
  );
  // console.log("Registration business", inviteeBusinessId);

  const [inviteRole, setInviteRole] = useState(
    queryParams.get("inviteRole") || ""
  );
  const [wfhFormId, setWfhFormId] = useState(
    queryParams.get("wfhFormId")
      ? parseInt(queryParams.get("wfhFormId"), 10)
      : null
  );

  // console.log("Registration role", inviteRole);

  const [token] = useState(queryParams.get("token") || "");
  // console.log("Registration token", token);

  const [invitedSupplierId, setInvitedSupplierId] = useState("");
  // console.log("Registration supplier", invitedSupplierId);

  const [invitedSupplierName, setInvitedSupplierName] = useState("");
  // console.log("Registration supplier name", supplierName);

  const [reportingYear, setReportingYear] = useState("");
  // console.log("Registration year", reportingYear);

  const [invitedBy, setInvitedBy] = useState("");

  const [employeeEngagementFormId, setEmployeeEngagementFormId] =
    useState(null);
  // console.log("Registration invitedBy", invitedBy);

  // State
  const [formData, setFormData] = useState({
    givenName: "",
    familyName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    isAccepted: false,
  });

  //firebase authentication
  const {
    signup,
    currentUser,
    loginWithGoogle,
    updateUserProfile,
    sendEmailVerificationMail,
  } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [xero, setXero] = useState(sessionStorage.getItem("xero"));

  //api
  async function register(body, headers) {
    try {
      let url = `/api/users/register`;

      if (inviteeBusinessId !== null) {
        url = `/api/users/registerInvite?inviteeBusinessId=${inviteeBusinessId}`;
      } else if (
        invitedSupplierId !== null &&
        inviteRole === "SUPPLIER" &&
        invitedSupplierName !== null &&
        reportingYear !== null &&
        invitedBy !== null
      ) {
        url = `/api/users/registerSupplier?invitedSupplierId=${invitedSupplierId}&invitedSupplierName=${invitedSupplierName}&reportingYear=${reportingYear}&invitedBy=${invitedBy}`;
      }

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      });

      if (response.status !== 200) {
        throw new Error();
      }

      toast(
        `Successfully registered as ${body.givenName + " " + body.familyName}`
      );

      if (body.employeeEngagementFormId) {
        window.location.href =
          "/authentication/card/confirmEmail/" + body.employeeEngagementFormId;
      } else {
        window.location.href = "/authentication/card/confirmEmail";
      }
    } catch (err) {
      console.log(err);
      console.error(err.message);
    }
  }
  // Handler
  async function handleSubmit(e) {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);

      //to store full name on firebase
      const fullName = formData.givenName + " " + formData.familyName;
      //console.log(fullName)

      //to display email on confirmationMail.js
      sessionStorage.setItem("useremail", formData.email);

      //sign up on firebase
      //const response = await signup(fullName, formData.email, formData.password, formData.phoneNumber, formData.givenName, formData.familyName)
      const response = await signup(formData.email, formData.password);
      // console.log(response);

      if (response.user.hasOwnProperty("message")) {
        const code = response.user.code;
        setError(errorMessage[code]);
      } else {
        if (response.hasOwnProperty("user")) {
          const updatedUser = await updateUserProfile(
            fullName,
            formData.phoneNumber
          );
          // console.log(updatedUser);

          //send verification email
          await sendEmailVerificationMail();

          //push data to db
          // console.log(response.user.user.uid);
          const body = {
            uid: response.user.user.uid,
            email: response.user.user.email,
            givenName: formData.givenName,
            familyName: formData.familyName,
            number: formData.phoneNumber,
            authProvider: "",
            inviteRole: inviteRole,
            wfhFormId: wfhFormId,
            employeeEngagementFormId: employeeEngagementFormId,
          };

          setIdToken(response.idToken);
          setUserData(JSON.stringify(body));

          const headers = {
            Authorization: "Bearer " + response.idToken,
            "Content-Type": "application/json",
          };
          await register(body, headers);
        }
      }
    } catch (error) {
      setError("Failed to create an account");
    }
    setLoading(false);
  }

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  //google registration
  async function handleRedirectToOrBack() {
    try {
      //login in with google
      const loginResponse = await loginWithGoogle();
      const userRes = loginResponse.user;
      const temp = JSON.stringify(userRes);
      let user = JSON.parse(temp);
      user["businessId"] = inviteeBusinessId;
      user["inviteRole"] = inviteRole;
      user["supplierId"] = invitedSupplierId;
      user["supplierName"] = invitedSupplierName;
      user["reportingYear"] = reportingYear;
      user["invitedBy"] = invitedBy;
      user["wfhFormId"] = wfhFormId;

      try {
        const googleLoginResponse = await fetch(
          "/api/users/google-token/" + loginResponse.user.uid.toString(),
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + loginResponse.idToken,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
          }
        );
        if (googleLoginResponse.status !== 200) {
          throw new Error();
        }

        const googleLoginResponseData = await googleLoginResponse.json();
        // console.log(googleLoginResponseData);
        const userData = googleLoginResponseData?.user;
        // console.log("userData Registration Form", userData);
        // console.log(userData);
        const newIdToken = await auth.currentUser.getIdToken(true);

        if (userData !== undefined) {
          setIdToken(newIdToken);
          setUserData(JSON.stringify(userData));
          toast(`Logged in as ${userData.email}`);
          if (userData?.role === "EMPLOYEE") {
            if (userData?.wfhFormId) {
              const wfhFormId = userData?.wfhFormId;
              const userWfhForms = await getAllWfhFormData(wfhFormId);
              const peopleIds = userWfhForms.map((item) => item.people_id);
              const isUserInPeopleIds = peopleIds.includes(user.uid);

              // Check if the user has submitted a WFH form or not
              if (isUserInPeopleIds) {
                window.location.href = "/welcome/data/wfh";
              } else {
                window.location.href = `/welcome/data/wfh-form-data?wfhFormId=${wfhFormId}`;
              }
            } else {
              window.location.href = "/welcome/data/wfh";
            }
          } else {
            window.location.href = "/welcome";
            //navigate("/welcome");
          }

          // window.location.href = "/welcome";
        }
      } catch (err) {
        console.error(err.message);
      }
    } catch (error) {
      setError("Failed to create an account");
    }
  }

  // decode token
  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);

      const {
        invitedSupplierId,
        inviteRole,
        invitedSupplierName,
        reportingYear,
        invitedBy,
        inviteeBusinessId,
        wfhFormId,
        employeeEngagementFormId,
      } = decoded;

      // if not undefined then set

      // setInvitedSupplierId(invitedSupplierId);
      // setInviteRole(inviteRole);
      // setInvitedSupplierName(invitedSupplierName);
      // setReportingYear(reportingYear);
      // setInvitedBy(invitedBy);

      if (invitedSupplierId !== undefined)
        setInvitedSupplierId(invitedSupplierId);
      if (inviteRole !== undefined) setInviteRole(inviteRole);
      if (invitedSupplierName !== undefined)
        setInvitedSupplierName(invitedSupplierName);
      if (reportingYear !== undefined) setReportingYear(reportingYear);
      if (invitedBy !== undefined) setInvitedBy(invitedBy);
      if (inviteeBusinessId !== undefined)
        setInviteeBusinessId(inviteeBusinessId);
      if (wfhFormId !== undefined) setWfhFormId(wfhFormId);
      if (employeeEngagementFormId !== undefined)
        setEmployeeEngagementFormId(employeeEngagementFormId);
    }
  }, [token]);

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Given Name</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? "Given Name" : ""}
            value={formData.givenName}
            name="givenName"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Family Name</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? "Family Name" : ""}
            value={formData.familyName}
            name="familyName"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Email address" : ""}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Mobile/Phone Number</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Mobile/Phone Number" : ""}
          value={formData.phoneNumber}
          name="phoneNumber"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? "Password" : ""}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? "Confirm Password" : ""}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            checked={formData.isAccepted}
            onChange={(e) =>
              setFormData({
                ...formData,
                isAccepted: e.target.checked,
              })
            }
          />
          <Form.Check.Label className="form-label">
            I accept the{" "}
            <a
              href="https://www.netnada.com.au/netnada-terms-and-conditions"
              target="_blank"
            >
              terms
            </a>{" "}
            and{" "}
            <a
              href="https://www.netnada.com.au/netnada-terms-and-conditions"
              target="_blank"
            >
              conditions{" "}
            </a>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.givenName ||
            !formData.familyName ||
            !formData.email ||
            !formData.phoneNumber ||
            !formData.password ||
            !formData.confirmPassword ||
            !formData.isAccepted
          }
        >
          Register
        </Button>
        <ToastContainer />
      </Form.Group>
      <Divider>or register with</Divider>

      <SocialAuthButtons
        handleRedirectToOrBack={handleRedirectToOrBack}
        xero={xero}
      />
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default RegistrationForm;
