import React, { useEffect, useState } from "react";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import PropTypes from "prop-types";
import { Card, Nav, Row, Tab } from "react-bootstrap";
import Flex from "../../components/common/Flex";
import { getAllPartners } from "./partnersAPI";
import PartnerCard from "./partnerCard";
import { useAuth } from "../../Contexts/AuthContext";

const NavItem = ({ item }) => {
  return (
    <Nav.Item as="li">
      <Nav.Link
        className="mb-0 py-3 cursor-pointer"
        eventKey={item.toLowerCase()}
      >
        {item}
      </Nav.Link>
    </Nav.Item>
  );
};

const Partners = () => {
  const { currentUser } = useAuth();
  useEffect(() => {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser.uid);
    setUserProperties(analytics, { businessId: currentUser.businessId });
    logEvent(analytics, "page_view", {
      page_title: "All Partners",
    });
  }, [currentUser]);

  const [allData, setAllData] = useState();
  const [energyData, setEnergyData] = useState();
  const [WRData, setWRData] = useState();
  // const [travelData, setTravelData] = useState()
  // const [SIData, setSIData] = useState()

  async function getPartners() {
    const data = await getAllPartners();
    console.log(data);
    setAllData(data);

    let result = [];

    //energy
    result = data.filter((data) => {
      return data.type === "Energy";
    });
    setEnergyData(result);

    //Waste and Recycling
    result = data.filter((data) => {
      return data.type === "Waste Management";
    });
    setWRData(result);

    // //Travel
    // result = data.filter((data) => {
    //   return data.category === 'Travel';
    // });
    // setTravelData(result)

    // //Staff Involvement
    // result = data.filter((data) => {
    //   return data.category === 'Staff Involvement';
    // });
    // setSIData(result)
  }

  useEffect(() => {
    getPartners();
  }, []);

  const [navItems] = useState(["All", "Energy", "Waste Management"]);
  console.log(allData);
  return (
    <Card className="mt-3">
      <Tab.Container id="audience-tab" defaultActiveKey="all">
        <Card.Header
          as={Flex}
          justifyContent="between"
          alignItems="center"
          className="ps-0 py-0 border-bottom"
        >
          <Nav
            as="ul"
            className="nav-tabs border-0 flex-nowrap chart-tab tab-active-caret"
          >
            {navItems.map((item) => (
              <NavItem item={item} key={item} />
            ))}
          </Nav>
        </Card.Header>

        <Card.Body>
          <Row>
            <Tab.Content>
              <Tab.Pane unmountOnExit eventKey="all">
                <PartnerCard data={allData} />
              </Tab.Pane>
              <Tab.Pane unmountOnExit eventKey="energy">
                <PartnerCard data={energyData} />
              </Tab.Pane>
              <Tab.Pane unmountOnExit eventKey="waste management">
                <PartnerCard data={WRData} />
              </Tab.Pane>
              {/* <Tab.Pane unmountOnExit eventKey="travel">
                <SolutionCard data={travelData} />
              </Tab.Pane>
              <Tab.Pane unmountOnExit eventKey="staff involvement">
                <SolutionCard data={SIData} />
              </Tab.Pane> */}
            </Tab.Content>
          </Row>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};

NavItem.propTypes = {
  item: PropTypes.string.isRequired,
};

export default Partners;
