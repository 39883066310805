import React from "react";
import PropTypes from "prop-types";
import { Accordion, Badge, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faRedo,
  faStream,
  faBan,
  faExchange,
  faCartPlus,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../components/common/IconButton";
import useSolutionHook from "./useSolutionHook";
import StarRating from "../../../components/common/StarRating";
import { useAuth } from "../../../Contexts/AuthContext";

const SolutionList = ({ solutionData, index }) => {
  console.log("In SolutionList");
  const { currentUser } = useAuth();
  const { title, category, id, solution, website, tasks, ratings } =
    solutionData;
  console.log(solutionData);

  const { handleAddToCart } = useSolutionHook(solutionData);

  return (
    <>
      <Col
        xs={12}
        className={classNames("p-card", {
          "bg-100": index % 2 !== 0,
        })}
      >
        <Row>
          <Col sm={12} md={12}>
            <Row className="h-100">
              <h5 className="mt-3 mt-sm-0">{title}</h5>
              <p className="fs--1 mb-2 mb-md-3">
                <Badge
                  pill
                  bg={
                    category === "Energy"
                      ? "success"
                      : category === "Recycling"
                      ? "info"
                      : category === "Travel"
                      ? "warning"
                      : category === "Swaps"
                      ? "primary"
                      : category === "Staff Involvement"
                      ? "dark"
                      : category === "Cost Saver"
                      ? "info"
                      : category === "Transport"
                      ? "warning"
                      : category === "Waste"
                      ? "primary"
                      : null
                  }
                >
                  {category}
                  <FontAwesomeIcon
                    icon={
                      category === "Energy"
                        ? faCheck
                        : category === "Recycling"
                        ? faRedo
                        : category === "Travel"
                        ? faStream
                        : category === "Swaps"
                        ? faBan
                        : category === "Staff Involvement"
                        ? faExchange
                        : category === "Cost Saver"
                        ? faRedo
                        : category === "Transport"
                        ? faStream
                        : category === "Waste"
                        ? faBan
                        : null
                    }
                    transform="shrink-2"
                    className="ms-1"
                  />
                </Badge>
              </p>
              {ratings && <StarRating readonly rating={ratings} />}
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: solution,
                  }}
                />
              </p>
              <p>
                <Accordion>
                  {tasks && tasks.length !== 0
                    ? tasks.map((task, index) => (
                        <Accordion.Item eventKey={task.id}>
                          <Accordion.Header>{task.title}</Accordion.Header>
                          <Accordion.Body>{task.solution}</Accordion.Body>
                        </Accordion.Item>
                        // <>
                        //   <Alert variant="danger">{task.id}</Alert>
                        //   <Alert variant="danger">{task.solution}</Alert>
                        // </>
                      ))
                    : null}
                </Accordion>
              </p>
              <div className="mt-2">
                <Row>
                  <Col>
                    <IconButton
                      size="sm"
                      variant="primary"
                      className="ms-2"
                      icon={faPenToSquare}
                      onClick={() =>
                        handleAddToCart(
                          true,
                          currentUser.businessId,
                          currentUser.uid,
                          id
                        )
                      }
                    >
                      Commit to Solution
                    </IconButton>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

SolutionList.propTypes = {
  solutionData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    solution: PropTypes.string.isRequired,
  }),
  index: PropTypes.number,
};

export default SolutionList;
