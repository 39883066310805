import React from "react";
import { Button, Nav } from "react-bootstrap";

const BookMeeting = () => {
  return (
    <Nav.Item as="li">
      <Nav.Link
        href="https://calendly.com/netnada/customer-support"
        target="_blank"
      >
        <Button variant="primary" className="rounded-pill me-0 mb-0" size="sm">
          Request a meeting
        </Button>
      </Nav.Link>
    </Nav.Item>
  );
};

export default BookMeeting;
