import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { first } from "lodash";
import { WaterContext } from "../../context/Context";
import { submitWaterForm, getWaterDataByWaterId } from "./WaterDataAPI";
import { useLocation } from "react-router-dom";

const WaterHubProvider = ({ children }) => {
  const location = useLocation();
  const [step, setStep] = useState(15);
  const [key, setKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [categoriesData, setCategoriesData] = useState([]);

  const lastStep = () => {
    setStep(100);
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 25);
  };

  const prevStep = () => {
    setStep((prevStep) => Math.max(prevStep - 25, 1));
  };

  const handleSubmitContext = async () => {
    setIsLoading(true);
    setStep(85);
    // console.log("CategoriesData", categoriesData);
    // console.log("FormData", formData);
    await submitWaterForm({ formData, categoriesData });
    setIsLoading(false);
    lastStep();
  };

  const getSubmittedDataById = async (formId) => {
    setIsLoading(true);
    // console.log(location?.state?.row?.id);
    const response = await getWaterDataByWaterId(location?.state?.row?.id);
    // console.log("get water id response", response);

    const filteredWaterData = response?.waterData?.map((item) => ({
      category: "Water",
      volumeOfWater: item.volume_kilolitres,
      developmentType: item.development_type,
      developmentSubType: item.development_sub_type,
      keyMetric: item.key_metric,
      keyMetricValue: item.key_metric_value,
      keyMetricUnit: item.key_metric_unit,
      source: item.source,
      form_id: item.form_id,
      id: item.id,
    }));

    setFormData({
      formName: response.formData.name,
      formCategory: response.formData.category,
      userId: response.formData.uploaded_by,
      office: { value: response.formData.office_id },
      startDate: response.formData.start_date,
      endDate: response.formData.end_date,
      update_date: response.formData.created_at,
    });
    setCategoriesData(filteredWaterData);
    setIsLoading(false);
  };

  useEffect(() => {
    //Get the result when navigating to the result page
    // console.log("location", location);

    if (location?.state?.row?.id !== undefined) {
      lastStep();
      getSubmittedDataById();
    } else {
      // console.log("Come from the Calculator");
    }
  }, [location]);

  const value = {
    step,
    setStep,
    nextStep,
    prevStep,
    isLoading,
    key,
    setKey,
    lastStep,
    formData,
    setFormData,
    // isButtonDisabled: isButtonDisabled,
    setCategoriesData,
    categoriesData,
    handleSubmitContext,
  };

  //get Locations of BusinessId
  //
  return (
    <WaterContext.Provider value={value}>{children}</WaterContext.Provider>
  );
};

WaterHubProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WaterHubProvider;
