import React, { useEffect } from "react";
import {
  Spinner,
  Col,
  Row,
  Modal,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import { Card } from "react-bootstrap";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "../common/advance-table/AdvanceTableSearchBox";
import IconButton from "../common/IconButton";
import {
  faCheckDouble,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  query,
  updateDoc,
  where,
  addDoc,
  increment,
  deleteDoc,
  getDoc,
  writeBatch,
} from "firebase/firestore";
import app from "../../firebase";
import { ToastContainer, toast } from "react-toastify";
import AccountsListForm from "../authentication/AccountsListForm";
import CardDropdown from "../common/CardDropdown";
import {
  getActiveUserData,
  getUserData,
  isDataFrozen,
  isImpersonationModeEnabled,
} from "../../helpers/store";
import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../helpers/unifiedDataHelper";
import Flex from "../common/Flex";
import { formatDateToYYYYMMDD } from "../../helpers/utils";
import FrozenDataModal from "../utilities/FrozenDataModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const fireStoreDB = getFirestore(app);

const EXCLUDE_ACCOUNT_TYPES_XERO = ["EQUITY", "REVENUE"];

const UnifiedData = ({ startDate, endDate }) => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [isLoading, setIsLoading] = useState(true);
  const [unifiedDataCalCompleted] = useState(
    JSON.parse(sessionStorage.getItem("unifiedDataCalCompleted"))
  );
  const [unifiedData, setUnifiedData] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [editUnifiedData, setEditUnifiedData] = useState(null);
  const [primaryCategories, setPrimaryCategories] = useState([]);
  const [combinedCategoryFactorsObj, setCombinedCategoryFactorsObj] = useState(
    {}
  );
  const [accountsList, setAccountsList] = useState([]);
  const [
    showEditSelectedAccountNamesModal,
    setShowEditSelectedAccountNamesModal,
  ] = useState(false);
  const [showFrozenDataModal, setShowFrozenDataModal] = useState(false);
  const [showBillModal, setShowBillModal] = useState(false);
  const [billUrl, setBillUrl] = useState("");
  const [billId, setBillId] = useState("");

  const fetchBillUrl = async (mainId) => {
    try {
      const docRef = doc(fireStoreDB, "Files", mainId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const url = docSnap.data().url;
        setBillUrl(url);
        setBillId(mainId);
        setShowBillModal(true);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching bill URL:", error);
    }
  };

  const isAllRowsSelected =
    Object.keys(selectedRowIds).length === unifiedData.length;

  const handleHeaderCheckboxChange = () => {
    if (isAllRowsSelected) {
      setSelectedRowIds({});
    } else {
      const newSelectedRowIds = {};
      unifiedData.forEach((row, index) => {
        newSelectedRowIds[index] = true;
      });
      setSelectedRowIds(newSelectedRowIds);
    }
  };

  const handleRowSelection = (row) => {
    const newSelectedRowIds = { ...selectedRowIds };
    if (newSelectedRowIds[row.index]) {
      delete newSelectedRowIds[row.index];
    } else {
      newSelectedRowIds[row.index] = true;
    }
    setSelectedRowIds(newSelectedRowIds);
  };

  const columns = [
    {
      id: "selection",
      Header: (
        <Form.Check
          type="checkbox"
          className="form-check fs-0 mb-0 d-flex align-items-center"
        >
          <Form.Check.Input
            type="checkbox"
            checked={isAllRowsSelected}
            onChange={handleHeaderCheckboxChange}
            // Add indeterminate state based on the number of selected rows
            ref={(element) => {
              if (element) {
                element.indeterminate =
                  Object.keys(selectedRowIds).length > 0 &&
                  Object.keys(selectedRowIds).length < unifiedData.length;
              }
            }}
          />
        </Form.Check>
      ),
      Cell: ({ row }) => (
        <Form.Check
          type="checkbox"
          className="form-check fs-0 mb-0 d-flex align-items-center"
        >
          <Form.Check.Input
            type="checkbox"
            checked={selectedRowIds[row.index]}
            onChange={() => handleRowSelection(row)}
          />
        </Form.Check>
      ),
      width: 10, // Set the desired width for the selection column
    },
    {
      accessor: "",
      Header: "Actions",
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <>
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item
                  as="button"
                  onClick={() => {
                    if (isDataFrozen()) {
                      setShowFrozenDataModal(true);
                      return;
                    }
                    handleEditOpen(rowData.row.original);
                  }}
                >
                  Change Categories
                </Dropdown.Item>

                <Dropdown.Item
                  className="text-danger"
                  as="button"
                  onClick={() => {
                    if (isDataFrozen()) {
                      setShowFrozenDataModal(true);
                      return;
                    }
                    handleExclusion(id);
                  }}
                >
                  Exclude this row
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </>
        );
      },
    },
    {
      accessor: "dateTime",
      Header: "Date",
      sortType: "datetime",
      Cell: (rowData) => {
        return (
          <>
            {rowData.row.original.date
              .split("T")[0]
              .split("-")
              .reverse()
              .join("/")}
          </>
        );
      },
    },

    {
      accessor: "contactName",
      Header: "Contact name",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "scope",
      Header: "Scope",
    },
    {
      accessor: "accountName",
      Header: "Account Name",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "description",
      Header: "Description",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "accountType",
      Header: "Account Type",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "primaryCategory",
      Header: "Primary Category",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "secondaryCategory",
      Header: "Secondary Category",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "emissions",
      Header: "Emissions",
      sortType: "basic",
      Cell: (rowData) => {
        const value = rowData.row.original.emissions;
        const [showFullNumber, setShowFullNumber] = useState(false);
        const toggleFullNumber = () => setShowFullNumber(!showFullNumber);
        const shouldUseConciseFormat = value < 0.1;
        const formattedNumber = shouldUseConciseFormat
          ? showFullNumber
            ? value
            : value.toFixed(3)
          : value.toFixed(2); // Display the full number if value >= 0.1

        return (
          <div
            className="number-container"
            onMouseEnter={toggleFullNumber}
            onMouseLeave={toggleFullNumber}
            onClick={toggleFullNumber}
          >
            {formattedNumber}
            {shouldUseConciseFormat && (
              <span className="ellipsis">{showFullNumber ? "" : "..."}</span>
            )}
          </div>
        );
      },
    },
    {
      accessor: "dataSource",
      Header: "Data Source",
      Cell: (rowData) => {
        const { dataSource, mainId } = rowData.row.original;
        return (
          <>
            {dataSource}
            {dataSource === "Bill" && (
              <FontAwesomeIcon
                icon={faFilePdf}
                onClick={() => fetchBillUrl(mainId)}
                style={{ cursor: "pointer", marginLeft: "10px" }}
              />
            )}
          </>
        );
      },
    },
    {
      accessor: "inclusionStatus",
      Header: "Status",
    },
    {
      accessor: "reason",
      Header: "Exclusion rule",
      cellProps: {
        className: "text-wrap",
      },
    },
  ];

  async function handleEditClose(isRefresh) {
    setShowEditModal(false);
    if (isRefresh) {
      await getUnifiedData();
    }
  }

  function handleEditOpen(unifiedData) {
    setEditUnifiedData(unifiedData);
    setShowEditModal(true);
  }

  async function updateChartData() {
    await removeUnifiedDateFromStore();
    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      startDate,
      endDate
    );

    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);

    toast.success("Data updated successfully");
  }

  async function updateGroupedUnifiedData(data) {
    let date = data.date;
    let emissions = data.emissions;

    // Replace the day with 01
    let newDate = date.slice(0, 8) + "01";
    // console.log("Processed Date:", newDate);

    // Get a reference to the GroupedUnifiedData collection
    const groupedDataCollection = collection(
      fireStoreDB,
      "GroupedUnifiedData",
      activeUser.businessId,
      "Groups"
    );
    // Look for a document in GroupedUnifiedData/businessId/Groups with the same date, primaryCategory, secondaryCategory, scope, and dataSource
    let groupedDataQuery = query(
      groupedDataCollection,
      where("date", "==", newDate),
      where("primaryCategory", "==", data.primaryCategory),
      where("secondaryCategory", "==", data.secondaryCategory),
      where("scope", "==", data.scope),
      where("dataSource", "==", data.dataSource)
    );
    // Additional conditions based on dataSource
    if (data.dataSource === "Bill") {
      groupedDataQuery = query(
        groupedDataQuery,
        where("contactName", "==", data.contactName),
        where("officeId", "==", data.officeId)
      );
    } else if (data.dataSource !== "Form") {
      groupedDataQuery = query(
        groupedDataQuery,
        where("contactName", "==", data.contactName),
        where("accountName", "==", data.accountName)
      );
    } else if (data.dataSource === "Form") {
      groupedDataQuery = query(
        groupedDataQuery,
        where("officeId", "==", data.officeId)
      );
    }

    // Execute the query and get the result
    const groupedDataSnapshot = await getDocs(groupedDataQuery);

    // Check if there are any documents in the groupedDataQuery result
    if (groupedDataSnapshot.docs.length > 0) {
      // Fetch the document data
      const docData = groupedDataSnapshot.docs[0].data();
      // emissions to subtract
      let emissionToBeSubtracted = emissions;
      if (data.dataSource === "Form") {
        //skip dividing by 1000 if the primaryCategory is Waste or Paper or Water
        if (
          data.primaryCategory !== "Waste" &&
          data.primaryCategory !== "Paper" &&
          data.primaryCategory !== "Water"
        ) {
          emissionToBeSubtracted = emissions / 1000;
        }
      }

      // Update the existing document with the decrement
      const totalEmissionsAfterDecrement =
        docData.totalEmissions - emissionToBeSubtracted;
      console.log(
        "Total Emissions After Decrement:",
        totalEmissionsAfterDecrement
      );

      if (totalEmissionsAfterDecrement <= 0) {
        // If totalEmissions becomes 0 after decrement, delete the document from GroupedUnifiedData
        await deleteDoc(groupedDataSnapshot.docs[0].ref);
        console.log("Document deleted due to zero or negative emissions.");
      } else {
        // Update the existing document with the decrement
        await updateDoc(groupedDataSnapshot.docs[0].ref, {
          totalEmissions: totalEmissionsAfterDecrement,
        });
        console.log("Document updated with new emissions.");
      }
    } else {
      // Handle the case where there's no corresponding document in GroupedUnifiedData
      console.warn(
        `No corresponding document in GroupedUnifiedData for the provided query filters.`
      );
    }
  }

  //exclude the selected row
  async function handleExclusion(id) {
    const userData = JSON.parse(getActiveUserData());
    let updatedBy = userData.displayName;
    if (isImpersonationModeEnabled()) {
      const parentUserData = JSON.parse(getUserData());
      updatedBy = parentUserData.displayName;
    }

    try {
      const unifiedDataDocRef = doc(
        fireStoreDB,
        "UnifiedData",
        activeUser.businessId,
        "DataLines",
        id
      );
      const updateData = {
        inclusionStatus: "EXCLUDED",
        reason: "Excluded by " + updatedBy,
      };
      await updateDoc(unifiedDataDocRef, updateData);

      //get the excluded data
      const docSnap = await getDoc(unifiedDataDocRef);
      const data = docSnap.data();
      //update grouped unified data
      await updateGroupedUnifiedData(data);

      if (unifiedDataCalCompleted) {
        updateChartData();
      }

      toast.success("Row has been excluded");

      // Refresh data
      await getUnifiedData();

      handleEditClose(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleBulkExclusion() {
    const userData = JSON.parse(getActiveUserData());
    let updatedBy = userData.displayName;
    if (isImpersonationModeEnabled()) {
      const parentUserData = JSON.parse(getUserData());
      updatedBy = parentUserData.displayName;
    }

    try {
      const batch = writeBatch(fireStoreDB);
      Object.keys(selectedRowIds).forEach((index) => {
        const row = unifiedData[index];
        const unifiedDataDocRef = doc(
          fireStoreDB,
          "UnifiedData",
          activeUser.businessId,
          "DataLines",
          row.id
        );
        const updateData = {
          inclusionStatus: "EXCLUDED",
          reason: "Excluded by " + updatedBy,
        };
        batch.update(unifiedDataDocRef, updateData);
      });
      await batch.commit();
      toast.success("Selected rows have been excluded");

      // Update grouped unified data
      await Promise.all(
        Object.keys(selectedRowIds).map(async (index) => {
          const row = unifiedData[index];
          await updateGroupedUnifiedData(row);
        })
      );

      // Update chart data
      if (unifiedDataCalCompleted) {
        updateChartData();
      }

      // Refresh data
      await getUnifiedData();
      setSelectedRowIds({});
    } catch (error) {
      console.log(error);
    }
  }

  async function handleEditSelectedAccountNamesClose(isRefresh) {
    setShowEditSelectedAccountNamesModal(false);
    if (isRefresh) {
      await getUnifiedData();
    }
  }

  async function getUnifiedData() {
    let unifiedData = [];

    const startDateString = `${formatDateToYYYYMMDD(
      new Date(startDate)
    )}T00:00:00.000Z`;
    const endDateString = `${formatDateToYYYYMMDD(
      new Date(endDate)
    )}T00:00:00.000Z`;

    try {
      const qry = query(
        collection(
          fireStoreDB,
          "UnifiedData",
          activeUser.businessId,
          "DataLines"
        ),
        where("date", ">=", startDateString),
        where("date", "<=", endDateString)
      );
      const querySnapshot = await getDocs(qry);
      querySnapshot.forEach((doc) => {
        unifiedData.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      if (unifiedData.length > 0) {
        // unifiedData = toFetchDateFilteredData(unifiedData);

        //formatting data
        unifiedData.map((e) => {
          //formatting emissions to 2 decimal places
          // e.emissions = parseFloat(e.emissions * 1000).toFixed(2);
          //formatting date to dd/mm/yyyy
          e.dateTime = new Date(e.date);
        });
      }
    } catch (error) {
      console.log(error);
    }
    setUnifiedData(unifiedData);
  }

  async function fetchNetnadaFactorsFromMongoDB() {
    const targetUrl = `https://netnada-factor-production-8ed52ccdfd5e.herokuapp.com/factors/allGroupByCategory`;
    try {
      const response = await fetch(targetUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_NETNADA_API_KEY,
        },
      });
      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      const data = await response.json();
      // console.log("data", data);

      return data;
    } catch (error) {
      console.error("Failed to fetch emission factors:", error);
      return null;
    }
  }

  async function get() {
    await getUnifiedData();

    let data = await fetchNetnadaFactorsFromMongoDB();
    // console.log("data=>", data);

    //omit the category_name whose activities array has no item with unit as '$', except for category_name="Electricity" and "Stationary energy (gaseous fuels)"
    const filteredData = data.filter((item) => {
      if (
        item.category_name !== "Electricity" &&
        item.category_name !== "Stationary energy (gaseous fuels)"
      ) {
        const activities = item.activities;
        // Check if activities array has no item with unit as '$'
        const hasDollar = activities.some((activity) => activity.unit === "$");
        return hasDollar;
      }
      return true; // Keep "Electricity" and "Stationary energy (gaseous fuels)"
    });
    // console.log("filteredData=>", filteredData);

    //fetch all the primary and secondary categories
    let primaryCategories = [];
    let combinedCategoryFactorsObj = {};
    for (let i = 0; i < filteredData.length; i++) {
      let factorObject = filteredData[i];
      let primaryCategory = factorObject.category_name;
      primaryCategories.push(primaryCategory);
      combinedCategoryFactorsObj[primaryCategory] = factorObject["activities"];
    }

    // console.log("primaryCategories=>", primaryCategories);
    // console.log("combinedCategoryFactorsObj=>", combinedCategoryFactorsObj);

    setPrimaryCategories(primaryCategories);
    setCombinedCategoryFactorsObj(combinedCategoryFactorsObj);
    setIsLoading(false);
  }

  useEffect(() => {
    get();
  }, [activeUser, startDate, endDate]);

  useEffect(() => {
    const fetchAccountsList = async () => {
      //check if a data source exists
      const csvPresent = JSON.parse(sessionStorage.getItem("csvPresent"));
      const dataSource = csvPresent ? "CSV" : activeUser.authProvider;
      if (!dataSource) return;

      //fetch accounts list from firebase
      const querySnapshot = await getDocs(
        collection(fireStoreDB, "Accounts", activeUser.businessId, dataSource)
      );
      let accountsList = [];
      querySnapshot.forEach((doc) => {
        const data = JSON.parse(JSON.stringify(doc.data()));
        if (activeUser.authProvider === "Xero") {
          if (!EXCLUDE_ACCOUNT_TYPES_XERO.includes(data.type)) {
            accountsList.push(data);
          }
        }
        if (activeUser.authProvider === "MYOB" || csvPresent) {
          accountsList.push(data);
        }
      });
      // console.log("accountList=>", accountsList);
      if (activeUser.authProvider === "Xero") {
        //sort accountsList by name
        accountsList = accountsList.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      }
      if (activeUser.authProvider === "MYOB") {
        //sort accountsList by DisplayID
        accountsList = accountsList.sort((a, b) => {
          // Extract the DisplayID values from the account objects
          const displayIDA = a.DisplayID;
          const displayIDB = b.DisplayID;

          // Use the localeCompare method to perform a string comparison
          return displayIDA.localeCompare(displayIDB);
        });
      }
      if (csvPresent) {
        accountsList = accountsList.sort((a, b) => {
          // Extract the DisplayID values from the account objects
          const nameA = a.name;
          const nameB = b.name;

          // Use the localeCompare method to perform a string comparison
          return nameA.localeCompare(nameB);
        });
      }
      setAccountsList(accountsList);
    };

    fetchAccountsList();
  }, []);

  return (
    <>
      {/* <WidgetSectionTitle
        title="Unified data"
        transform="shrink-2"
        className="mb-4 mt-6"
      /> */}
      {showFrozenDataModal && (
        <FrozenDataModal
          show={showFrozenDataModal}
          onHide={() => {
            setShowFrozenDataModal(false);
          }}
        />
      )}
      {showEditModal && (
        <EditUnifiedDataModal
          showEditModal={showEditModal}
          handleEditClose={handleEditClose}
          data={{
            businessId: activeUser.businessId,
            unifiedData: { ...editUnifiedData },
            primaryCategories: primaryCategories,
            combinedCategoryFactorsObj: combinedCategoryFactorsObj,
          }}
          updateChartData={updateChartData}
        />
      )}

      {showEditSelectedAccountNamesModal && (
        <EditSelectedAccountNamesModal
          showEditSelectedAccountNamesModal={showEditSelectedAccountNamesModal}
          handleEditSelectedAccountNamesClose={
            handleEditSelectedAccountNamesClose
          }
          accountsList={accountsList}
          setAccountsList={setAccountsList}
        />
      )}

      {showBillModal && (
        <FileViewModal
          showBillModal={showBillModal}
          setShowBillModal={setShowBillModal}
          activeUser={activeUser}
          billId={billId}
          billUrl={billUrl}
        />
      )}

      {isLoading ? (
        <div className="mb-3">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="mb-3">
          <AdvanceTableWrapper
            columns={columns}
            data={unifiedData}
            sortable
            pagination
            perPage={25}
            rowCount={unifiedData.length}
          >
            <Card>
              <Card.Header>
                <Row className="flex-start-center mb-1">
                  <Col xs={6} lg={8}>
                    <AdvanceTableSearchBox table />
                  </Col>
                  <Col xs={6} lg={4} className="text-end">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon={faPenToSquare}
                      transform="shrink-3"
                    >
                      <span
                        className="d-none d-sm-inline-block ms-1"
                        onClick={() => {
                          if (isDataFrozen()) {
                            setShowFrozenDataModal(true);
                            return;
                          }
                          setShowEditSelectedAccountNamesModal(true);
                        }}
                      >
                        Account Mapping
                      </span>
                    </IconButton>
                    <>
                      {Object.keys(selectedRowIds).length > 0 && (
                        <Button
                          variant="danger"
                          size="sm"
                          className="ms-2"
                          onClick={handleBulkExclusion}
                        >
                          Exclude Selected Rows
                        </Button>
                      )}
                    </>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="btn-reveal-trigger text-nowrap align-middle"
                  tableProps={{
                    size: "lg",
                    className: "fs--1 mb-0 overflow-hidden",
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  data={unifiedData}
                  rowCount={unifiedData.length}
                  table
                  rowInfo
                  navButtons
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </div>
      )}
    </>
  );
};

const EditUnifiedDataModal = ({
  showEditModal,
  handleEditClose,
  data,
  updateChartData,
}) => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [reportingYear] = useState(
    parseInt(activeUser.selectedMeasurementYear)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [unifiedDataCalCompleted] = useState(
    JSON.parse(sessionStorage.getItem("unifiedDataCalCompleted"))
  );
  const [unifiedData] = useState(data.unifiedData || null);
  const [primaryCategory, setPrimaryCategory] = useState(
    data.unifiedData.primaryCategory
  );
  const [secondaryCategory, setSecondaryCategory] = useState(
    data.unifiedData.secondaryCategory
  );

  const [secondaryCategoryObject, setSecondaryCategoryObject] = useState(null);

  const [combinedCategoryFactorsObj] = useState(
    data.combinedCategoryFactorsObj || null
  );
  const [primaryCategories] = useState(data.primaryCategories || []);
  const [secondaryCategories, setSecondaryCategories] = useState([]);

  const [showSecondaryCategories, setShowSecondaryCategories] = useState(
    data.unifiedData.primaryCategory
      ? data.unifiedData.secondaryCategory !== null
        ? true
        : false
      : false
  );

  const toSetSecondaryCategoriesByPrimaryCategory = (primaryCategory) => {
    if (primaryCategory) {
      let categories = combinedCategoryFactorsObj[primaryCategory];

      // Group by unit
      const groupedByUnit = categories.reduce((acc, item) => {
        // If the unit doesn't exist in the accumulator, add it as a key with an empty array
        if (!acc[item.unit]) {
          acc[item.unit] = [];
        }

        // Push the current item to the corresponding unit array
        acc[item.unit].push(item);
        return acc;
      }, {});
      // console.log("groupedByUnit=>", groupedByUnit);

      let secondaryCategories = [];

      //if the primary category is 'Electricity', then filter only unit 'kwh' for the year nearest to reporting year
      if (primaryCategory === "Electricity") {
        secondaryCategories = Object.keys(groupedByUnit)
          .filter((unit) => unit === "kwh")
          .map((unit) => ({
            groupName: "For input activity in " + unit,
            categories: groupedByUnit[unit],
          }));

        //find the categories whose year is nearest to reporting year
        let categories = secondaryCategories[0].categories;

        //sort the categories by year in descending order
        categories.sort((a, b) => parseInt(b.year) - parseInt(a.year));

        //fetch unique years
        const uniqueYears = [
          ...new Set(categories.map((item) => item.year)),
        ].sort((a, b) => b - a);

        let nearestLowerYear = null;
        //compare the unique years with reporting year and get the nearest lower year
        for (let i = 0; i < uniqueYears.length; i++) {
          if (uniqueYears[i] <= reportingYear) {
            nearestLowerYear = uniqueYears[i];
            break;
          }
        }
        // console.log("nearestLowerYear=>", nearestLowerYear);

        //filter the categories whose year is nearestLowerYear
        categories = categories.filter(
          (item) => item.year === nearestLowerYear
        );

        //update the secondaryCategories with the filtered categories
        secondaryCategories[0].categories = categories;

        // console.log("secondaryCategories=>", secondaryCategories);

        return secondaryCategories;
      }

      //if the primary category is 'Stationary energy (gaseous fuels)', then filter only unit 'GJ'
      if (primaryCategory === "Stationary energy (gaseous fuels)") {
        secondaryCategories = Object.keys(groupedByUnit)
          .filter((unit) => unit === "GJ")
          .map((unit) => ({
            groupName: "For input activity in " + unit,
            categories: groupedByUnit[unit].filter(
              (item) =>
                item.activity_name === "Natural Gas National average (GJ)"
            ),
          }));

        // console.log("secondaryCategories=>", secondaryCategories);

        return secondaryCategories;
      }

      //if others , then filter only unit '$'
      // Transform the grouped object back into an array suitable for the dropdown
      secondaryCategories = Object.keys(groupedByUnit)
        .filter((unit) => unit === "$")
        .map((unit) => ({
          groupName: "For input activity in " + unit,
          categories: groupedByUnit[unit],
        }));

      // console.log("secondaryCategories=>", secondaryCategories);

      return secondaryCategories;
    }
  };

  useEffect(() => {
    if (primaryCategory) {
      const secondaryCategories =
        toSetSecondaryCategoriesByPrimaryCategory(primaryCategory);
      setSecondaryCategories(secondaryCategories);
      setShowSecondaryCategories(true);
    }
  }, [primaryCategory]);

  // console.log("showSecondaryCategories=>", showSecondaryCategories);

  const handleChange = (event) => {
    event.preventDefault();
    setPrimaryCategory(event.target.value);
    const secondaryCategories = toSetSecondaryCategoriesByPrimaryCategory(
      event.target.value
    );
    setSecondaryCategories(secondaryCategories);
    setShowSecondaryCategories(true);
  };

  async function handleSubmit(e) {
    setIsLoading(true);

    try {
      e.preventDefault();
      let activity = null;
      let factor = 0.0;
      let emissions = 0.0;

      if (!secondaryCategoryObject) {
        //if the secondaryCategories {'groupName':"For input activity in $",categories=[{}]} has a group name 'For input activity in $', then get the first element of the categories array
        let categories =
          secondaryCategories.filter(
            (item) => item.groupName === "For input activity in $"
          )[0]?.categories || [];

        if (categories.length === 0) {
          categories = secondaryCategories[0].categories;
        }

        activity = categories[0];
        factor = parseFloat(categories[0].category_co2e_total);
      } else {
        activity = secondaryCategoryObject;
        factor = parseFloat(secondaryCategoryObject.co2e_total);
      }

      //if the data source is bill, calculate the emissions using the consumption
      if (unifiedData.dataSource === "Bill") {
        // if accountName is electricity, convert emission factor to t/kWh
        if ((unifiedData.accountName = "electricity")) {
          const consumption = parseFloat(unifiedData.consumption);
          emissions = (consumption * factor) / 1000;
        }
        // if accountName is gas, convert emission factor to t/GJ
        if (unifiedData.accountName === "gas") {
          const consumption = parseFloat(unifiedData.consumption);
          emissions = (consumption * factor) / (1000 * 1000);
        }
      } else {
        if (unifiedData.subTotal) {
          const subTotal = parseFloat(unifiedData.subTotal);
          emissions = (subTotal * factor) / 1000;
        }
      }

      //check if primary category or secondary category is being changed
      let isChanged = false;
      let exisitngPrimaryCategory = unifiedData.primaryCategory;
      let exisitngSecondaryCategory = unifiedData.secondaryCategory;
      if (
        exisitngPrimaryCategory !== primaryCategory ||
        exisitngSecondaryCategory !== activity.activity_name
      ) {
        isChanged = true;
      }

      const unifiedDataDocRef = doc(
        fireStoreDB,
        "UnifiedData",
        data.businessId,
        "DataLines",
        unifiedData.id
      );
      const updateData = {
        categoryMatchingSource: "MANUAL",
        primaryCategory: primaryCategory,
        primaryEmissionFactor: activity.category_co2e_total,
        secondaryCategory: activity.activity_name,
        secondaryEmissionFactor: factor,
        scope: activity.default_scope,
        emissionFactorId: activity._id,
        score: 1.0,
        emissions,
      };
      await updateDoc(unifiedDataDocRef, updateData);

      //update grouped unified data
      if (isChanged) {
        // decrement emissions from previous category
        const previousCategoryEmissions = unifiedData.emissions;
        const previousPrimaryCategory = unifiedData.primaryCategory;
        const previousSecondaryCategory = unifiedData.secondaryCategory;
        const previousContactName = unifiedData.contactName;
        //manipulate date to make changes to the right data
        let date = unifiedData.date;
        // Replace the day with 01
        let newDate = date.slice(0, 8) + "01";
        console.log("newDate", newDate);

        const groupedDataCollection = collection(
          fireStoreDB,
          "GroupedUnifiedData",
          data.businessId,
          "Groups"
        );

        const groupedDataQuery = query(
          groupedDataCollection,
          where("date", "==", newDate),
          where("supplier", "==", previousContactName),
          where("primaryCategory", "==", previousPrimaryCategory),
          where("secondaryCategory", "==", previousSecondaryCategory)
        );

        const groupedDataQuerySnapshot = await getDocs(groupedDataQuery);

        // Check if there are any documents in the groupedDataQuery result
        if (groupedDataQuerySnapshot.docs.length > 0) {
          await Promise.all(
            groupedDataQuerySnapshot.docs.map(async (groupedDataDoc) => {
              const groupedDataDocRef = doc(
                fireStoreDB,
                "GroupedUnifiedData",
                data.businessId,
                "Groups",
                groupedDataDoc.id
              );

              // Update the existing document with the decrement
              const groupedData = groupedDataDoc.data();
              const totalEmissionsAfterDecrement =
                groupedData.totalEmissions - previousCategoryEmissions;

              if (totalEmissionsAfterDecrement <= 0) {
                // If totalEmissions becomes 0 after decrement, delete the document from GroupedUnifiedData
                await deleteDoc(groupedDataDocRef);
              } else {
                // Update the existing document with the decrement
                await updateDoc(groupedDataDocRef, {
                  totalEmissions: totalEmissionsAfterDecrement,
                });
              }
            })
          );
        }

        // increment emissions to new category
        const newGroupedDataQuery = query(
          groupedDataCollection,
          where("date", "==", newDate),
          where("supplier", "==", previousContactName),
          where("primaryCategory", "==", primaryCategory),
          where("secondaryCategory", "==", activity.activity_name)
        );

        const newGroupedDataQuerySnapshot = await getDocs(newGroupedDataQuery);

        // Check if there are any documents in the newGroupedDataQuery result
        if (newGroupedDataQuerySnapshot.docs.length > 0) {
          await Promise.all(
            newGroupedDataQuerySnapshot.docs.map(async (newGroupedDataDoc) => {
              const newGroupedDataDocRef = doc(
                fireStoreDB,
                "GroupedUnifiedData",
                data.businessId,
                "Groups",
                newGroupedDataDoc.id
              );

              // Update the existing document with the increment
              await updateDoc(newGroupedDataDocRef, {
                totalEmissions: increment(emissions),
              });
            })
          );
        } else {
          // If there are no documents in the newGroupedDataQuery result, create a new document
          await addDoc(groupedDataCollection, {
            date: newDate,
            accountName: unifiedData.accountName,
            dataSource: unifiedData.dataSource,
            officeId: unifiedData.officeId,
            supplier: previousContactName,
            primaryCategory: primaryCategory,
            secondaryCategory: activity.activity_name,
            scope: activity.default_scope,
            totalEmissions: emissions,
          });
        }
      }

      //update chart data
      if (unifiedDataCalCompleted) {
        await updateChartData();
      }
      setIsLoading(false);
      handleEditClose(true);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {" "}
      <Modal
        show={showEditModal}
        onHide={handleEditClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Unified data category - <i>{data.unifiedData.id}</i>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Primary Category</Form.Label>
                <Form.Select
                  aria-label="Default select"
                  value={primaryCategory}
                  required
                  onChange={handleChange}
                >
                  <option value="">Please select</option>
                  {primaryCategories.sort().map((cat, index) => (
                    <option value={primaryCategories[index]}>
                      {primaryCategories[index]}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              {showSecondaryCategories ? (
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Secondary Category</Form.Label>

                  <Form.Select
                    aria-label="Default select"
                    value={secondaryCategory}
                    onChange={(e) => {
                      // Find the secondary category object
                      const secondaryCategoryObj = secondaryCategories
                        .flatMap((group) => group.categories)
                        .find((cat) => cat._id === e.target.value);
                      setSecondaryCategory(e.target.value);
                      setSecondaryCategoryObject(secondaryCategoryObj);
                    }}
                  >
                    <option value="">Please select your factor</option>

                    {secondaryCategories.map((group) => (
                      <optgroup label={group.groupName}>
                        {group.categories.map((cat) => (
                          <option key={cat._id} value={cat._id}>
                            {cat.activity_name}
                            {cat.state_code !== "" && (
                              <span>
                                {" - "}({cat.state_code})
                              </span>
                            )}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </Form.Select>
                </Form.Group>
              ) : null}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              disabled={!primaryCategory || !secondaryCategory || isLoading}
            >
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

const EditSelectedAccountNamesModal = ({
  showEditSelectedAccountNamesModal,
  handleEditSelectedAccountNamesClose,
  accountsList,
  setAccountsList,
}) => {
  return (
    <div>
      {" "}
      <Modal
        show={showEditSelectedAccountNamesModal}
        onHide={() => {
          handleEditSelectedAccountNamesClose(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Account Mapping
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormTitle
              icon={faCheckDouble}
              title={
                "Map accounts between your accounting software and NetNada"
              }
            />
            <AccountsListForm
              accountsList={accountsList}
              setAccountsList={setAccountsList}
              isEdit={true}
              handleEditSelectedAccountNamesClose={
                handleEditSelectedAccountNamesClose
              }
            />
          </Modal.Body>
        </Form>
      </Modal>
      <ToastContainer />
    </div>
  );
};

const FormTitle = ({ icon, title }) => {
  return (
    <Flex className="mb-4">
      {/* <span className="fa-stack me-2 ms-n1">
        <FontAwesomeIcon icon="circle" className="fa-stack-2x text-300" />
        <FontAwesomeIcon
          icon={icon}
          className="fa-inverse fa-stack-1x text-primary"
        />
      </span> */}
      <div className="flex-1">
        <h5 className="mb-0 text-primary position-relative">
          <span className="bg-200 dark__bg-1100 pe-3">{title}</span>
          <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
        </h5>

        <p className="mb-0">
          This should take you no more than a few minutes. You will be able to
          edit this accounts later with the support of our team.
          <br />
          <br />
          <b>What to include:</b>
          <li>Expenses (exceptions below)</li>
          <li>Cost of goods sold</li>
          <br />
          <b>What to exclude:</b>
          <li>Salary/wages</li>
          <li>Superannuation, or</li>
          <li>Revenue</li>
          <li>Depreciation</li>
          <br />
          You can click on the table columns to sort account types or use the
          search bar.
        </p>
      </div>
    </Flex>
  );
};

const FileViewModal = ({
  showBillModal,
  setShowBillModal,
  activeUser,
  billId,
  billUrl,
}) => {
  return (
    <Modal
      show={showBillModal}
      onHide={() => setShowBillModal(false)}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Invoice {activeUser.role === "SUPER_ADMIN" && `- ${billId}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe src={billUrl} width="100%" height="500px" />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowBillModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnifiedData;
