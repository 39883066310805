import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form, Row, Col, Alert, InputGroup } from "react-bootstrap";
import Divider from "../common/Divider";
import SocialAuthButtons from "./SocialAuthButtons";
import { useAuth } from "../../Contexts/AuthContext";
import { errorMessage } from "./ErrorData";
import { auth } from "../../firebase";
import {
  setIdToken,
  setInventoryProgress,
  setUserData,
} from "../../helpers/store";
import LoginSocialAuthButtons from "./LoginSocialAuthButtons";
import { PublicClientApplication } from "@azure/msal-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { getAllWfhFormData } from "../wfh/wfhAPI";

const LoginForm = ({ hasLabel, layout, employeeEngagementFormId }) => {
  // console.log("state", state);
  const navigate = useNavigate();
  const { setCurrentUser, setIsUserSignedIn } = useAuth();
  const [isAzureLogin, setIsAzureLogin] = useState(false);
  // const [employeeEngagementFormId, setEmployeeEngagementFormId] = useState(
  //   state?.employeeEngagementFormId
  // );
  // console.log("employeeEngagementFormId on mount", employeeEngagementFormId);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember: false,
  });

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  //show password
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  //firebase authentication
  const { login, loginWithGoogle, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      const response = await login(formData.email, formData.password);
      if (response.hasOwnProperty("message")) {
        const code = response.code;
        setError(errorMessage[code]);
      } else {
        if (response.hasOwnProperty("user")) {
          const user = response.user;
          // console.log(user.emailVerified);
          if (user.emailVerified) {
            const basicLoginResponse = await fetch(
              "/api/users/basic-token/" + user.uid.toString(),
              {
                method: "GET",
                headers: {
                  Authorization: "Bearer " + response.idToken,
                },
              }
            );

            if (basicLoginResponse.status !== 200) {
              throw new Error();
            }

            const basicLoginResponseData = await basicLoginResponse.json();
            const newIdToken = await auth.currentUser.getIdToken(true);
            setIdToken(newIdToken);
            setUserData(JSON.stringify(basicLoginResponseData.user));
            setInventoryProgress(
              JSON.stringify(basicLoginResponseData.inventoryProgress)
            );
            toast(`Logged in as ${formData.email}`);

            if (basicLoginResponseData.user?.role === "EMPLOYEE") {
              // if (basicLoginResponseData.user?.wfhFormId) {
              //   const wfhFormId = basicLoginResponseData.user?.wfhFormId;
              //   const userWfhForms = await getAllWfhFormData(wfhFormId);
              //   const peopleIds = userWfhForms.map((item) => item.people_id);
              //   const isUserInPeopleIds = peopleIds.includes(user.uid);

              //   // Check if the user has submitted a WFH form or not
              //   if (isUserInPeopleIds) {
              //     window.location.href = "/welcome/data/wfh";
              //   } else {
              //     window.location.href = `/welcome/data/wfh-form-data?wfhFormId=${wfhFormId}`;
              //   }
              // }
              console.log("employeeEngagementFormId on mount");

              if (
                employeeEngagementFormId !== undefined &&
                employeeEngagementFormId !== null &&
                employeeEngagementFormId !== ""
                // Object.keys(state).length !== 0
              ) {
                // window.location.href = `/welcome/data/wfh-form-data?wfhFormId=${state.wfhFormId}`;

                console.log(
                  "employeeEngagementFormId in if statement",
                  employeeEngagementFormId
                );

                // window.location.href =
                //   "/welcome/data/employee-engagement-response-overview/" +
                //   employeeEngagementFormId;

                navigate(
                  "/welcome/data/employee-engagement-response-overview",
                  {
                    state: {
                      // Add any state you want to pass here, e.g.,
                      employeeEngagementFormId,
                    },
                  }
                );
              } else {
                window.location.href = "/welcome";
              }
            } else {
              window.location.href = "/welcome";
              //navigate("/welcome");
            }
          } else {
            setError("Email Not Verified!");
          }
        }
      }
    } catch (error) {
      setError("Failed to create an account");
    }
    //setLoading(false)
  }

  async function handleRedirectToOrBack() {
    try {
      //login in with google
      const loginResponse = await loginWithGoogle();
      const user = loginResponse.user;
      // console.log("USER", user);
      try {
        const googleLoginResponse = await fetch(
          "/api/users/google-token/" + loginResponse.user.uid.toString(),
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + loginResponse.idToken,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
          }
        );

        if (googleLoginResponse.status !== 200) {
          throw new Error("Login error.");
        }

        const googleLoginResponseData = await googleLoginResponse.json();
        // console.log(googleLoginResponseData);
        const userData = googleLoginResponseData?.user;
        // console.log("userData Login Form", userData);
        // console.log(userData);

        const newIdToken = await auth.currentUser.getIdToken(true);

        if (userData !== undefined) {
          setIdToken(newIdToken);
          setUserData(JSON.stringify(userData));
          setInventoryProgress(
            JSON.stringify(googleLoginResponseData?.inventoryProgress)
          );
          toast(`Logged in as ${userData.email}`);
          if (userData?.role === "EMPLOYEE") {
            if (userData?.wfhFormId) {
              const wfhFormId = userData?.wfhFormId;
              const userWfhForms = await getAllWfhFormData(wfhFormId);
              const peopleIds = userWfhForms.map((item) => item.people_id);
              const isUserInPeopleIds = peopleIds.includes(user.uid);

              // Check if the user has submitted a WFH form or not
              if (isUserInPeopleIds) {
                window.location.href = "/welcome/data/wfh";
              } else {
                window.location.href = `/welcome/data/wfh-form-data?wfhFormId=${wfhFormId}`;
              }
            } else {
              window.location.href = "/welcome/data/wfh";
            }
          } else {
            window.location.href = "/welcome";
            //navigate("/welcome");
          }
          // window.location.href = "/welcome";
        }
      } catch (err) {
        toast.error(err.message);
        console.error(err.message);
      }
    } catch (error) {
      setError("Failed to create an account");
    }
  }

  async function azureADLogin() {
    setIsAzureLogin(true);
    try {
      const config = {
        appId: "72c705a3-222a-44ad-90f3-adf70766bc11",
        redirectUri: process.env.REACT_APP_AZUREAD_REDIRECT_URI,
        scopes: ["user.read"],
        authority: "https://login.microsoft.com/common/", //"https://login.microsoft.com/4b8c37f4-5b7c-4736-8b56-8aaf6cab8d3d",,
      };

      const clientApp = new PublicClientApplication({
        auth: {
          clientId: config.appId,
          redirectUri: config.redirectUri,
          authority: config.authority,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: true,
        },
      });

      await clientApp.initialize();
      await clientApp.handleRedirectPromise();
      // clientApp.loginRedirect({
      //   scopes: config.scopes,
      //   prompt: "select_account",
      // });
      const azureADResponse = await clientApp.loginPopup({
        scopes: config.scopes,
        prompt: "select_account",
      });

      if (!azureADResponse || !azureADResponse.accessToken) {
        toast.error("Microsoft login failed.");
        return;
      }

      const response = await fetch("/api/azure/azure-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(azureADResponse),
        mode: "cors",
      });

      if (response.status === 200) {
        const jsonData = await response.json();
        // console.log("jsonData", jsonData);

        if (!jsonData || !jsonData.customToken)
          throw new Error("customToken should not be empty");

        await auth.signInWithCustomToken(jsonData.customToken);
        const idToken = await auth.currentUser.getIdToken(true);
        setIdToken(idToken);
        const userData = jsonData.user;
        setUserData(JSON.stringify(userData));
        setInventoryProgress(JSON.stringify(jsonData.inventoryProgress));
        toast(`Logged in as ${userData.email}`);
        window.location.href = "/welcome";
      } else {
        toast.error("Microsoft login failed.");
      }
    } catch (ex) {
      console.log(ex);
      toast.error(ex);
    }
    setIsAzureLogin(false);
  }

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      {/* {message && <Alert variant="success">{message}</Alert>} */}
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Email address" : ""}
          value={formData.email}
          onChange={handleFieldChange}
          autoComplete="email-id"
          name="email"
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <InputGroup>
          <Form.Control
            placeholder={!hasLabel ? "Password" : ""}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type={showPassword ? "text" : "password"}
          />
          <InputGroup.Text className="bg-transparent">
            <FontAwesomeIcon
              icon={showPassword ? faEye : faEyeSlash}
              className="fs--1 text-600"
              onClick={(e) => setShowPassword(!showPassword)}
              style={{ cursor: "pointer" }}
            />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  remember: e.target.checked,
                })
              }
            />
            <Form.Check.Label className="ms-2 mb-0">
              Remember Me
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/${layout}/forgot-password`}
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>

      <ToastContainer />

      <Divider className="mt-4">or log in with</Divider>

      <LoginSocialAuthButtons
        handleRedirectToOrBack={handleRedirectToOrBack}
        azureADLogin={azureADLogin}
        isAzureLogin={isAzureLogin}
      />
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
};

LoginForm.defaultProps = {
  layout: "simple",
  hasLabel: false,
};

export default LoginForm;
