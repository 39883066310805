import React, { useState, useEffect, createContext, useContext } from "react";

import { Modal, Form, Button, Spinner } from "react-bootstrap";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
  increment,
  addDoc,
  writeBatch,
  runTransaction,
} from "firebase/firestore";
import {
  fetchAllApprovedSuppliersFromFirebase,
  updateContactWithSupplierIdAndScore,
  getSupplier,
  fetchNetnadaFactorsFromMongoDBOpenSearch,
  groupUnifiedData,
} from "../../helpers/suppliersHelper";
import { getActiveUserData } from "../../helpers/store";

import Select from "react-select";
import debounce from "lodash.debounce"; // Assuming you have lodash installed for debouncing
import app from "../../firebase";

import { EmissionsContext } from "./emissions-by-supplier"; // Adjust the import path as necessary
import { formatDateToYYYYMM01 } from "../../helpers/utils";

const fireStoreDB = getFirestore(app);

const EditSupplierModal = ({
  showEditSupplierModal,
  handleEditSupplierModalClose,
  contactData,
  handleCloseCalculationsModal,
}) => {
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeUser] = useState(JSON.parse(getActiveUserData()));

  const startDate = `${formatDateToYYYYMM01(
    new Date(activeUser.currentStartDate)
  )}T00:00:00.000Z`;
  const endDate = `${formatDateToYYYYMM01(
    new Date(activeUser.currentEndDate)
  )}T00:00:00.000Z`;

  const businessId = activeUser.businessId;
  const dataSource = contactData.dataSource;
  const contactId = contactData.contactId;
  const [isLoading, setIsLoading] = useState(false);
  const { getUnifiedData, updateChartData, get } = useContext(EmissionsContext);
  const [unifiedDataCalCompleted] = useState(
    JSON.parse(sessionStorage.getItem("unifiedDataCalCompleted"))
  );

  // Debounced fetch suppliers
  const fetchSuppliers = debounce(async (searchTerm) => {
    const fetchedSuppliers = await fetchAllApprovedSuppliersFromFirebase(
      searchTerm
    );
    setSuppliers(fetchedSuppliers || []);
  }, 300); // Debounce time of 300ms

  useEffect(() => {
    fetchSuppliers(searchTerm);
  }, [searchTerm]); // Fetch suppliers whenever searchTerm changes

  const handleChange = (selectedOption) => {
    setSelectedSupplier(selectedOption);
    // console.log(`Option selected:`, selectedOption);
  };

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setSearchTerm(inputValue); // Update the search term which triggers the useEffect
    return inputValue;
  };

  async function updateGroupedUnifiedData(data) {
    try {
      await runTransaction(fireStoreDB, async (transaction) => {
        for (let i = 0; i < data.length; i++) {
          const groupedUnifiedDataCollectionRef = collection(
            fireStoreDB,
            "GroupedUnifiedData",
            activeUser.businessId,
            "Groups"
          );

          const qry = query(
            groupedUnifiedDataCollectionRef,
            where("date", "==", data[i].date),
            where("contactName", "==", data[i].contactName)
          );

          const querySnapshot = await getDocs(qry);

          if (querySnapshot.empty) {
            continue;
          }

          querySnapshot.forEach((doc) => {
            const docRef = doc.ref;
            transaction.update(docRef, {
              scope: data[i].scope,
              primaryCategory: data[i].primaryCategory,
              secondaryCategory: data[i].secondaryCategory,
              totalEmissions: data[i].totalEmissions,
            });
          });
        }
      });

      // console.log("Transaction update operation completed.");
    } catch (error) {
      console.error("Error updating GroupedUnifiedData:", error);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submit action
    // console.log("SUBMITTING THE FORM");
    // console.log("Supplier", selectedSupplier);
    // console.log("dataSource", dataSource);
    // console.log("contactData", contactData);
    // console.log("businessId", businessId);

    setIsLoading(true); // Set isLoading to true when the form is submitted
    // update the contact with the selected supplier and score
    await updateContactWithSupplierIdAndScore(
      businessId,
      dataSource,
      contactId,
      selectedSupplier.value
    );

    // Get the supplier info
    const supplierId = selectedSupplier.value;
    // console.log("Supplier ID:", supplierId);
    let supplier = null;

    try {
      //fetch the supplier data from suppliers collection
      supplier = await getSupplier(supplierId);
    } catch (error) {
      console.error("Failed to fetch supplier:", error);
    }

    // Update the datalines
    let secondaryEmissionFactor = supplier.secondaryEmissionFactor;
    let emissions = 0.0;

    try {
      const qry = query(
        collection(fireStoreDB, "UnifiedData", businessId, "DataLines"),
        where("contactName", "==", contactData.contactName),
        where("inclusionStatus", "==", "INCLUDED"),
        where("date", ">=", startDate),
        where("date", "<=", endDate)
      );
      const querySnapshot = await getDocs(qry);

      const batch = writeBatch(fireStoreDB);

      // const data = await fetchNetnadaFactorsFromMongoDBOpenSearch(
      //   supplier.primaryCategory,
      //   supplier.secondaryCategory
      // );

      // Example usage:
      const data = await fetchNetnadaFactorsFromMongoDBOpenSearch({
        categoryName: supplier.primaryCategory,
        activityName: supplier.secondaryCategory,
        // You can add more parameters here as needed
      });

      const foundActivity = data[0];

      let scope = "";
      let emissionFactorId = "";

      if (foundActivity) {
        scope = foundActivity.default_scope;
        emissionFactorId = foundActivity._id;
      }

      // Refactor starts here
      querySnapshot.forEach(async (queryDoc) => {
        const queryData = queryDoc.data();

        if (queryData.dataSource === "Bill") {
          if (queryData.accountName === "electricity") {
            const consumption = parseFloat(queryData.consumption);
            emissions = (consumption * secondaryEmissionFactor) / 1000;
          } else if (queryData.accountName === "gas") {
            const consumption = parseFloat(queryData.consumption);
            emissions = (consumption * secondaryEmissionFactor) / (1000 * 1000);
          }
        } else {
          if (queryData.subTotal) {
            const subTotal = parseFloat(queryData.subTotal);
            emissions = (subTotal * secondaryEmissionFactor) / 1000;
          }
        }

        const updateData = {
          categoryMatchingSource: "MANUAL",
          primaryCategory: supplier.primaryCategory,
          primaryEmissionFactor: supplier.primaryEmissionFactor,
          secondaryCategory: supplier.secondaryCategory,
          secondaryEmissionFactor: supplier.secondaryEmissionFactor,
          scope: scope,
          emissionFactorId: emissionFactorId,
          score: 1.0,
          emissions,
        };

        const unifiedDataDocRef = doc(
          fireStoreDB,
          "UnifiedData",
          businessId,
          "DataLines",
          queryDoc.id
        );

        batch.update(unifiedDataDocRef, updateData);
      });

      // Commit the batched writes
      await batch.commit();

      //fetch the updated unified data
      let unifiedDataList = [];

      const updatedUnifiedDataQuery = query(
        collection(fireStoreDB, "UnifiedData", businessId, "DataLines"),
        where("contactName", "==", contactData.contactName),
        where("inclusionStatus", "==", "INCLUDED"),
        where("date", ">=", startDate),
        where("date", "<=", endDate)
      );
      const updatedUnifiedDataQuerySnapshot = await getDocs(
        updatedUnifiedDataQuery
      );

      for (const doc of updatedUnifiedDataQuerySnapshot.docs) {
        const data = doc.data();
        unifiedDataList.push(data);
      }
      // console.log("unifiedDataList=>", unifiedDataList);

      //group the updated unified data
      const groupedData = await groupUnifiedData(unifiedDataList);
      // console.log("groupedData=>", groupedData);

      //update the GroupedUnifiedData collection
      await updateGroupedUnifiedData(groupedData);

      if (unifiedDataCalCompleted) {
        await updateChartData();
      }
    } catch (error) {
      console.error(error);
    }

    // console.log("Updated the emissions data.");
    handleEditSupplierModalClose();
    handleCloseCalculationsModal(true);
    setSelectedSupplier(null);
    setIsLoading(false); // Set isLoading to false after the submission logic is handled
  };

  return (
    <div>
      <Modal
        show={showEditSupplierModal}
        onHide={handleEditSupplierModalClose}
        size="xl"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Supplier</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <Form.Group controlId="editSupplierSelect">
                <Form.Label>Select Supplier</Form.Label>
                <Select
                  options={suppliers.map((supplier) => ({
                    label: supplier.name,
                    value: supplier.id,
                  }))}
                  classNamePrefix="react-select"
                  placeholder="Select a Supplier"
                  value={selectedSupplier}
                  onChange={handleChange}
                  onInputChange={handleInputChange}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* Save Changes button with loading spinner */}
            <Button
              variant="primary"
              type="submit"
              disabled={!selectedSupplier || isLoading} // Disable the button when isLoading is true
            >
              {isLoading ? ( // Show loading spinner if isLoading is true
                <Spinner animation="border" size="sm" />
              ) : (
                "Save Changes"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default EditSupplierModal;
