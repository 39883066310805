import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Badge, Button, Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCheck, faExchange, faPenToSquare, faRedo, faStream } from "@fortawesome/free-solid-svg-icons";

const RecommendedSolutionList = ({ data, index, aoiObject }) => {
  console.log("In RecommendedSolutionList=>", data, index, aoiObject);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id, title, description, scope, cost, parent_id } = data;
  //find key for parent_id in aoiObject
  const areaOfImprovement = Object.keys(aoiObject).find((key) => aoiObject[key] === parent_id);
  return (
    <>
      <Col
        xs={12}
        className={classNames("p-card", {
          "bg-100": index % 2 !== 0,
        })}>
        <Row>
          <Col sm={12} md={12}>
            <Row className="h-100">
              <h5 className="mt-3 mt-sm-0">{title}</h5>
              <p className="fs--1 mb-2 mb-md-3">
                <Badge
                  pill
                  bg={
                    areaOfImprovement === "Moving People"
                      ? "success"
                      : areaOfImprovement === "Moving Goods"
                      ? "info"
                      : areaOfImprovement === "Office Operations"
                      ? "warning"
                      : areaOfImprovement === "Site Operations and Equipment"
                      ? "primary"
                      : areaOfImprovement === "Designing Products"
                      ? "dark"
                      : null
                  }>
                  {areaOfImprovement}
                  <FontAwesomeIcon
                    icon={
                      areaOfImprovement === "Moving People"
                        ? faCheck
                        : areaOfImprovement === "Moving Goods"
                        ? faRedo
                        : areaOfImprovement === "office Operations"
                        ? faStream
                        : areaOfImprovement === "Site Operations and Equipment"
                        ? faBan
                        : areaOfImprovement === "Designing Products"
                        ? faExchange
                        : null
                    }
                    transform="shrink-2"
                    className="ms-1"
                  />
                </Badge>
              </p>
              {/* {ratings && <StarRating readonly rating={ratings} />} */}
              {/* <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: solution,
                  }}
                />
              </p> */}
              <p>Description: {description}</p>
              <p>Scope: {scope}</p>
              <p>Cost: {cost}</p>
              {/* <p>
                <Accordion>
                  {tasks && tasks.length !== 0
                    ? tasks.map((task, index) => (
                        <Accordion.Item eventKey={task.id}>
                          <Accordion.Header>{task.title}</Accordion.Header>
                          <Accordion.Body>{task.solution}</Accordion.Body>
                        </Accordion.Item>
                        // <>
                        //   <Alert variant="danger">{task.id}</Alert>
                        //   <Alert variant="danger">{task.solution}</Alert>
                        // </>
                      ))
                    : null}
                </Accordion>
              </p> */}
              {/* <div className="mt-2">
                <Row>
                  <Col>
                    <Button>
                      Commit to Action
                      {isLoading && (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginLeft: "10px" }}
                        ></span>
                      )}
                      {!isLoading && (
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          transform="shrink-2"
                          className="ms-1"
                        />
                      )}
                    </Button>
                  </Col>
                </Row>
              </div> */}
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

RecommendedSolutionList.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    description: PropTypes.string.isRequired,
  }),
  index: PropTypes.number,
};

export default RecommendedSolutionList;
