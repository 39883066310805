import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import {
  getSelectedSolutions,
  updateCompletedSolutions,
} from "../solutionsAPI";
import IconButton from "../../../components/common/IconButton";
import { faCheck, faBarsProgress } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { MySolutionsContext } from "../../../context/Context";

const SolutionTableHeader = ({ selectedRowIds }) => {
  // console.log(selectedRowIds);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const { mySolutionsDispatch } = useContext(MySolutionsContext);

  const getSolutions = async () => {
    console.log("Getting updated solutions");
    const data = await getSelectedSolutions();
    await mySolutionsDispatch({
      type: "FETCH_MY_SOLUTIONS_SUCCESS",
      payload: { data },
    });
  };
  async function handleClick(status) {
    if (status !== null) {
      let body = { status, updatedBy: currentUser.uid };
      let keys = Object.keys(selectedRowIds);
      // console.log(keys);
      const selectedSolutions = JSON.parse(
        sessionStorage.getItem("selectedSolutions")
      );
      // console.log(selectedSolutions);
      for (let i in keys) {
        let solutionId = selectedSolutions[keys[i]]["solutionId"];
        let officeId = selectedSolutions[keys[i]]["officeId"];
        // console.log(solutionId, officeId);
        await updateCompletedSolutions(solutionId, officeId, body);
      }
      //window.location = "/welcome/solutions/my-solutions";
      await getSolutions();
      navigate("/welcome/solutions/my-solutions");
    }
  }

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
          See and change the status of your solutions.
        </h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        <div id="orders-actions">
          <IconButton
            variant="falcon-default"
            size="sm"
            icon={faCheck}
            transform="shrink-3"
          >
            <span
              className="d-none d-sm-inline-block ms-1"
              onClick={() => {
                handleClick("Completed");
              }}
            >
              Mark As Completed
            </span>
          </IconButton>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon={faBarsProgress}
            transform="shrink-3"
            className="mx-2"
          >
            <span
              className="d-none d-sm-inline-block ms-1"
              onClick={() => {
                handleClick("InProgress");
              }}
            >
              Mark As In-Progress
            </span>
          </IconButton>
        </div>
      </Col>
    </Row>
  );
};

SolutionTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
};

export default SolutionTableHeader;
