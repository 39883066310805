import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import BusinessDetailsWizardInput from "./BusinessDetailsWizardInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Screen3 = ({ register, errors, setValue, getValues }) => {
  return (
    <>
      <BusinessDetailsWizardInput
        type="select"
        label={
          <>
            Do you have a sustainability committee or ESG manager? *
            <OverlayTrigger placement="top" overlay={<Tooltip>TBC</Tooltip>}>
              <span>
                <FontAwesomeIcon icon={faQuestionCircle} className="mx-2" />
              </span>
            </OverlayTrigger>
          </>
        }
        name="ESGManager"
        placeholder="Please select..."
        errors={errors}
        options={["No", "Yes, but not officially", "Yes"]}
        formGroupProps={{
          className: "mb-3",
        }}
        formControlProps={{
          ...register("ESGManager", {
            required: "Select an option",
          }),
        }}
      />

      <BusinessDetailsWizardInput
        type="select"
        label="Do you have a sustainability policy or ESG strategy? *"
        name="ESGStrategy"
        placeholder="Please select..."
        errors={errors}
        options={["No", "Yes, but it's old", "Yes, and up to date"]}
        formGroupProps={{
          className: "mb-3",
        }}
        formControlProps={{
          ...register("ESGStrategy", {
            required: "Select an option",
          }),
        }}
      />
    </>
  );
};

Screen3.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
};
export default Screen3;
