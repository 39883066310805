import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faObjectGroup } from "@fortawesome/free-solid-svg-icons";
import { getAllAreasOfImprovementByQuestion } from "../solutionsAPI";
import QuestionModal from "./QuestionModal";
import { AreasOfImprovementContext } from "../../../context/Context";
import { useNavigate } from "react-router-dom";
import {
  getActiveUserData,
  getUserData,
  setUserData,
} from "../../../helpers/store";

const GenerateAOIButton = () => {
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleGenerateAOI = () => {
    setShow(true);
  };

  const handleClose = async (isRefresh) => {
    setShow(false);
    if (isRefresh) {
      const userData = JSON.parse(getActiveUserData());
      // console.log("Before userData", userData);
      const { questionId } = userData;
      // console.log(questionId);
      const data = await getAllAreasOfImprovementByQuestion(questionId);
      // console.log(data);
      if (data.length > 0) {
        userData.aoiGeneratedStatus = "Completed";
        setUserData(JSON.stringify(userData));
      }
      // console.log("After userData", userData);
      navigate("/welcome/solutions/areas-of-improvement", {
        state: {
          areasOfImprovement: data,
          status: userData.aoiGeneratedStatus === "InProgress" ? true : false,
        },
      });
    }
  };
  return (
    <div className="mt-2">
      {show && (
        <QuestionModal showQuestionModal={show} handleClose={handleClose} />
      )}
      <Row>
        <Col>
          <Button onClick={handleGenerateAOI} style={{ width: "100%" }}>
            Generate Your Areas of Improvement
            <FontAwesomeIcon
              icon={faObjectGroup}
              transform="shrink-2"
              className="ms-1"
            />
            {/* {isLoading && (
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                style={{ marginLeft: "10px" }}
              ></span>
            )}
            {!isLoading && (
              <FontAwesomeIcon
                icon={faObjectGroup}
                transform="shrink-2"
                className="ms-1"
              />
            )} */}
          </Button>
          <br />
          <br />
        </Col>
      </Row>
    </div>
  );
};

export default GenerateAOIButton;
