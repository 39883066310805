import React, { useContext } from "react";
import PropTypes from "prop-types";
import Flex from "../../../components/common/Flex";
import { Link } from "react-router-dom";
import { Button, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ProjectContext } from "../../../context/Context";
import useProjectHook from "./useProjectHook";
import ProjectImage from "./ProjectImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const ProjectGrid = ({ project, ...rest }) => {
  const {
    id,
    imagesrc,
    title,
    link,
    type,
    costpertonne,
    description,
    country,
  } = project;

  const { handleAddTotalEmission } = useProjectHook(project);
  return (
    <Col className="mb-4" {...rest}>
      <Flex
        direction="column"
        justifyContent="between"
        className="border rounded-1 h-100 pb-3"
      >
        <div className="overflow-hidden">
          <ProjectImage
            id={id}
            imageSrc={imagesrc}
            title={title}
            layout="grid"
          />
          <div className="p-3">
            <h5 className="fs-0">
              <Link className="text-dark" to={link} target="_blank">
                {title}
              </Link>
            </h5>
            <p className="fs--1 mb-3">{type}</p>
            <h5 className="fs-md-2 text-warning mb-0 d-flex align-items-center mb-3">
              {`$${costpertonne}`}
            </h5>
            <p className="fs--1 mb-1">{description}</p>
            <p className="fs--1 mb-1">
              <span className="fw-semi-bold">Country:</span> {country}
            </p>
          </div>
        </div>
        <Flex alignItems="center" className="px-3">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Add to Portfolio</Tooltip>}
          >
            <Button
              variant="falcon-default"
              size="sm"
              onClick={() => handleAddTotalEmission(id, true)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </OverlayTrigger>
        </Flex>
      </Flex>
    </Col>
  );
};

ProjectGrid.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    imagesrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    costpertonne: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
  }),
};

export default ProjectGrid;
