import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  ListGroup,
  Spinner,
  Modal,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faCalendarAlt,
  faWalking,
  faAdd,
  faCheckCircle,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import corner1 from "../../Assets/img/icons/spot-illustrations/corner-2.png";
import Background from "../common/Background";
import { toast, ToastContainer } from "react-toastify";

import { useLocation, useNavigate } from "react-router-dom";
import EmployeeEngagementEmployeeCard from "./EmployeeEngagementEmployeeCard";
import {
  getAllEmployees,
  getEmployeeEngagementAllEstimationFormData,
  getEmployeeEngagementAllUsersFormData,
  getEmployeeEngagementForm,
  getEmployeeEngagementInviteUrl,
  getOfficesByBusiness,
  saveEmployeeEngagementForm,
} from "./EmployeeEngagementAPI";

import { getActiveUserData, setActiveUserData } from "../../helpers/store";
import { useParams, Link } from "react-router-dom";
import EmployeeEngagementEstimationCard from "./EmployeeEngagementEstimationCard";

import { useAuth } from "../../Contexts/AuthContext";
import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../helpers/unifiedDataHelper";

const EmployeeEngagementResponseOverview = () => {
  const { signupAnonymously } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [employeeEngagementFormId, setEmployeeEngagementFormId] = useState();
  const [showSubmitModal, setShowSubmitModal] = useState();
  const [formName, setFormName] = useState();
  const [formStartDate, setFormStartDate] = useState();
  const [formEndDate, setFormEndDate] = useState();
  const [formCreatedAt, setFormCreatedAt] = useState();
  const [formStatus, setFormStatus] = useState();

  // const { employeeEngagementFormId } = useParams();

  const [activeUser, setActiveUser] = useState(JSON.parse(getActiveUserData()));
  // console.log("activeUser", activeUser);
  // const queryParams = new URLSearchParams(window.location.search);
  // const formId = queryParams.get("employeeEngagementFormId");
  // console.log("formId", formId);
  const [isLoading, setIsLoading] = useState(false);

  const buttonBorderRadius = "8px";
  const cardBorderRadius = "20px";

  // const [formDetails, setFormDetails] = useState({});
  const [submittedCount, setSubmittedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [estimationFormList, setEstimationFormList] = useState([]);

  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [newEmployeeName, setNewEmployeeName] = useState(""); // State for the new employee name

  const handleCopyInviteLink = async (id) => {
    try {
      const inviteLink = await getEmployeeEngagementInviteUrl(id);
      await navigator.clipboard.writeText(inviteLink);
      toast.success("Invite URL copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy invite URL");
    }
  };

  const handleAddNewEmployee = () => {
    setShowModal(true); // Open the modal when adding a new employee
  };

  const handleSaveNewEmployee = async () => {
    try {
      // Generate a unique user ID by signing up anonymously
      const { user } = await signupAnonymously();

      if (user) {
        const newEmployee = {
          id: user.uid, // Use the UID generated by Firebase
          given: newEmployeeName, // Use the entered name
          formId: employeeEngagementFormId,
          hasSubmittedData: false, // Default to no submitted data
          color: "danger", // Set color to indicate no data submitted
        };

        setEmployees([...employees, newEmployee]); // Append the new employee to the state
        setShowModal(false); // Close the modal after saving
        setNewEmployeeName(""); // Reset the input field
      } else {
        toast.error("Failed to create a new employee. Please try again.");
      }
    } catch (error) {
      console.error("Error creating new employee:", error);
      toast.error("An error occurred while creating the employee.");
    }
  };

  async function get(formId) {
    setIsLoading(true);

    // const formDetailsResponse = await getEmployeeEngagementForm(formId);
    // setFormDetails(formDetailsResponse[0]);

    // logic to retrive all data of users that have submitted data under formId
    const employeeEngagementAllUsersFormData =
      await getEmployeeEngagementAllUsersFormData(formId);

    const employeeEngagementAllEstimationFormData =
      await getEmployeeEngagementAllEstimationFormData(formId);

    const officesData = await getOfficesByBusiness();

    let estimationList = employeeEngagementAllEstimationFormData.map(
      (estimation, index) => {
        return {
          ...estimation,
          formId: formId,
          officeData: officesData.find(
            (office) => office.id === estimation.officeId
          ),

          hasSubmittedData: true,
          color: "success",
        };
      }
    );
    setEstimationFormList(estimationList);

    // logic to retrive all employees under the businessId
    const allEmployees = await getAllEmployees();

    // console.log("allEmployees", allEmployees);
    // console.log(
    //   "employeeEngagementAllUsersFormData",
    //   employeeEngagementAllUsersFormData
    // );

    // Map over employees registered under the business and add the details
    let employeeList = allEmployees.map((employee, index) => {
      // Find the matching data object for the current employee
      const matchedData = employeeEngagementAllUsersFormData.find(
        (data) => data.userId === employee.id
      );

      // If a match is found, extract the submittedDate
      const submittedDate = matchedData ? matchedData.submittedDate : null;

      return {
        ...employee,
        formId: formId,
        hasSubmittedData: !!matchedData, // Convert matchedData to a boolean
        color: matchedData ? "success" : "danger", // Set color based on submission status
        date: submittedDate, // Set the date field to the submittedDate
      };
    });

    // Find the employees who have submitted data but are not part of the bussiness
    const employeesNotInBusiness = employeeEngagementAllUsersFormData.filter(
      (data) => !allEmployees.some((employee) => employee.id === data.userId)
    );

    // add the employees who have submitted data but are not part of the business to the employee list
    employeeList = [
      ...employeeList,
      ...employeesNotInBusiness.map((employee, index) => {
        return {
          ...employee,
          id: employee.userId,
          given: employee.employeeName,
          formId: formId,
          hasSubmittedData: true,
          color: "success", // Set color based on submission status
          date: employee.submittedDate,
        };
      }),
    ];

    // Calculate submitted and total counts
    const totalCount = employeeList.length;
    const submittedCount = employeeList.filter(
      (employee) => employee.hasSubmittedData
    ).length;

    // console.log("employeeList", employeeList);
    const sortedEmployeeList = (employeeList || []).sort((a, b) => {
      // Sort false values first, then true values
      return a.hasSubmittedData === b.hasSubmittedData
        ? 0
        : a.hasSubmittedData
        ? 1
        : -1;
    });

    // Set the updated data to the state
    setSubmittedCount(submittedCount);
    setTotalCount(totalCount);
    setEmployees(sortedEmployeeList || []);
    setIsLoading(false);
  }

  async function handleSubmit() {
    try {
      setIsLoading(true);

      await saveEmployeeEngagementForm({
        id: employeeEngagementFormId,
        formName,
        formStatus: "Completed",
        startDate: formStartDate.toDateString(),
        endDate: formEndDate.toDateString(),
      });

      await removeUnifiedDateFromStore();

      await fetchDashboardDataFromFirebaseByBusinessId(
        activeUser.businessId,
        activeUser.currentStartDate,
        activeUser.currentEndDate
      );

      // Update location.state with the new form status
      setFormStatus("Completed");

      navigate("/welcome/data/employee-engagement-response-overview", {
        state: {
          ...location.state,
          status: "Completed",
        },
        replace: true,
      });
    } catch (error) {
      console.error("Error occurred during form submission:", error);
    } finally {
      setIsLoading(false);
      sessionStorage.setItem("dashboardDataCalCompleted", false);
      setShowSubmitModal(false);
    }
  }

  useEffect(() => {
    if (location.state) {
      const {
        employeeEngagementFormId,
        name,
        startDate,
        endDate,
        createdAt,
        status,
      } = location.state;
      setEmployeeEngagementFormId(employeeEngagementFormId);
      setFormName(name);
      setFormStartDate(new Date(startDate));
      setFormEndDate(new Date(endDate));
      setFormCreatedAt(createdAt);
      setFormStatus(status);
      get(employeeEngagementFormId);
    }
  }, [location.state]);

  return (
    <>
      {submitModal(
        showSubmitModal,
        setShowSubmitModal,
        handleSubmit,
        isLoading
      )}

      <Card style={{ borderRadius: cardBorderRadius }}>
        <Background image={corner1} className="rounded-soft bg-card" />{" "}
        <Card.Body>
          <h1 className="mt-5">
            {/* Employee Engagement Form: {formDetails?.name} */}
            Employee Engagement Form: {formName}
          </h1>
          {activeUser.role === "EMPLOYEE" ? (
            <>
              <p>
                Complete the following form to assess the environmental impact
                of your daily work habits. This information will help you
                understand your carbon footprint, whether you commute to the
                office or work from home.
              </p>
              {/* <p>
                Please click the blue arrow to continue through and complete
                your form.
              </p> */}
            </>
          ) : (
            <>
              <p>
                As a manager, you have the opportunity to contribute to our
                company's sustainability efforts by encouraging your team to
                complete the environmental impact assessment form. This form
                will help employees understand their carbon footprint, whether
                they commute to the office or work from home.
              </p>
              <p>
                Once your team has submitted their responses, you will have
                access to view all submissions, allowing you to gain valuable
                insights into the environmental impact of your team's daily work
                habits.
              </p>
            </>
          )}

          {/* <small>July 2024 for Australia | Created on 25th May 2024 </small> */}
          <Row className="mt-2">
            <Col>
              <>
                {/* {formDetails?.status === "Completed" ? ( */}
                {formStatus === "Completed" ? (
                  <Button
                    variant="success"
                    style={{ borderRadius: buttonBorderRadius }}
                    className="me-2"
                  >
                    <FontAwesomeIcon className="me-2" icon={faCheckCircle} />
                    Submitted
                  </Button>
                ) : activeUser.role !== "EMPLOYEE" ? (
                  <Button
                    style={{ borderRadius: buttonBorderRadius }}
                    className="me-2"
                    variant="primary"
                    onClick={() => setShowSubmitModal(true)}
                  >
                    <FontAwesomeIcon className="me-2" icon={faSync} />
                    In Progress
                  </Button>
                ) : (
                  <Button
                    style={{ borderRadius: buttonBorderRadius }}
                    className="me-2"
                    variant="primary"
                  >
                    <FontAwesomeIcon className="me-2" icon={faSync} />
                    In Progress
                  </Button>
                )}
              </>

              {activeUser.role !== "EMPLOYEE" && (
                <>
                  <Button
                    style={{ borderRadius: buttonBorderRadius }}
                    className="me-2"
                    variant="outline-primary"
                  >
                    <FontAwesomeIcon className="me-2" icon={faWalking} />
                    {submittedCount}/{totalCount} responses
                  </Button>
                  {formStatus !== "Completed" ? (
                    <Link
                      to="/welcome/data/employee-engagement-estimation-form-data"
                      state={{
                        ...location.state, // Spread the existing state variables
                        employeeEngagementEstimationFormDataId: activeUser.uid,
                      }}
                    >
                      <Button
                        style={{ borderRadius: buttonBorderRadius }}
                        className="me-2"
                        variant="outline-primary"
                      >
                        Estimate on behalf of employees
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      style={{ borderRadius: buttonBorderRadius }}
                      className="me-2"
                      variant="outline-primary"
                      disabled
                    >
                      Estimate on behalf of employees
                    </Button>
                  )}

                  <Button
                    style={{ borderRadius: buttonBorderRadius }}
                    className="me-2"
                    variant="outline-primary"
                    onClick={() =>
                      handleCopyInviteLink(employeeEngagementFormId)
                    }
                  >
                    <FontAwesomeIcon className="me-2" icon={faCalendarAlt} />
                    Copy invite link
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {activeUser.role !== "EMPLOYEE" ? (
        <Card className="mt-4">
          <Card.Header>
            <Row>
              <Col>
                <h3>View your estimation forms</h3>
              </Col>
            </Row>
          </Card.Header>
          {isLoading ? (
            <div className="d-flex justify-content-center mb-4">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : estimationFormList.length === 0 ? (
            <div className="d-flex flex-column justify-content-center align-items-center mb-4">
              <div>
                <p>
                  No estimation data to show. Please complete the estimation
                  form for your employees.
                </p>
              </div>
            </div>
          ) : (
            <ListGroup>
              {estimationFormList.map((item) => (
                <ListGroup.Item
                  style={{ paddingRight: 0, position: "relative" }}
                  key={item.office_id}
                >
                  <EmployeeEngagementEstimationCard
                    item={item}
                    refreshData={get}
                    formStatus={formStatus}
                  />
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Card>
      ) : (
        <></>
      )}
      <Card className="mt-4">
        <Card.Header>
          <Row>
            <Col>
              {activeUser.role === "EMPLOYEE" ? (
                <h3>View your form below</h3>
              ) : (
                <h3>View all form responses</h3>
              )}
            </Col>
            {activeUser.role !== "EMPLOYEE" && (
              <Col className="d-flex justify-content-end">
                <Button
                  style={{ borderRadius: buttonBorderRadius }}
                  className="me-2"
                  variant="outline-primary"
                  onClick={handleAddNewEmployee} // Add onClick handler to add new employee
                  disabled={formStatus === "Completed"} // Disable the button if the form is completed
                >
                  <FontAwesomeIcon className="me-2" icon={faAdd} />
                  Add New Employee
                </Button>
              </Col>
            )}
          </Row>
        </Card.Header>
        {/* <Card.Body> */}
        {isLoading ? (
          <div className="d-flex justify-content-center mb-4">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : employees.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center mb-4">
            <div>
              <p>
                No employee data to show. Please copy and share the invite link
                with your employees.
              </p>
            </div>
          </div>
        ) : (
          <ListGroup>
            {employees
              .filter((employee) => {
                // If the active user is an EMPLOYEE, only show the employee with the matching UID
                if (activeUser.role === "EMPLOYEE") {
                  return employee.id === activeUser.uid;
                }
                // Otherwise, show all employees
                return true;
              })
              .map((employee) => (
                <ListGroup.Item
                  style={{ paddingRight: 0, position: "relative" }}
                  key={employee.id}
                >
                  <EmployeeEngagementEmployeeCard
                    employee={employee}
                    refreshData={get}
                    formStatus={formStatus}
                  />
                </ListGroup.Item>
              ))}
          </ListGroup>
        )}
      </Card>
      {/* Modal for adding new employee */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="employeeName">
              <Form.Label>Employee Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter employee name"
                value={newEmployeeName}
                onChange={(e) => setNewEmployeeName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNewEmployee}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const submitModal = (
  showSubmitModal,
  setShowSubmitModal,
  handleSubmit,
  isLoading
) => {
  return (
    <Modal
      centered
      show={showSubmitModal}
      onHide={() => setShowSubmitModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Submit form</Modal.Title>
      </Modal.Header>
      <Modal.Body>Would you like to submit the form?</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setShowSubmitModal(false)}
          disabled={isLoading}
        >
          No
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? <Spinner animation="border" size="sm" /> : "Yes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeEngagementResponseOverview;
