import { clearLoginData } from "../../helpers/store";
import {
  getAuthorizationHeader,
  getAuthorizationHeaderWithContentType,
} from "../../helpers/utils";

export const getInventoryProgress = async () => {
  const response = await fetch("/api/overview/get-inventory-progress", {
    method: "GET",
    headers: getAuthorizationHeaderWithContentType(),
    mode: "cors",
  });

  if (response.status === 200) {
    const data = await response.json();
    const inventoryProgress = data.data;
    return inventoryProgress;
  } else if (response.status === 401) {
    clearLoginData();
  } else {
  }
  return { status: "error", message: "Failed to get date filters" };
};

export const addMeasurementYear = async (body) => {
  const response = await fetch("/api/overview/add-measurement-year", {
    method: "POST",
    headers: getAuthorizationHeaderWithContentType(),
    body: JSON.stringify(body),
    mode: "cors",
  });

  if (response.status === 200) {
    const jsonData = await response.json();
    return {
      status: "success",
      message: "Date filters fetched successfully",
      data: jsonData.data,
    };
  } else if (response.status === 401) {
    clearLoginData();
  } else {
  }
  return { status: "error", message: "Failed to add baseline year" };
};

export const addEmissionBoundariesStatus = async (body) => {
  const response = await fetch("/api/overview/add-emission-boundaries-status", {
    method: "POST",
    headers: getAuthorizationHeaderWithContentType(),
    body: JSON.stringify(body),
    mode: "cors",
  });

  if (response.status === 200) {
    const jsonData = await response.json();
    return {
      status: "success",
      message: "Date filters fetched successfully",
      data: jsonData.data,
    };
  } else if (response.status === 401) {
    clearLoginData();
  } else {
  }
  return { status: "error", message: "Failed to add emission boundaries status" };
};

export const getUserBusinessLocations = async () => {
  try {
    const response = await fetch(`/api/users/user-business-locations`, {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getAllFiles = async () => {
  try {
    const response = await fetch(`/api/files/file-uploads`, {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getEmployeeCount = async () => {
  try {
    const response = await fetch(`/api/users/get-employee-count`, {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData.employeeCount;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateBusinessProcStatusAuthProvider = async (body) => {
  try {
    const response = await fetch(
      `/api/users/update-business-proc-status-auth-provider`,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
