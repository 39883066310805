import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Card,
  Button,
  Container,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import PageHeader from "../common/PageHeader.js";

import {
  countryStateDataJSON,
  targetedCountryCodeList,
  CountryCodeNameObject,
  StateCodeNameObject,
} from "../../data/CountryStateData.js";
import {
  saveEmployeeEngagementFormData,
  getEmployeeEngagementFormData,
  getEmployeeEngagementForm,
} from "./EmployeeEngagementAPI.js";
import { useLocation, useNavigate } from "react-router-dom";

const EmployeeEngagementEmployeeSpecificForm = () => {
  const storedLocationsJSON = sessionStorage.getItem("userBusinessLocations");
  const locations = JSON.parse(storedLocationsJSON);
  const [isLoading, setIsLoading] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [wfhFormDataId, setWfhFormDataId] = useState();
  const [staffDataId, setStaffDataId] = useState();
  const [formDetails, setFormDetails] = useState({});
  const [employeeEngagementFormId, setEmployeeEngagementFormId] = useState();
  const [uid, setUid] = useState();
  const [employeeName, setEmployeeName] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const [submitLoading, setSubmitLoading] = useState(false);

  const officeData = locations.map((office) => ({
    label: `${office.officename} (${office.location}, ${office.country})`,
    value: office.office_id,
    country: office.country,
    state: office.location,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
    resetField,
  } = useForm({
    defaultValues: {
      fteDaysPerYear: 240,
      fteHoursPerWeek: 40,
    },
  });

  const worksFromHome = watch("worksFromHome");
  const showWfhQuestions =
    worksFromHome === "only-wfh" || worksFromHome === "hybrid";
  const showTransportQuestions =
    worksFromHome === "only-office" || worksFromHome === "hybrid";

  async function get() {
    setIsLoading(true);
    try {
      const employeeEngagementFormData = await getEmployeeEngagementFormData(
        employeeEngagementFormId,
        uid
      );

      const formDetailsResponse = await getEmployeeEngagementForm(
        employeeEngagementFormId
      );
      setFormDetails(formDetailsResponse[0]);

      if (employeeEngagementFormData) {
        const { staffData = [], wfhData = [] } = employeeEngagementFormData;

        if (staffData.length > 0 && wfhData.length > 0) {
          setValue("worksFromHome", "hybrid");
        } else if (staffData.length === 0 && wfhData.length > 0) {
          setValue("worksFromHome", "only-wfh");
        } else if (staffData.length > 0 && wfhData.length === 0) {
          setValue("worksFromHome", "only-office");
        }

        const staffDataObject = staffData[0] || {};
        const wfhDataObject = wfhData[0] || {};

        if (Object.keys(wfhDataObject).length > 0) {
          setWfhFormDataId(wfhDataObject.id);
          setValue("office", wfhDataObject.office_id ?? "");
          setValue("fteDaysPerYear", wfhDataObject.fte_days_year ?? "");
          setValue("fteHoursPerWeek", wfhDataObject.fte_hours_week ?? "");
          setValue(
            "isRenewableEnergy",
            wfhDataObject.is_renewable_energy ?? ""
          );
          setValue("usingComputer", wfhDataObject.using_computer ?? "");
          setValue(
            "usingMonitor",
            wfhDataObject.using_stand_alone_monitor ?? ""
          );
          setValue("typeOfWorkPlace", wfhDataObject.work_place_type ?? "");
          setValue(
            "usingHeatingOrCooling",
            wfhDataObject.using_heating_cooling ?? ""
          );
          setValue("usingGasHeating", wfhDataObject.using_gas_heating ?? "");

          const countryName =
            CountryCodeNameObject()[wfhDataObject.country ?? ""];

          setStateData(countryStateDataJSON()[countryName] || []);

          const stateName = StateCodeNameObject(
            wfhDataObject.country,
            wfhDataObject.state
          )[wfhDataObject.state];

          setValue("country", `${countryName},${wfhDataObject.country}`);
          setValue("state", `${stateName},${wfhDataObject.state}`);
        }

        if (Object.keys(staffDataObject).length > 0) {
          setValue("office", staffDataObject.office_id ?? "");
          setStaffDataId(staffDataObject.id);
          const transportMapping = {
            total_bicycle: "bicycle",
            total_bus: "bus",
            total_car: "car",
            total_ferry: "ferry-foot-passenger",
            total_motorbike: "motorbike-scooter",
            total_taxi: "taxi-national-average",
            total_train: "train",
            total_tram: "light-rail-and-tram",
            total_walking: "walk",
          };

          let selectedTransport = "";
          let distanceTravelled = 0;

          for (const [key, value] of Object.entries(staffDataObject)) {
            if (value > 0 && transportMapping[key]) {
              selectedTransport = transportMapping[key];
              distanceTravelled = value;
              break;
            }
          }

          if (selectedTransport) {
            setValue("commuteDays", staffDataObject.commute_days ?? "");
            setValue("transportMode", selectedTransport);
            setValue("distanceTravelled", distanceTravelled);
            setValue("carType", staffDataObject.car_type ?? "");
            setValue("carSize", staffDataObject.car_size ?? "");
            setValue("fuelEfficiency", staffDataObject.fuelEfficiency ?? null);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const onSubmit = async (data) => {
    setSubmitLoading(true);

    data.fuelEfficiency = data.fuelEfficiency
      ? parseFloat(data.fuelEfficiency)
      : null;

    if (data.country && data.state) {
      data.country = data.country.split(",")[1];
      data.state = data.state.split(",")[1];
    }

    data.employeeEngagementFormId = employeeEngagementFormId;
    data.wfhFormDataId = wfhFormDataId;
    data.staffDataId = staffDataId;
    data.employeeName = employeeName;
    data.uid = uid;
    try {
      await saveEmployeeEngagementFormData(data);

      navigate("/welcome/data/employee-engagement-response-overview", {
        state: {
          ...location.state,
        },
      });
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (worksFromHome === "only-wfh") {
      resetField("commuteDays");
      resetField("transportMode");
      resetField("distanceTravelled");
      resetField("carSize");
      resetField("carType");
      resetField("fuelEfficiency");
      resetField("renewableCharging");
    } else if (worksFromHome === "only-office") {
      resetField("country");
      resetField("state");
      resetField("fteDaysPerYear");
      resetField("fteHoursPerWeek");
      resetField("isRenewableEnergy");
      resetField("usingComputer");
      resetField("usingMonitor");
      resetField("typeOfWorkPlace");
      resetField("usingHeatingOrCooling");
      resetField("usingGasHeating");
    }
  }, [worksFromHome, resetField]);

  useEffect(() => {
    if (employeeEngagementFormId && uid) {
      get();
    }
  }, [employeeEngagementFormId, uid]);

  useEffect(() => {
    if (location.state) {
      const {
        employeeEngagementFormId,
        employeeEngagementFormDataId,
        employeeName,
      } = location.state;
      setEmployeeEngagementFormId(employeeEngagementFormId);
      setUid(employeeEngagementFormDataId);
      setEmployeeName(employeeName);
      // console.log("employeeName", employeeName);
    }
  }, [location.state]);

  return (
    <>
      <PageHeader
        title="Employee Engagement Calculator"
        description={`This tool helps with calculating and understanding the environmental impact of your daily commute to work or work from home environment. Whether you're driving, taking public transportation, or working from home, the Employee Engagement Calculator measures the related carbon emissions (CO2e) for each activity`}
        className="mb-3"
      />
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "200px" }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <fieldset
            disabled={submitLoading || formDetails.status === "Completed"}
          >
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card className="mt-3">
                <Card.Body>
                  <Form.Group controlId="formOffice" className="mb-3">
                    <Form.Label>Which office do you work from?</Form.Label>
                    <Form.Select
                      {...register("office", {
                        required: "This field is required",
                      })}
                      // isInvalid={!!errors.office}
                      className={errors.office ? "is-invalid" : ""}
                    >
                      <option value="">Select office</option>
                      {officeData.map((office) => (
                        <option key={office.value} value={office.value}>
                          {office.label}
                        </option>
                      ))}
                    </Form.Select>

                    {errors.office && (
                      <Form.Text className="text-danger">
                        {errors.office.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formWorksFromHome">
                    <Form.Label>How do you get to work?</Form.Label>
                    <div>
                      <Form.Check
                        type="radio"
                        label="I only work from home"
                        value="only-wfh"
                        {...register("worksFromHome", {
                          required: "This field is required",
                        })}
                        name="worksFromHome"
                        id="worksFromHomeYes"
                        className={errors.worksFromHome ? "is-invalid" : ""}
                      />
                      <Form.Check
                        type="radio"
                        label="I always go to the office"
                        value="only-office"
                        {...register("worksFromHome", {
                          required: "This field is required",
                        })}
                        name="worksFromHome"
                        id="worksFromHomeNo"
                        className={errors.worksFromHome ? "is-invalid" : ""}
                      />
                      <Form.Check
                        type="radio"
                        label="Hybrid - Sometimes I work from home, sometimes I go to the office"
                        value="hybrid"
                        {...register("worksFromHome", {
                          required: "This field is required",
                        })}
                        name="worksFromHome"
                        id="worksFromHomeSometimes"
                        className={errors.worksFromHome ? "is-invalid" : ""}
                      />
                    </div>
                    {errors.worksFromHome && (
                      <Form.Text className="text-danger">
                        {errors.worksFromHome.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Card.Body>
              </Card>
              {showTransportQuestions && (
                <>
                  <PageHeader
                    title="Daily Commute Questions"
                    description="Provide details about your daily commute to work. This information will help us calculate the environmental impact of your transportation choices, allowing for a more accurate assessment of your carbon footprint."
                    className="mb-3 mt-4"
                  ></PageHeader>

                  <Card className="mt-3">
                    <Card.Body>
                      <TransportQuestions
                        register={register}
                        watch={watch}
                        errors={errors}
                      />
                    </Card.Body>
                  </Card>
                </>
              )}

              {showWfhQuestions && (
                <>
                  <PageHeader
                    title="Working From Home Questions"
                    description="Provide insights into your working from home habits. This information will help us evaluate the impact on energy usage and emissions, enabling a comprehensive understanding of your carbon footprint while working remotely."
                    className="mb-3 mt-4"
                  ></PageHeader>
                  <Card className="mt-3">
                    <Card.Body>
                      <WfhQuestions
                        register={register}
                        watch={watch}
                        control={control}
                        setValue={setValue}
                        stateData={stateData}
                        setStateData={setStateData}
                        errors={errors}
                      />
                    </Card.Body>
                  </Card>
                </>
              )}
              <Button
                variant="primary"
                type="submit"
                className="mt-3"
                disabled={submitLoading || formDetails.status === "Completed"}
              >
                {submitLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Submitting...
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </Form>
          </fieldset>
        </>
      )}
    </>
  );
};

const WfhQuestions = ({
  register,
  watch,
  setValue,
  stateData,
  setStateData,
  errors,
}) => {
  const usingHeatingOrCooling = watch("usingHeatingOrCooling");
  const countryStateJSON = countryStateDataJSON();

  const country = watch("country");

  useEffect(() => {
    if (country) {
      const countryName = country.split(",")[0];
      setStateData(countryStateJSON[countryName] || []);
    }
  }, [country]);

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setValue("country", selectedCountry);
    setStateData(countryStateJSON[selectedCountry.split(",")[0]] || []);
    setValue("state", "");
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setValue("state", selectedState);
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Which country are you located in?</Form.Label>
            <Form.Select
              {...register("country", { required: "This field is required" })}
              onChange={handleCountryChange}
              className={errors.country ? "is-invalid" : ""}
            >
              <option value="">Select the country</option>
              {targetedCountryCodeList(["Australia", "United States"]).map(
                (data, index) => (
                  <option key={index} value={data.name + "," + data.code}>
                    {data.name}
                  </option>
                )
              )}
            </Form.Select>
            {errors.country && (
              <Form.Text className="text-danger">
                {errors.country.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>What state are you based in?</Form.Label>
            <Form.Select
              {...register("state", { required: "This field is required" })}
              onChange={handleStateChange}
              className={errors.state ? "is-invalid" : ""}
            >
              <option value="">Select the state</option>
              {stateData.map((data, index) => (
                <option key={index} value={data.name + "," + data.code}>
                  {data.name}
                </option>
              ))}
            </Form.Select>
            {errors.state && (
              <Form.Text className="text-danger">
                {errors.state.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>
              How many days a year do you work from home? (A full-time load is
              240 days)
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="FTE days per year"
              {...register("fteDaysPerYear", {
                required: "This field is required",
              })}
              className={errors.fteDaysPerYear ? "is-invalid" : ""}
            />
            {errors.fteDaysPerYear && (
              <Form.Text className="text-danger">
                {errors.fteDaysPerYear.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>
              How many hours a week do you work from home? (A full-time load is
              40 hours)
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="FTE hours per week"
              {...register("fteHoursPerWeek", {
                required: "This field is required",
              })}
              className={errors.fteHoursPerWeek ? "is-invalid" : ""}
            />
            {errors.fteHoursPerWeek && (
              <Form.Text className="text-danger">
                {errors.fteHoursPerWeek.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>
              Do you use renewable or carbon neutral energy at home?
            </Form.Label>
            <Form.Select
              {...register("isRenewableEnergy", {
                required: "This field is required",
              })}
              className={errors.isRenewableEnergy ? "is-invalid" : ""}
            >
              <option value="">Please select...</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Form.Select>
            {errors.isRenewableEnergy && (
              <Form.Text className="text-danger">
                {errors.isRenewableEnergy.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>
              Do you work from a small (ie. sunroom) large (ie. office) or a
              common area?
            </Form.Label>
            <Form.Select
              {...register("typeOfWorkPlace", {
                required: "This field is required",
              })}
              className={errors.typeOfWorkPlace ? "is-invalid" : ""}
            >
              <option value="">Please select...</option>
              <option value="Small">Small</option>
              <option value="Large">Large</option>
              <option value="Shared">Shared</option>
            </Form.Select>
            {errors.typeOfWorkPlace && (
              <Form.Text className="text-danger">
                {errors.typeOfWorkPlace.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>
              Do you use a computer or laptop to complete work-related tasks at
              home?
            </Form.Label>
            <Form.Select
              {...register("usingComputer", {
                required: "This field is required",
              })}
              className={errors.usingComputer ? "is-invalid" : ""}
            >
              <option value="">Please select...</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </Form.Select>
            {errors.usingComputer && (
              <Form.Text className="text-danger">
                {errors.usingComputer.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Do you use an extra monitor?</Form.Label>
            <Form.Select
              {...register("usingMonitor", {
                required: "This field is required",
              })}
              className={errors.usingMonitor ? "is-invalid" : ""}
            >
              <option value="">Please select...</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </Form.Select>
            {errors.usingMonitor && (
              <Form.Text className="text-danger">
                {errors.usingMonitor.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>
              Are you using heating or cooling in the area where you work?
            </Form.Label>
            <Form.Select
              {...register("usingHeatingOrCooling", {
                required: "This field is required",
              })}
              className={errors.usingHeatingOrCooling ? "is-invalid" : ""}
            >
              <option value="">Please select...</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </Form.Select>
            {errors.usingHeatingOrCooling && (
              <Form.Text className="text-danger">
                {errors.usingHeatingOrCooling.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
        {usingHeatingOrCooling === "Yes" && (
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Do you use gas heating for this space?</Form.Label>
              <Form.Select
                {...register("usingGasHeating", {
                  required: "This field is required",
                })}
                className={errors.usingGasHeating ? "is-invalid" : ""}
              >
                <option value="">Please select...</option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </Form.Select>
              {errors.usingGasHeating && (
                <Form.Text className="text-danger">
                  {errors.usingGasHeating.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        )}
      </Row>
    </>
  );
};

const TransportQuestions = ({ register, watch, errors }) => {
  const transportMode = watch("transportMode");
  const carType = watch("carType");
  const showCarQuestions = transportMode === "car";

  const showFuelEfficiencyQuestion = ["diesel", "petrol", "lpg"].includes(
    carType
  );
  const showChargingQuestion = ["bev", "phev"].includes(carType);

  return (
    <>
      {/* New question for days commuting to the office */}
      <Form.Group controlId="formCommuteDays" className="mb-3">
        <Form.Label>
          How many days per week do you typically commute to the office?
        </Form.Label>
        <Form.Control
          type="number"
          {...register("commuteDays", {
            required: "This field is required",
            min: { value: 0, message: "Must be at least 0 days" },
            max: { value: 7, message: "Must be no more than 7 days" },
          })}
          className={errors.commuteDays ? "is-invalid" : ""}
        />
        {errors.commuteDays && (
          <Form.Text className="text-danger">
            {errors.commuteDays.message}
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group controlId="formTransportMode" className="mb-3">
        <Form.Label>Your mode of transport</Form.Label>
        <Form.Control
          as="select"
          {...register("transportMode", { required: "This field is required" })}
          className={errors.transportMode ? "is-invalid" : ""}
        >
          <option value="">Select...</option>
          <option value="bicycle">Bicycle</option>
          <option value="bus">Bus</option>
          <option value="walk">Walk</option>
          <option value="ferry-foot-passenger">Ferry : Foot passenger</option>
          <option value="motorbike-scooter">Motorbike/scooter</option>
          <option value="taxi-national-average">Taxi - National Average</option>
          <option value="train">Train</option>
          <option value="light-rail-and-tram">Light rail and tram</option>
          <option value="car">Car</option>
        </Form.Control>
        {errors.transportMode && (
          <Form.Text className="text-danger">
            {errors.transportMode.message}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group controlId="formDistanceTravelled" className="mb-3">
        <Form.Label>Distance travelled one way (km)</Form.Label>
        <Form.Control
          type="number"
          {...register("distanceTravelled", {
            required: "This field is required",
          })}
          className={errors.distanceTravelled ? "is-invalid" : ""}
        />
        {errors.distanceTravelled && (
          <Form.Text className="text-danger">
            {errors.distanceTravelled.message}
          </Form.Text>
        )}
      </Form.Group>

      {showCarQuestions && (
        <>
          <Form.Group controlId="formCarSize" className="mb-3">
            <Form.Label>What is the size of your car?</Form.Label>
            <Form.Control
              as="select"
              {...register("carSize", { required: "This field is required" })}
              className={errors.carSize ? "is-invalid" : ""}
            >
              <option value="">Select...</option>
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="small">Small</option>
            </Form.Control>
            {errors.carSize && (
              <Form.Text className="text-danger">
                {errors.carSize.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group controlId="formCarType">
            <Form.Label>What type of car do you drive?</Form.Label>
            <Form.Control
              as="select"
              {...register("carType", { required: "This field is required" })}
              className={errors.carType ? "is-invalid" : ""}
            >
              <option value="">Select...</option>
              <option value="diesel">Diesel</option>
              <option value="petrol">Petrol</option>
              <option value="lpg">LPG</option>
              <option value="hybrid">Hybrid</option>
              <option value="bev">Battery electric vehicle (BEV)</option>
              <option value="phev">
                Plug-in hybrid electric vehicle (PHEV)
              </option>
            </Form.Control>
            {errors.carType && (
              <Form.Text className="text-danger">
                {errors.carType.message}
              </Form.Text>
            )}
          </Form.Group>

          {/* Conditional field for fuel efficiency */}
          {showFuelEfficiencyQuestion && (
            <Form.Group controlId="formFuelEfficiency">
              <Form.Label>
                What is your car's fuel efficiency (in liters per 100
                kilometers)? - optional
              </Form.Label>
              <Form.Control
                type="number"
                step="0.1"
                {...register("fuelEfficiency")}
                className={errors.fuelEfficiency ? "is-invalid" : ""}
              />
              {errors.fuelEfficiency && (
                <Form.Text className="text-danger">
                  {errors.fuelEfficiency.message}
                </Form.Text>
              )}
            </Form.Group>
          )}

          {/* Conditional field for charging at home */}
          {showChargingQuestion && (
            <Form.Group controlId="formRenewableCharging">
              <Form.Label>
                Do you charge your car at home using carbon-neutral or renewable
                energy?
              </Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Yes"
                  value="Yes"
                  {...register("renewableCharging", {
                    required: "This field is required",
                  })}
                  className={errors.renewableCharging ? "is-invalid" : ""}
                />
                <Form.Check
                  type="radio"
                  label="No"
                  value="No"
                  {...register("renewableCharging", {
                    required: "This field is required",
                  })}
                  className={errors.renewableCharging ? "is-invalid" : ""}
                />
              </div>
              {errors.renewableCharging && (
                <Form.Text className="text-danger">
                  {errors.renewableCharging.message}
                </Form.Text>
              )}
            </Form.Group>
          )}
        </>
      )}
    </>
  );
};

export default EmployeeEngagementEmployeeSpecificForm;
