export const Industries = [
  "Agriculture, forestry and fishing",
  "Mining",
  "Manufacturing",
  "Electricity, gas, water and waste services",
  "Construction",
  "Wholesale trade",
  "Retail trade",
  "Accommodation and food services",
  "Transport, postal and warehousing",
  "Information media and telecommunications",
  "Rental, hiring and real estate services",
  "Professional, scientific and technical services",
  "Administrative and support services",
  "Public administration and safety",
  "Education and training",
  "Health care and social assistance",
  "Arts and recreation services",
  "Other services",
];

export const australianStates = [
  { name: "Australian Capital Territory", code: "ACT" },
  { name: "New South Wales", code: "NSW" },
  { name: "Northern Territory", code: "NT" },
  { name: "Queensland", code: "QLD" },
  { name: "South Australia", code: "SA" },
  { name: "Tasmania", code: "TAS" },
  { name: "Victoria", code: "VIC" },
  { name: "Western Australia", code: "WA" },
];
