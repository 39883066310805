import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Card, Form, Button, Row, Col, Spinner } from "react-bootstrap";
import PageHeader from "../common/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getEmployeeEngagementEstimationFormData,
  getEmployeeEngagementForm,
  saveEmployeeEngagementEstimationFormData,
} from "./EmployeeEngagementAPI";

const EmployeeEngagementEstimationForm = () => {
  const storedLocationsJSON = sessionStorage.getItem("userBusinessLocations");
  const locations = JSON.parse(storedLocationsJSON);
  const [employeeEngagementFormId, setEmployeeEngagementFormId] = useState("");
  // const [uid, setUid] = useState();
  const [formOfficeId, setFormOfficeId] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [wfhFormDataId, setWfhFormDataId] = useState();
  const [staffDataId, setStaffDataId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const officeData = locations.map((office) => ({
    label: `${office.officename} (${office.location}, ${office.country})`,
    value: office.office_id,
    country: office.country,
    state: office.location,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    setValue,
    reset, // Reset method from useForm
  } = useForm();

  const workDaysPerWeek = watch("workDaysPerWeek", 0); // Default to 0
  const showCommuteQuestions = workDaysPerWeek > 0;

  async function get() {
    try {
      setIsLoading(true);
      setValue("office", formOfficeId);

      const formDetailsResponse = await getEmployeeEngagementForm(
        employeeEngagementFormId
      );
      setFormDetails(formDetailsResponse[0]);

      // console.log("employeeEngagementFormId", employeeEngagementFormId);
      // console.log("formOfficeId", formOfficeId);

      const employeeEngagementEstimationFormData =
        await getEmployeeEngagementEstimationFormData(
          employeeEngagementFormId,
          formOfficeId
        );
      // console.log(
      //   "employeeEngagementEstimationFormData",
      //   employeeEngagementEstimationFormData
      // );

      if (employeeEngagementEstimationFormData) {
        const { staffData = [], wfhData = [] } =
          employeeEngagementEstimationFormData;

        const staffDataObject = staffData[0] || {};
        const wfhDataObject = wfhData[0] || {};

        if (
          Object.keys(staffDataObject).length > 0 &&
          Object.keys(wfhDataObject).length > 0
        ) {
          setValue("workDaysPerWeek", staffDataObject.commute_days);
        } else if (
          Object.keys(staffDataObject).length === 0 &&
          Object.keys(wfhDataObject).length > 0
        ) {
          setValue("workDaysPerWeek", 0);
        } else if (
          Object.keys(staffDataObject).length > 0 &&
          Object.keys(wfhDataObject).length === 0
        ) {
          setValue("workDaysPerWeek", staffDataObject.commute_days);
        } else {
          reset({
            // office: "",
            numberOfEmployees: "",
            workDaysPerWeek: "",
            commuteDistance: "",
            travelByCar: "",
            travelByPublicTransport: "",
            travelByWalkingBike: "",
          });
        }

        if (Object.keys(wfhDataObject).length > 0) {
          setWfhFormDataId(wfhDataObject.id);
          setValue("numberOfEmployees", wfhDataObject.num_employees ?? "");
        }

        if (Object.keys(staffDataObject).length > 0) {
          setStaffDataId(staffDataObject.id);
          setValue("numberOfEmployees", staffDataObject.num_employees ?? "");
          setValue("commuteDistance", staffDataObject.total_car ?? "");
          setValue("travelByCar", staffDataObject.car_percent ?? "");
          setValue(
            "travelByPublicTransport",
            staffDataObject.public_transport_percent ?? ""
          );
          setValue(
            "travelByWalkingBike",
            staffDataObject.walk_bike_percent ?? ""
          );
        }
      }
    } catch (error) {
      console.error("Error getting data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const onSubmit = async (data) => {
    clearErrors("totalPercentage");

    // console.log("data", data);
    setSubmitLoading(true);

    const totalPercentage =
      Number(data.travelByCar) +
      Number(data.travelByPublicTransport) +
      Number(data.travelByWalkingBike);

    if (showCommuteQuestions && totalPercentage !== 100) {
      setError("totalPercentage", {
        type: "manual",
        message: "The total percentage of travel methods must add up to 100.",
      });
      setSubmitLoading(false);

      return;
    }

    clearErrors("totalPercentage");
    // console.log("data", data);
    // console.log("employeeEngagementFormId", employeeEngagementFormId);
    data.employeeEngagementFormId = employeeEngagementFormId;
    data.wfhFormDataId = wfhFormDataId;
    data.staffDataId = staffDataId;

    // setSubmitLoading(false);

    try {
      await saveEmployeeEngagementEstimationFormData(data);

      navigate("/welcome/data/employee-engagement-response-overview", {
        state: {
          ...location.state,
        },
      });
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (employeeEngagementFormId && formOfficeId) {
      get();
    }
  }, [employeeEngagementFormId, formOfficeId]);

  useEffect(() => {
    if (location.state) {
      const { employeeEngagementFormId, officeId } = location.state;
      setEmployeeEngagementFormId(employeeEngagementFormId);
      setFormOfficeId(officeId);
    }
  }, [location.state]);

  return (
    <>
      <PageHeader
        title="Employee Engagement Estimation Calculator"
        description={`This tool helps with calculating and understanding the environmental impact of your daily commute to work or work from home environment. Whether you're driving, taking public transportation, or working from home, the Employee Engagement Calculator measures the related carbon emissions (CO2e) for each activity`}
        className="mb-3"
      />
      <fieldset disabled={submitLoading || formDetails.status === "Completed"}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card className="mt-3">
            <Card.Body>
              <Form.Group controlId="formOffice" className="mb-3">
                <Form.Label>Office</Form.Label>
                <Form.Select
                  {...register("office", { required: true })}
                  isInvalid={!!errors.office}
                  onChange={(e) => {
                    const selectedOfficeId = e.target.value;
                    setFormOfficeId(selectedOfficeId);
                  }}
                >
                  <option value="">Select office</option>
                  {officeData.map((office) => (
                    <option key={office.value} value={office.value}>
                      {office.label}
                    </option>
                  ))}
                </Form.Select>
                {errors.office && (
                  <Form.Control.Feedback type="invalid">
                    This field is required.
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <>
                  <Form.Group
                    controlId="formNumberOfEmployees"
                    className="mb-3"
                  >
                    <Form.Label>
                      How many employees are you estimating for?
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter number of employees"
                      {...register("numberOfEmployees", {
                        required: true,
                        min: 1,
                      })}
                      isInvalid={!!errors.numberOfEmployees}
                    />
                    {errors.numberOfEmployees && (
                      <Form.Control.Feedback type="invalid">
                        This field is required and must be at least 1.
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formWorkDaysPerWeek" className="mb-3">
                    <Form.Label>
                      On average, how many days per week will each employee work
                      from the office?
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter number of days per week"
                      {...register("workDaysPerWeek", {
                        required: "This field is required.",
                        min: {
                          value: 0,
                          message: "Enter a number between 0 and 5.",
                        },
                        max: {
                          value: 5,
                          message: "Enter a number between 0 and 5.",
                        },
                      })}
                      isInvalid={!!errors.workDaysPerWeek}
                    />

                    {errors.workDaysPerWeek && (
                      <Form.Control.Feedback type="invalid">
                        {errors.workDaysPerWeek.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  {showCommuteQuestions && (
                    <>
                      <Form.Group
                        controlId="formCommuteDistance"
                        className="mb-3"
                      >
                        <Form.Label>
                          Average commute distance one way (km)
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter average commute distance"
                          {...register("commuteDistance", {
                            required: true,
                            min: 0,
                          })}
                          isInvalid={!!errors.commuteDistance}
                        />
                        {errors.commuteDistance && (
                          <Form.Control.Feedback type="invalid">
                            This field is required and must be a positive
                            number.
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>

                      {/* New question for travel percentages */}
                      <Form.Group controlId="formTravelBy" className="mb-3">
                        <Form.Label>
                          What percentage of employees travel...
                        </Form.Label>
                        <Row>
                          <Col>
                            <Form.Label>By car</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter percentage"
                              {...register("travelByCar", {
                                required: true,
                                min: 0,
                                max: 100,
                              })}
                              onChange={(e) => {
                                clearErrors("totalPercentage");
                              }}
                              isInvalid={!!errors.travelByCar}
                            />
                            {errors.travelByCar && (
                              <Form.Control.Feedback type="invalid">
                                This field is required and must be between 0 and
                                100.
                              </Form.Control.Feedback>
                            )}
                          </Col>
                          <Col>
                            <Form.Label>By public transport</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter percentage"
                              {...register("travelByPublicTransport", {
                                required: true,
                                min: 0,
                                max: 100,
                              })}
                              onChange={(e) => {
                                clearErrors("totalPercentage");
                              }}
                              isInvalid={!!errors.travelByPublicTransport}
                            />
                            {errors.travelByPublicTransport && (
                              <Form.Control.Feedback type="invalid">
                                This field is required and must be between 0 and
                                100.
                              </Form.Control.Feedback>
                            )}
                          </Col>
                          <Col>
                            <Form.Label>By walking/bike</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter percentage"
                              {...register("travelByWalkingBike", {
                                required: true,
                                min: 0,
                                max: 100,
                              })}
                              onChange={(e) => {
                                clearErrors("totalPercentage");
                              }}
                              isInvalid={!!errors.travelByWalkingBike}
                            />
                            {errors.travelByWalkingBike && (
                              <Form.Control.Feedback type="invalid">
                                This field is required and must be between 0 and
                                100.
                              </Form.Control.Feedback>
                            )}
                          </Col>
                        </Row>
                        {errors.totalPercentage && (
                          <Form.Text className="text-danger">
                            {errors.totalPercentage.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </>
                  )}
                </>
              )}
            </Card.Body>
          </Card>

          <Button
            variant="primary"
            type="submit"
            className="mt-3"
            disabled={submitLoading || isLoading}
          >
            {submitLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Submitting...
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Form>
      </fieldset>
    </>
  );
};

export default EmployeeEngagementEstimationForm;
