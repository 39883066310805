import React, { useContext, useEffect, useState } from "react";
import { Nav, Tooltip, OverlayTrigger, Alert } from "react-bootstrap";
import ProfileDropdown from "../../../components/navbar/top/ProfileDropdown";
import ImpersonateUser from "../../../components/navbar/top/ImpersonateUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../context/Context";
import {
  getActiveUserData,
  getUerRole,
  setActiveUserData,
} from "../../../helpers/store";
import io from "socket.io-client";
import { getAllFiles } from "../../new-overview/newOverviewAPI";
import NetNadaHelp from "./NetNadaHelp";
import BookMeeting from "./BookMeeting";
import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../../helpers/unifiedDataHelper";

const TopNavRightSideNavItem = () => {
  const {
    config: { isDark, isRTL },
    setConfig,
  } = useContext(AppContext);
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [processingStatus, setProcessingStatus] = useState(
    activeUser.processingStatus
  );
  const [billDataCalculationStatus, setBillDataCalculationStatus] =
    useState(false);

  async function updateChartData() {
    //remove unified data from store
    await removeUnifiedDateFromStore();
    //fetch unified data from firebase
    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      new Date(activeUser.currentStartDate),
      new Date(activeUser.currentEndDate)
    );
    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);
  }

  async function getBillDataCalculationStatus() {
    try {
      const jsonData = await getAllFiles();
      //console.log(jsonData);
      let check = false;
      let unifiedDataCalCompleted = false;

      for (var i = 0; i < jsonData.length; i++) {
        if (jsonData[i]["approval_status"] === "Pending") {
          check = true;
          break;
        }
        //atleast one bill emission should be completed
        if (jsonData[i]["approval_status"] === "Success") {
          //update 'unifiedDataCalCompleted' to 'true'
          unifiedDataCalCompleted = true;
          sessionStorage.setItem("unifiedDataCalCompleted", true);
        }
      }
      //console.log("check", check);
      setBillDataCalculationStatus(check);
      if (unifiedDataCalCompleted) {
        updateChartData();
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  function connectSocket() {
    const socket = io({
      query: {
        businessId: activeUser.businessId,
      },
    });

    socket.on("SCOPES_CALCULATED", async (data) => {
      if (activeUser.businessId !== data.businessId) return;

      console.log("SCOPES_CALCULATED for businessId", data.businessId);

      //update user data in session storage
      let userData = activeUser;
      userData["processingStatus"] = "Completed";
      setActiveUserData(JSON.stringify(userData));
      setProcessingStatus("Completed");

      //trigger chart data calculation
      updateChartData();

      //update 'unifiedDataCalCompleted' to 'true'
      sessionStorage.setItem("unifiedDataCalCompleted", true);
    });

    socket.on("CSV_CALCULATED", async (data) => {
      if (activeUser.businessId !== data.businessId) return;

      console.log("CSV_CALCULATED for businessId", data.businessId);

      //trigger chart data calculation
      updateChartData();
      //update 'unifiedDataCalCompleted' to 'true'
      sessionStorage.setItem("unifiedDataCalCompleted", true);

      sessionStorage.setItem("csvPresent", true);
      setProcessingStatus("Completed");
    });

    socket.on("BILLS_CALCULATED", async (data) => {
      if (activeUser.businessId !== data.businessId) return;

      console.log("BILLS_CALCULATED for businessId", data.businessId);

      //trigger chart data calculation
      updateChartData();
      setBillDataCalculationStatus(true);
    });

    socket.on("RETRIGGER_CALCULATIONS", (data) => {
      if (activeUser.businessId !== data.businessId) return;

      console.log("RETRIGGER_CALCULATIONS for businessId", data.businessId);

      if (
        !sessionStorage.getItem("csvPresent") ||
        sessionStorage.getItem("csvPresent") === undefined
      )
        return;

      const csvPresent = JSON.parse(sessionStorage.getItem("csvPresent"));
      if (!csvPresent) {
        //update user data in session storage
        const userData = JSON.parse(getActiveUserData());
        userData.processingStatus = data.processingStatus;
        setActiveUserData(JSON.stringify(userData));
      }

      setProcessingStatus(data.processingStatus);
    });

    return () => {
      socket.disconnect();
    };
  }

  useEffect(() => {
    connectSocket();
  }, []);

  useEffect(() => {
    getBillDataCalculationStatus();
  }, []);

  //console.log("billDataCalculationStatus", billDataCalculationStatus);

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {processingStatus === "InProgress" && (
        <Alert key={"warning"} variant={"warning"}>
          Your emissions calculations are now taking place. Learn more about our
          methodology{" "}
          <a
            href="https://www.netnada.com.au/certifications/methodology"
            target="_blank"
          >
            here
          </a>
        </Alert>
      )}
      {billDataCalculationStatus && (
        <Alert key={"warning"} variant={"warning"}>
          We are now processing the invoices you have uploaded. Learn more about
          our methodology{" "}
          <a
            href="https://www.netnada.com.au/certifications/methodology"
            target="_blank"
          >
            here
          </a>
        </Alert>
      )}{" "}
      {(getUerRole() === "SUPER_ADMIN" || getUerRole() === "ADVISOR") && (
        <ImpersonateUser />
      )}
      {/* Removed the InviteUser component from the navbar NV367 */}
      {/* <InviteUser /> */}
      <NetNadaHelp />
      <BookMeeting />
      <Nav.Item as={"li"}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig("isDark", !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? "bottom" : "left"}
            overlay={
              <Tooltip id="ThemeColor">
                {isDark ? "Switch to light theme" : "Switch to dark theme"}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? faSun : faMoon}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
      {/* <NotificationDropdown />
      <NineDotMenu /> */}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
