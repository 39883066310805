import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { WasteContext } from "../../../context/Context";
import { getWasteDataByWasteId, submitWasteForm } from "./wasteAPI";
import { useLocation } from "react-router-dom";
const WasteHubProvider = ({ children }) => {
  const location = useLocation();
  const [step, setStep] = useState(15);
  const [key, setKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [categoriesData, setCategoriesData] = useState([]);
  const lastStep = () => {
    setStep(100);
  };
  const nextStep = () => {
    setStep((prevStep) => prevStep + 25);
  };
  const prevStep = () => {
    setStep((prevStep) => Math.max(prevStep - 25, 1));
  };
  const handleSubmitContext = async () => {
    setIsLoading(true);
    setStep(85);
    // console.log("CategoriesData", categoriesData);
    // console.log("FormData", formData);
    await submitWasteForm({ formData, categoriesData });
    setIsLoading(false);
    lastStep();
  };
  const getSubmittedDataById = async (formId) => {
    setIsLoading(true);
    const response = await getWasteDataByWasteId(location.state.formId);
    const filteredWasteData = response?.wasteData?.map((item) => ({
      wasteType: item.category_name,
      form_id: item.form_id,
      frequency: item.freq_collection,
      id: item.id,
      numBins: item.n_of_bins,
      percBins: item.perc_bins,
      volumeOfCollections: item.total_volume,
      volBins: item.vol_bins,
      totalWeightOfWaste: item.weight_tonnes,
      source: item.source,
    }));
    setFormData({
      formName: response.formData.name,
      formCategory: response.formData.category,
      userId: response.formData.uploaded_by,
      office: { value: response.formData.office_id },
      startDate: response.formData.startdate,
      endDate: response.formData.enddate,
      update_date: response.formData.update_date,
    });
    setCategoriesData(filteredWasteData);
    setIsLoading(false);
  };
  useEffect(() => {
    //Get the result when navigating to the result page
    if (location?.state?.formId !== undefined) {
      lastStep();
      getSubmittedDataById();
    }
  }, [location]);
  const value = {
    step,
    setStep,
    nextStep,
    prevStep,
    isLoading,
    key,
    setKey,
    lastStep,
    formData,
    setFormData,
    // isButtonDisabled: isButtonDisabled,
    setCategoriesData,
    categoriesData,
    handleSubmitContext,
  };
  //get Locations of BusinessId
  //
  return (
    <WasteContext.Provider value={value}>{children}</WasteContext.Provider>
  );
};
WasteHubProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default WasteHubProvider;
