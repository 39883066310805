import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button, Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { addQuestion, getBusinessDetails } from "../solutionsAPI";
import {
  getActiveUserData,
  getUserData,
  setActiveUserData,
  setUserData,
} from "../../../helpers/store";

const QuestionModal = ({ showQuestionModal, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [key1, setKey1] = useState("");
  const [key2, setKey2] = useState("");
  const [key3, setKey3] = useState("");
  const [key4, setKey4] = useState("");
  const [key5, setKey5] = useState("");

  const [locationData, setLocationData] = useState("");
  const [nameId, setNameId] = useState();
  const [selectedLocation, setSelectedLocation] = useState("");

  async function handleSave(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      //fetch bsuiness details from backend
      const businessDetails = await getBusinessDetails();
      // console.log("businessDetails=>", businessDetails);
      if (businessDetails) {
        const question = `Our business activity mostly takes place ${key1} that we ${key2}. Our people ${key3} for ${key4}. Our main business activity is ${businessDetails.category} and mostly takes place at home that we own. We have ${businessDetails.numberofemployees} employees and they travel frequently for business meetings. Our core operations ${key5} the transport of goods, materials or waste to or from our business.`;
        // console.log(question);
        const officeId =
          selectedLocation !== "" ? nameId[selectedLocation] : "";
        const body = {
          question,
          officeId,
        };
        // console.log(body);
        const { questionId } = await addQuestion(body);
        // console.log("questionId=>", questionId);
        //update questionId and aoiGeneratedStatus in session
        let userData = JSON.parse(getActiveUserData());
        userData.questionId = questionId;
        userData.aoiGeneratedStatus = "InProgress";
        setActiveUserData(JSON.stringify(userData));
        toast(`Question saved.`);
        handleClose(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  }

  //fetch list of offices for the user
  function getOffice() {
    const businessOffices = JSON.parse(
      sessionStorage.getItem("businessOffices")
    );
    let nameIdDict = {};
    for (let i = 0; i < businessOffices.length; i++) {
      let str = businessOffices[i].split(",");
      nameIdDict[str[0]] = str[1];
    }
    setNameId(nameIdDict);
    setLocationData(JSON.parse(sessionStorage.getItem("officeName")));
  }

  useEffect(() => {
    getOffice();
  }, []);

  return (
    <div>
      <Modal show={showQuestionModal} onHide={handleClose} size="xl" centered>
        <Form onSubmit={handleSave}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Tell us a bit about your business
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="gx-3 mb-3" style={{ justifyContent: "center" }}>
              <Col sm="auto">
                <h4>Our business activity mostly takes place</h4>
              </Col>
              <Col sm="auto">
                <Form.Select
                  value={key1}
                  onChange={(e) => setKey1(e.target.value)}
                  aria-label="Default select"
                >
                  <option value="">Select</option>
                  <option value="at home">at home</option>
                  <option value="in an office">in an office</option>
                  <option value="in a warehouse">in a warehouse</option>
                  <option value="in a factory">in a factory</option>
                </Form.Select>
              </Col>
              <Col sm="auto">
                <h4>that we</h4>{" "}
              </Col>
              <Col sm="auto">
                <Form.Select
                  value={key2}
                  onChange={(e) => setKey2(e.target.value)}
                  aria-label="Default select"
                >
                  <option value="">Select</option>
                  <option value="own">own</option>
                  <option value="lease">lease</option>
                </Form.Select>
              </Col>
            </Row>
            {key1 === "in an office" && (
              <Row className="gx-3 mb-3" style={{ justifyContent: "center" }}>
                <Col sm="auto">
                  <h4>At</h4>
                </Col>
                <Col sm="auto">
                  <Form.Select
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    aria-label="Default select"
                  >
                    <option>Select...</option>
                    {locationData
                      ? locationData.map((item, i) => (
                          <option key={item.id} value={item}>
                            {item}
                          </option>
                        ))
                      : null}
                  </Form.Select>
                </Col>
              </Row>
            )}
            {/* <Row className="gx-3 mb-3" style={{ justifyContent: "center" }}>
              <Col sm="auto">
                <h4>that we</h4>
              </Col>
              <Col sm="auto">
                <Form.Select
                  value={key2}
                  onChange={(e) => setKey2(e.target.value)}
                  aria-label="Default select"
                >
                  <option value="">Select</option>
                  <option value="own">own</option>
                  <option value="lease">lease</option>
                </Form.Select>
              </Col>
            </Row> */}
            <Row className="gx-3 mb-3" style={{ justifyContent: "center" }}>
              <Col sm="auto">
                <h4>Our people</h4>
              </Col>
              <Col sm="auto">
                <Form.Select
                  value={key3}
                  onChange={(e) => setKey3(e.target.value)}
                  aria-label="Default select"
                >
                  <option value="">Select</option>
                  <option value="travel frequently">travel frequently</option>
                  <option value="travel infrequently">
                    travel infrequently
                  </option>
                </Form.Select>
              </Col>
              <Col sm="auto">
                <h4>for</h4>{" "}
              </Col>
              <Col sm="auto">
                <Form.Select
                  value={key4}
                  onChange={(e) => setKey4(e.target.value)}
                  aria-label="Default select"
                >
                  <option value="">Select</option>
                  <option value="business meetings">business meetings</option>
                  <option value="off-site work">off-site work</option>
                </Form.Select>
              </Col>
            </Row>
            <Row className="gx-3 mb-3" style={{ justifyContent: "center" }}>
              <Col sm="auto">
                <h4>Our core operations</h4>
              </Col>
              <Col sm="auto">
                <Form.Select
                  value={key5}
                  onChange={(e) => setKey5(e.target.value)}
                  aria-label="Default select"
                >
                  <option value="">Select</option>
                  <option value="require">require</option>
                  <option value="do not require">do not require</option>
                </Form.Select>
              </Col>
            </Row>
            <Row className="gx-3 mb-3" style={{ justifyContent: "center" }}>
              <Col sm="auto">
                <h4>
                  the transport of goods, materials or waste to or from our
                  business.
                </h4>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {/* <Row className="gx-3 mt-5" style={{ justifyContent: "center" }}>
              <Col> */}
            <Button type="submit" disabled={!key1 || !key2 || !key3 || !key4}>
              Save
              {isLoading && (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginLeft: "10px" }}
                ></span>
              )}
            </Button>
            {/* </Col>
            </Row> */}
          </Modal.Footer>

          <ToastContainer />
        </Form>
      </Modal>
    </div>
  );
};

export default QuestionModal;
