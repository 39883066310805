import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Avatar from "../../../common/Avatar";
import Flex from "../../../common/Flex";
import { faVrCardboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ChatContentBodyIntro = ({ user, isGroup }) => (
  <Flex
    alignItems="center"
    className="position-relative p-3 border-bottom mb-3"
  >
    {/* <Avatar className={`${user.status} me-3`} size="2xl">
      <FontAwesomeIcon icon={user.avatarSrc} className="fs-0" />
    </Avatar> */}
    <Avatar size="2xl" src={user.avatarSrc} className="me-3" />
    <div className="flex-1">
      <h6 className="mb-0">
        {/* <Link
          to="/user/profile"
          className=" text-decoration-none stretched-link text-700"
        >
          {user.name}
        </Link> */}
        {user.name}
      </h6>
      <p className="mb-0">
        Hi I'm Nettie, an AI powered sustainability advisor that will help
        tailor sustainable solutions to your business. After the conversation is
        finished the solution will be saved in your profile. You can also
        request a meeting using the button in the navigation bar above.
      </p>
    </div>
  </Flex>
);

ChatContentBodyIntro.propTypes = {
  isGroup: PropTypes.bool,
  user: PropTypes.object.isRequired,
};

export default ChatContentBodyIntro;
