import React from "react";
import { Link } from "react-router-dom";
import BarChart from "./BarChart";
import { Row, Col } from "react-bootstrap";
import EmissionsAreaChart from "./EmissionsAreaChart";

function ScopeTwoCard({ data }) {
  const highlightStyle = {
    // fontWeight: 'bold',
    color: "#007bff",
  };

  const scopeTwoData = data?.map(({ month, scope2 }) => ({
    month,
    gas: parseFloat(scope2?.gas).toFixed(2),
    electricity: parseFloat(scope2?.electricity).toFixed(2),
    electricityConsumption: parseFloat(scope2?.electricityConsumption).toFixed(
      2
    ),
    locationBasedEmissions: parseFloat(
      scope2?.locationBasedElectricity
    ).toFixed(2),
    marketBasedEmissions: parseFloat(scope2?.marketBasedElectricity).toFixed(2),
  }));

  // console.log("ScopeTwoData", scopeTwoData);

  const keys = ["gas", "electricity"];
  const colorsChart = ["#ffca6c", "#99d6d6"];

  const generateAnalysis = (data) => {
    const gasValues = data.map((d) => parseFloat(d.gas));
    const electricityValues = data.map((d) => parseFloat(d.electricity));

    const hasZeroValue = data.some(
      (d) => parseFloat(d.gas) + parseFloat(d.electricity) === 0
    );

    const hasVariation = (values) => {
      const max = Math.max(...values);
      const min = Math.min(...values);
      return max > 10 * min;
    };

    if (hasZeroValue) {
      return (
        <>
          Scope 2 emissions generally show consistent usage across all months.
          It looks like there's a month with 0 emissions. You can update the
          information
          <Link to="/welcome/data/calculations" style={{ marginLeft: 5 }}>
            here
          </Link>{" "}
          to ensure we have complete monthly data.
        </>
      );
    } else if (hasVariation(gasValues) || hasVariation(electricityValues)) {
      return (
        <>
          Scope 2 emissions generally show consistent usage across all months.
          There appears to be a high variation in the data across the months.
          You can update the information
          <Link to="/welcome/data/all-invoices" style={{ marginLeft: 5 }}>
            here
          </Link>{" "}
          to ensure we have accurate monthly data.
        </>
      );
    }

    return "";
  };

  const analysisMessage = generateAnalysis(scopeTwoData);

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <h4>Scope 2</h4>
        <p>
          Scope 2 emissions are indirect greenhouse gas emissions associated
          with the purchase of electricity, steam, heating, and cooling. These
          emissions are considered indirect because they are a result of the
          activities of the generating plant, not the company itself. For
          instance, if a company buys electricity to power its office building,
          the emissions produced by the power plant generating that electricity
          are Scope 2 emissions.
        </p>
      </div>
      <Row>
        <Col lg={6}>
          <BarChart
            data={scopeTwoData}
            colorTheme={colorsChart}
            keys={keys}
            title={"Scope 2 Data"}
            // skipTotals={true}
            small={true}
          />
        </Col>
        <Col lg={6}>
          <EmissionsAreaChart
            data={scopeTwoData}
            colorTheme={colorsChart}
            keys={["Location Based", "Market Based"]}
            title={"Invoice Based Electricity Data"}
            small={true}
          />
        </Col>
      </Row>

      {analysisMessage && (
        <div style={{ marginTop: "20px" }}>{analysisMessage}</div>
      )}
    </>
  );
}

export default ScopeTwoCard;
