import React, { useEffect, useState } from "react";
import { getActiveUserData } from "../../helpers/store";
import { Button, Card, Modal, Spinner } from "react-bootstrap";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import { saveAs } from "file-saver";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import { sendSuppliersByEmail } from "../admin/adminApi";
import { fetchCampaignURL } from "../../helpers/suppliersHelper";

const SendSupplierDetailsModal = ({
  show,
  handleClose,
  suppliers,
  businessId,
  businessName,
  reportingType,
  reportingYear,
}) => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  // CSV data for download
  const [resolvedCsvData, setResolvedCsvData] = useState([]);
  const [processedCsvData, setProcessedCsvData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);
  const [invitedBy] = useState(businessId);
  const reportingPeriod = `${
    reportingType === "financial" ? "FY" : "CY"
  }${reportingYear}`;

  const suppliersColumns = [
    {
      accessor: "contactId",
      Header: "Contact ID",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "preferredName",
      Header: "Preferred Name",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "preferredEmail",
      Header: "Preferred Email",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "preferredABN",
      Header: "Preferred ABN",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "supplierId",
      Header: "Connected Supplier ID",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "abn",
      Header: "Supplier ABN",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "name",
      Header: "Supplier Name",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "email",
      Header: "Supplier Email",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "website",
      Header: "Supplier Website",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "status",
      Header: "Status",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
  ];

  // Clean CSV data for download
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingCSV(true);
        const resolvedData = await Promise.all(
          suppliers.map(async (supplier) => {
            const inviteURL = await fetchCampaignURL(
              supplier.supplierId,
              supplier.name,
              reportingPeriod,
              invitedBy
            );
            return {
              "Contact ID": supplier.contactId,
              "Preferred Name": supplier.preferredName,
              "Preferred Email": supplier.preferredEmail,
              "Preferred ABN": supplier.preferredABN,
              "Connected Supplier ID": supplier.supplierId,
              "Supplier ABN": supplier.abn,
              "Supplier Name": supplier.name,
              "Supplier Email": supplier.email,
              "Supplier Website": supplier.website,
              "Supplier Approval Status": supplier.status,
              InviteURL: inviteURL,
            };
          })
        );
        setResolvedCsvData(resolvedData);
        setIsLoadingCSV(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoadingCSV(false); // Make sure to handle loading state even in case of an error
      }
    };

    fetchData();
  }, [suppliers, reportingType, reportingYear]);

  // Process CSV data after resolvedCsvData is updated
  useEffect(() => {
    const processCsvData = async () => {
      setIsLoadingCSV(true);
      if (resolvedCsvData.length > 0) {
        try {
          setIsLoadingCSV(true);
          const processedData = await Promise.all(
            resolvedCsvData.map((row) => {
              return Object.fromEntries(
                Object.entries(row).map(([key, value]) => {
                  if (typeof value === "string") {
                    const escapedValue = value.replace(/"/g, '""');
                    return [
                      key,
                      /["\n,]/.test(escapedValue)
                        ? `"${escapedValue}"`
                        : escapedValue,
                    ];
                  } else {
                    return [key, value];
                  }
                })
              );
            })
          );
          setProcessedCsvData(processedData);
          setIsLoadingCSV(false);
        } catch (error) {
          console.error("Error processing CSV data:", error);
          setIsLoadingCSV(false);
        }
      }
    };

    processCsvData();
  }, [resolvedCsvData]);

  async function handleSendSuppliersByEmail() {
    setIsLoading(true);

    await sendSuppliersByEmail({
      email: activeUser.email,
      suppliers: resolvedCsvData,
      businessName: businessName,
      reportingType: reportingType,
      reportingYear: reportingYear,
    });

    handleClose();
    setIsLoading(false);
  }

  // Custom CSV generation function
  const handleDownloadCsv = () => {
    let csvContent =
      "Contact ID,Preferred Name,Preferred Email,Preferred ABN,Connected Supplier ID,Supplier ABN,Supplier Name,Supplier Email,Supplier Website,Supplier Approval Status,InviteURL"; // Include all column headers"; // Include all column headers

    processedCsvData.forEach((row) => {
      const rowData = Object.values(row).join(",");
      csvContent += `\n${rowData}`;
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(
      blob,
      `${businessName}_${reportingType}_${reportingYear}_suppliers.csv`
    );
  };

  return (
    <>
      {isLoadingCSV && <LoadingModal show={isLoadingCSV} />}

      <Modal show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Suppliers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This table displays the details of the suppliers. You can Initiate
            Supplier Engagement by selecting the desired action below.
          </p>

          <div className="mb-3">
            <AdvanceTableWrapper
              columns={suppliersColumns}
              data={suppliers}
              sortable
              pagination
              perPage={10}
              rowCount={suppliers}
            >
              <Card>
                <Card.Body className="p-0">
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger text-nowrap align-middle"
                    tableProps={{
                      size: "lg",
                      className: "fs--1 mb-0 overflow-hidden",
                    }}
                  />
                </Card.Body>
                <Card.Footer>
                  <AdvanceTableFooter
                    data={suppliers}
                    rowCount={suppliers.length}
                    table
                    rowInfo
                    navButtons
                  />
                </Card.Footer>
              </Card>
            </AdvanceTableWrapper>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* Button to download CSV */}
          <Button
            variant="success"
            disabled={isLoadingCSV}
            onClick={handleDownloadCsv}
          >
            Download CSV
          </Button>

          {/* Button to send CSV by email */}
          <Button
            variant="primary"
            onClick={handleSendSuppliersByEmail}
            disabled={true}
          >
            {isLoading && (
              <>
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                {""}
              </>
            )}
            Send CSV by Email
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const LoadingModal = ({ show }) => {
  return (
    <Modal show={show} centered>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div className="ms-3">
            <p className="mb-0">We are gathering and cleaning your data.</p>
            <p className="mb-0">Please wait...</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SendSupplierDetailsModal;
