import React, { Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import { useAuth } from "./Contexts/AuthContext";
import MainLayout from "./layouts/MainLayout";
import Login from "./components/authentication/card/Login";
import Logout from "./components/authentication/card/Logout";
import Register from "./components/authentication/card/Registration";
import ConfirmMail from "./components/authentication/card/ConfirmMail";
import ForgotPassword from "./components/authentication/card/ForgetPassword";
import BusinessDetailsForm from "./components/BusinessDetailWizard/BusinessDetailsForm";

import NewOverview from "./components/new-overview/newOverview.js";
import Error from "./components/errors/errors";
import PageNotFound from "./components/errors/pageNotFound";
import XeroCallback from "./components/authentication/card/XeroCallback";
import MyobCallback from "./components/authentication/card/MyobCallback";
import { useEffect } from "react";
import { getAuthorizationHeader } from "./helpers/utils";
import { clearLoginData, getWhiteLabel } from "./helpers/store";
import MYOBCompanyList from "./components/authentication/card/MYOBCompanyList";
import AccountsList from "./components/authentication/card/AccountsList";

import TransperancyHub from "./components/TransparencyHub/index";
import { Spinner } from "react-bootstrap";
import AuthenticatedRoutes from "./AuthenticatedRoutes.js";

const LoadingSpinner = () => {
  const spinnerContainerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1050, // High z-index to cover other content
  };

  const spinnerBackdropStyle = {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white backdrop
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const loadingTextStyle = {
    position: "absolute",
    color: "#007bff", // Bootstrap primary color
    fontSize: "1.2rem",
    marginTop: "7rem", // Positioning the text below the spinner
  };

  return (
    <div style={spinnerContainerStyle}>
      <div style={spinnerBackdropStyle}>
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      <p style={loadingTextStyle}>Loading... Please wait.</p>
    </div>
  );
};

const AppRouter = () => {
  const { isUserSignedIn, currentUser } = useAuth();
  const [whitelabel, setWhitelabel] = useState(getWhiteLabel());
  const [isLoading, setIsLoading] = useState(true);
  //console.log(currentUser);

  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.get("inviteeBusinessId") !== null) {
    console.log(queryParams.get("inviteeBusinessId"));
    sessionStorage.setItem("businessId", queryParams.get("inviteeBusinessId"));
    sessionStorage.setItem("xero", "false");
  }
  if (queryParams.get("invitedSupplierId") !== null) {
    console.log("Supplier ID is ", queryParams.get("invitedSupplierId"));
    sessionStorage.setItem("supplierId", queryParams.get("invitedSupplierId"));
    sessionStorage.setItem("xero", "false");
  }

  async function subscribe() {
    //extract subscription ID from url
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("subscriptionId") !== null) {
      const subscriptionId = queryParams.get("subscriptionId");
      //update subscriptionId
      const response = await fetch(
        "/api/xero/xero-update-subscription?subscriptionId=" + subscriptionId,
        {
          headers: getAuthorizationHeader(),
          mode: "cors",
        }
      );
      if (response.status === 401) {
        clearLoginData();
      }
    }
  }

  const getWhitelabel = async () => {
    const hostname = window.location.hostname;
    // const subdomain = hostname.split(".")[0];
    const subdomain = hostname.split(".")[1];
    // console.log("Redirect URI", `${window.location.origin}/xero-callback`);
    // console.log("Subdomain", subdomain);
    if (subdomain === "netnada") {
      // console.log("netnada");
      setDefaultWhitelabel();
    } else {
      try {
        const response = await fetch(`/api/users/get-whitelabel/${subdomain}`, {
          method: "GET",
          // headers: getAuthorizationHeader(),
        });
        if (response.ok) {
          const whitelabelData = await response.json();
          if (whitelabelData) {
            // console.log("set whitelabel", whitelabelData);
            // updateFavicon(whitelabelData.icon);
            sessionStorage.setItem(
              "whiteLabel",
              JSON.stringify(whitelabelData)
            );
            setWhitelabel(whitelabelData);
          } else {
            // console.log("whitelabel not found subdomain");
            // If whitelabelData is null, set default settings
            setDefaultWhitelabel();
          }
        } else {
          // console.log("response not ok");
          setDefaultWhitelabel();
        }
      } catch (error) {
        // console.log("Error");
        setDefaultWhitelabel();
      }
    }

    function setDefaultWhitelabel() {
      const defaultWhitelabel = {
        name: "NetNada",
        domain: "default",
        logo: "https://cdn.filestackcontent.com/jI0Bz5GXRTmfkMONEOJH",
        width: 150,
        icon: "https://firebasestorage.googleapis.com/v0/b/netnada-staging.appspot.com/o/WhiteLabelLogos%2Ffavicon.ico?alt=media&token=52e281e7-abd2-4b46-9fd0-09070320527b",
      };
      // updateFavicon(defaultWhitelabel.icon);
      sessionStorage.setItem("whiteLabel", JSON.stringify(defaultWhitelabel));
      setWhitelabel(defaultWhitelabel);
    }
  };

  const updateFavicon = (faviconURL) => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${faviconURL}?v=${Date.now()}`;
  };

  useEffect(() => {
    const initialize = async () => {
      await subscribe();
      await getWhitelabel();
      setIsLoading(false);
    };
    initialize();
  }, []);

  useEffect(() => {
    updateFavicon(whitelabel.icon);
    document.title = whitelabel.name;
  }, [whitelabel]);
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/xero-sign-up" element={<Xero />} />
        <Route path="/xero-sign-in" element={<Xero />} />
        <Route path="/connect-to-xero" element={<Xero />} />
        <Route path="/myob-sign-up" element={<MYOB />} />
        <Route path="/myob-sign-in" element={<MYOB />} />
        <Route path="/connect-to-MYOB" element={<MYOB />} />
        <Route path="/xero-callback" element={<XeroCallback />} />
        <Route path="/myob-callback" element={<MyobCallback />} />
        <Route exact path="/:subscriptionId" element={<Login />} />
        <Route path="/authentication/basic/logout" element={<Logout />} />
        <Route path="/authentication/card/register" element={<Register />} />
        <Route path="/register/b2buy" element={<Register />} />
        <Route
          path="/authentication/card/forgot-password"
          element={<ForgotPassword />}
        />
        <Route path="/authentication/card/login" element={<Login />} />
        <Route
          path="/authentication/card/confirmEmail/:employeeEngagementFormId?"
          element={<ConfirmMail />}
        />
        <Route path="/authentication/card/error" element={<Error />} />
        {/* Accessible only when a new user */}
        <Route path="/myob-company-list" element={<MYOBCompanyList />} />
        <Route path="/myob-account-list" element={<AccountsList />} />
        <Route path="/xero-account-list" element={<AccountsList />} />
        {isUserSignedIn ? (
          currentUser.hasDefaultOffice ? (
            currentUser.role === "SUPPLIER" ? (
              <Route path="/welcome" element={<MainLayout />}>
                {/* Landing Page */}
                <Route path="/welcome" element={<TransperancyHub />} />
                <Route
                  path="/welcome/*"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthenticatedRoutes currentUser={currentUser} />
                    </Suspense>
                  }
                />
              </Route>
            ) : (
              <Route path="/welcome" element={<BusinessDetailsForm />} />
            )
          ) : (
            <Route path="/welcome" element={<MainLayout />}>
              {/* Landing Page */}
              <Route path="/welcome" element={<NewOverview />} />
              <Route
                path="/welcome/*"
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <AuthenticatedRoutes currentUser={currentUser} />
                  </Suspense>
                }
              />
            </Route>
          )
        ) : (
          <Route path="/welcome/*" element={<Navigate to="/" />} />
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;

function Xero() {
  // 👇️ redirect to external Xero URL
  const redirectUri = `${window.location.origin}/xero-callback`;
  const xeroUrl = encodeURI(
    `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${redirectUri}` +
      `&scope=offline_access openid profile email accounting.settings.read accounting.journals.read accounting.contacts.read accounting.transactions.read&state=imaParam=look-at-me-go`
  );
  // const xeroUrl = encodeURI(
  //   `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}` +
  //     `&scope=offline_access openid profile email accounting.settings.read accounting.journals.read accounting.contacts.read accounting.transactions.read&state=imaParam=look-at-me-go`
  // );
  window.location.replace(xeroUrl);
  return null;
}

function MYOB() {
  const myobUrl = encodeURI(
    `https://secure.myob.com/oauth2/account/authorize?client_id=${process.env.REACT_APP_MYOB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MYOB_REDIRECT_URI}&response_type=code&scope=CompanyFile`
  );
  window.location.replace(myobUrl);
  return null;
}
