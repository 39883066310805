import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Background from "../common/Background";
import Flex from "../common/Flex";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import corner1 from "../../Assets/img/icons/spot-illustrations/corner-2.png";
import PropTypes from "prop-types";
import { getWhiteLabel } from "../../helpers/store";

const WelcomeBanner = ({
  data,
  measurementYear,
  currentInventoryProgress,
  toggleToSetMeasurementYearModal,
  onMeasurementYearSelection,
  isLoadingInventoryProgress,
}) => {
  // Helper function to determine if link is external
  const isExternalLink = (link) => {
    return link.startsWith("http") || link.startsWith("mailto");
  };

  return (
    <Card className="h-100">
      <Background image={corner1} className="rounded-soft bg-card" />
      <Card.Header className="z-index-1">
        <h5 className="text-primary">Welcome to {getWhiteLabel().name}!</h5>
        <h6 className="text-600">Here are some quick links for you to start</h6>
      </Card.Header>
      <Card.Body className="z-index-1">
        <Row className="g-2 h-100 align-items-end">
          {data.map(({ icon, color, title, text, link }) => {
            return (
              <Col sm={6} md={5} key={title}>
                <Flex className="position-relative">
                  <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                    <FontAwesomeIcon icon={icon} className={`text-${color}`} />
                    {/* <FontAwesomeIcon icon={icon} color={color} /> */}
                  </div>
                  <div className="flex-1">
                    {isExternalLink(link) ? (
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="stretched-link"
                      >
                        <h6 className="text-800 mb-0">{title}</h6>
                      </a>
                    ) : (
                      <Link to={link} className="stretched-link">
                        <h6 className="text-800 mb-0">{title}</h6>
                      </Link>
                    )}
                    <p className="mb-0 fs--2 text-500 ">{text}</p>
                  </div>
                </Flex>
              </Col>
            );
          })}
        </Row>
      </Card.Body>
      <Card.Footer className="z-index-1">
        <Row className="align-items-center">
          <Col md={1}>
            <h6 className="mb-0">
              Filter by <span style={{ whiteSpace: "nowrap" }}>reporting</span>{" "}
              year:{" "}
            </h6>
          </Col>
          <Col md={3}>
            <Form.Select
              size="sm"
              value={measurementYear}
              onChange={(event) =>
                onMeasurementYearSelection(event.target.value)
              }
            >
              {currentInventoryProgress?.length > 0 &&
                currentInventoryProgress.map((inventory) => (
                  <option
                    key={inventory.inventoryid}
                    value={
                      inventory.measurementyear +
                      "-" +
                      inventory.measurementyeartype.toLowerCase()
                    }
                  >
                    {inventory.measurementyear}
                    {"("}
                    {inventory.measurementyeartype.toUpperCase()}
                    {")"}
                  </option>
                ))}
            </Form.Select>
            {isLoadingInventoryProgress ? (
              <div
                className="spinner-border spinner-border-sm ms-2"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : null}
          </Col>
          <Col>
            <Button size="sm" onClick={toggleToSetMeasurementYearModal}>
              <FontAwesomeIcon icon={faAdd} /> Add New Reporting Year
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

// WelcomeBanner.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       title: PropTypes.string.isRequired,
//       text: PropTypes.string.isRequired,
//       icon: PropTypes.string.isRequired,
//       color: PropTypes.string.isRequired,
//     })
//   ),
// };

export default WelcomeBanner;
