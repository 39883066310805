import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CloseButton,
  Col,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountUp from "react-countup";
import Background from "./Background";
import {
  faAdd,
  faCircleArrowUp,
  faCircleArrowRight,
  faCircleArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  getActiveUserData,
  getSingleInventoryProgress,
  setPeriodEmployeeInventoryProgress,
  setPeriodRevenueInventoryProgress,
} from "../../helpers/store";
import {
  updateOrganisationEmployeeNumber,
  updateOrganisationRevenue,
} from "../org-details/organisationDetailsAPI";

const EmissionYearStatCard = ({ stat }) => {
  const [modalShow, setModalShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [countEnd, setCountEnd] = useState(stat.amount);
  const [isBlurred, setIsBlurred] = useState(stat.missingAmount);
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [isLoading, setIsLoading] = useState(false);
  const [inputType, setInputType] = useState("number"); // 'number' or 'range'
  const [selectedRange, setSelectedRange] = useState(null);

  const revenueRangeOptions = [
    "0-50K",
    "50K-100K",
    "100K-200K",
    "200K-400K",
    "400K-700K",
    "700K-1M",
    "1M-2M",
    "2M-5M",
    "5M-10M",
    "10M-20M",
    "20M-50M",
    "50M+",
  ];

  // Function to handle the change in the revenue range dropdown
  const handleRangeChange = (e) => {
    setSelectedRange(e.target.value);
    let lowerBound = e.target.value.split("-")[0];
    if (lowerBound === "0") {
      setInputValue("25000");
    } else {
      if (lowerBound.includes("+")) {
        lowerBound = lowerBound.replace("+", "");
      }
      if (lowerBound.includes("K")) {
        lowerBound = lowerBound.replace("K", "") + "000";
      } else if (lowerBound.includes("M")) {
        lowerBound = lowerBound.replace("M", "") + "000000";
      }
      setInputValue(lowerBound);
    }
  };

  useEffect(() => {
    setCountEnd(stat.amount);
    setIsBlurred(stat.missingAmount);
  }, [stat]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    // const inventoryProgress = getInventoryProgressForYear(
    //   +activeUser.selectedMeasurementYear
    // );
    const inventoryProgress = getSingleInventoryProgress(
      +activeUser.inventoryId
    );
    // console.log("Inventory Progress", inventoryProgress);
    let data = {
      inventoryId: inventoryProgress.inventoryid,
    };

    if (inputValue && stat?.totalEmissions) {
      let newValue;
      if (stat.cardType === "revenue") {
        data.revenue = +inputValue;
        const result = await updateOrganisationRevenue(data);
        if (result.success) {
          const resultData = result.data;
          const updatedData = resultData[0];
          // console.log("updatedData", updatedData);

          newValue = (stat.totalEmissions * 1000) / +updatedData.revenue;

          // setYearRevenueInventoryProgress(
          //   +updatedData.measurement_year,
          //   +updatedData.revenue
          // );
          setPeriodRevenueInventoryProgress(
            +updatedData.id,
            +updatedData.revenue
          );
        }
      } else if (stat.cardType === "employee") {
        data.employeeNumber = +inputValue;
        const result = await updateOrganisationEmployeeNumber(data);

        if (result.success) {
          const resultData = result.data;
          const updatedData = resultData[0];
          // console.log("updatedData", updatedData);

          newValue =
            (stat.totalEmissions * 1000) / +updatedData.employee_number;

          // setYearEmployeeInventoryProgress(
          //   +updatedData.measurement_year,
          //   +updatedData.employee_number
          // );
          setPeriodEmployeeInventoryProgress(
            +updatedData.id,
            +updatedData.employee_number
          );
        }
      }
      setIsLoading(false);
      setCountEnd(newValue);
      setIsBlurred(false);
      setModalShow(false);
    }
  };

  const renderModalTriggerButton =
    stat.cardType === "revenue" || stat.cardType === "employee"; // Extend this condition as needed

  const getIconAndColor = (amountLastYear) => {
    if (amountLastYear === null) {
      return { icon: faCircleArrowRight, color: "text-secondary" }; // Assuming 'text-secondary' for unknown/null values
    } else if (amountLastYear > 0) {
      return { icon: faCircleArrowUp, color: "text-danger" };
    } else if (amountLastYear < 0) {
      return { icon: faCircleArrowDown, color: "text-success" };
    } else {
      return { icon: faCircleArrowRight, color: "text-info" };
    }
  };
  const { icon, color } = getIconAndColor(stat.amountLastYear);

  const placeholder =
    stat.cardType === "revenue"
      ? "e.g., 130000 (in AUD)"
      : "Type here the number of employees";

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isSmallScreen = windowWidth < 1500;
  return (
    <>
      <Col xxl={4} md={4} className={`${stat.className} px-3 text-center`}>
        <Card className="pt-3 pb-3" style={{ minHeight: "170px" }}>
          <Background image={stat.image} className="bg-card" />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className={`icon-circle icon-circle-${stat.color}`}>
              <FontAwesomeIcon
                icon={stat.icon}
                className={`fs-2 text-${stat.color}`}
              />
            </div>
            <h5 className="mb-2 font-sans-serif">
              <span className="fw-normal text-600">{stat.title}</span>
            </h5>
            <div
              style={{
                width: "50%",
                height: "2px",
                backgroundColor: "#ddd",
                margin: "0 auto 10px auto",
              }}
            ></div>
            <div style={{ position: "relative" }}>
              <div
                style={
                  isBlurred ? { filter: "blur(7px)", color: "#ccc" } : null
                }
              >
                <h2 className="mb-1 font-sans-serif">
                  <CountUp
                    start={0}
                    end={countEnd}
                    duration={2.75}
                    className="text-800"
                    separator=","
                    decimals={stat.decimals}
                  />
                  <span
                    className="fw-normal text-600"
                    style={{ fontSize: "0.80em" }}
                  >
                    {" " +
                      (isSmallScreen && stat.id === 2 ? "kg CO₂e" : stat.units)}
                  </span>
                </h2>

                {activeUser.showPreviousYear &&
                  stat.amountLastYear !== null && (
                    <div className="col">
                      <span
                        className="badge"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <FontAwesomeIcon icon={icon} className={color} />
                        <span> </span>
                        <span className={color}>
                          {Math.abs(stat.amountLastYear).toFixed(2)}% from
                          Previous Year
                        </span>
                      </span>
                    </div>
                  )}
              </div>
              {isBlurred && renderModalTriggerButton && (
                <Button
                  size="sm"
                  onClick={() => setModalShow(true)}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 10,
                  }}
                >
                  <FontAwesomeIcon icon={faAdd} /> Add Data
                </Button>
              )}
            </div>
          </div>
        </Card>
      </Col>
      {renderModalTriggerButton && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {stat.cardType === "revenue"
                ? `Revenue for ${+activeUser.selectedMeasurementYear} ${
                    activeUser.selectedMeasurementYearType
                      .charAt(0)
                      .toUpperCase() +
                    activeUser.selectedMeasurementYearType.slice(1)
                  } Year`
                : `Number of Employees during ${+activeUser.selectedMeasurementYear} ${
                    activeUser.selectedMeasurementYearType
                      .charAt(0)
                      .toUpperCase() +
                    activeUser.selectedMeasurementYearType.slice(1)
                  } Year`}
            </Modal.Title>
            <CloseButton onClick={() => setModalShow(false)} />
          </Modal.Header>
          <Modal.Body>
            {stat.cardType === "revenue" && (
              <>
                <p>
                  What is your organisation annual gross revenue in AUD for this
                  reporting period?
                </p>

                <Form.Check
                  type="radio"
                  label="Enter amount:"
                  name="inputType"
                  id="exactAmount"
                  checked={inputType === "number"}
                  onChange={() => setInputType("number")}
                />
                <Form.Check
                  type="radio"
                  label="Select range:"
                  name="inputType"
                  id="range"
                  checked={inputType === "range"}
                  onChange={() => setInputType("range")}
                />
              </>
            )}
            {inputType === "number" ? (
              <Form.Group className="mb-3">
                <p>
                  {stat.cardType !== "revenue" &&
                    "How many employees worked at your organisation during this reporting period?"}
                </p>
                <Form.Control
                  type="text"
                  name={stat.cardType}
                  placeholder={inputValue || placeholder}
                  value={inputValue}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            ) : (
              <Form.Select
                aria-label="Select Revenue Range"
                value={selectedRange}
                onChange={handleRangeChange}
              >
                {/* <option>Open this select menu</option> */}
                {revenueRangeOptions.map((range, index) => (
                  <option key={index} value={range}>
                    {range}
                  </option>
                ))}
              </Form.Select>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalShow(false)}>Close</Button>
            <Button onClick={handleSubmit} disabled={!inputValue}>
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Loading...</span>
                </>
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

EmissionYearStatCard.propTypes = {
  stat: PropTypes.shape({
    cardType: PropTypes.string,
    title: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalEmissions: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    missingAmount: PropTypes.bool,
    units: PropTypes.string,
    decimals: PropTypes.number,
    image: PropTypes.string,
    color: PropTypes.string,
    amountLastYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.object,
    className: PropTypes.string,
  }),
};

export default EmissionYearStatCard;
