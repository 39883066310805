import FlatFileUploader from "../../common/FlatFileUploader";
import { flatFileWorkbook } from "./flatFileConfig";
import { flatfileEventListener } from "./flatFilelistener";

const InvoiceFlatFileUploader = ({ metadata }) => (
  <FlatFileUploader
    metadata={metadata}
    config={{
      title: "Data Uploader",
      workbook: flatFileWorkbook,
      listener: flatfileEventListener,
      operation: "expenses:submit",
      redirectUrl: "/welcome/data/all-invoices",
    }}
  />
);

export default InvoiceFlatFileUploader;
