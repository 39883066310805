import React, { useState, useEffect, useContext } from "react";
// Ensure all other necessary imports are here, for example:
import EditSupplierModal from "./EditSupplierModal"; // Adjust the import path as needed
import SoftBadge from "../common/SoftBadge";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  FormControl,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faLocationArrow,
  faEnvelope,
  faGlobe,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

import { updateSupplier } from "../../helpers/suppliersHelper";
import { EmissionsContext } from "./emissions-by-supplier"; // Adjust the import path as necessary

import {
  countryStateDataJSON,
  countryCodeList,
} from "../../data/CountryStateData";
import { getActiveUserData } from "../../helpers/store";

const SupplierIntroduction = ({
  contactData,
  handleCloseCalculationsModal,
  data,
}) => {
  // console.log("contactData", contactData);
  const CONFIDENT_THRESHOLD = 0.94;
  const NEEDS_REVIEW_THRESHOLD = 0.9;

  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [reportingYear] = useState(
    parseInt(activeUser.selectedMeasurementYear)
  );
  const [supplierData] = useState(contactData.supplier);
  const [someContactData] = useState(contactData.contact);
  const [combinedCategoryFactorsObj] = useState(
    data.combinedCategoryFactorsObj || null
  );
  const [primaryCategories] = useState(data.primaryCategories || []);
  const [secondaryCategories, setSecondaryCategories] = useState([]);
  const [secondaryCategoryObject, setSecondaryCategoryObject] = useState(null);

  // console.log("primaryCategory =>", primaryCategory);
  // console.log("secondaryCategory =>", secondaryCategory);

  // const [primaryCategory, setPrimaryCategory] = useState(
  //   data.unifiedData.primaryCategory
  // );

  // console.log("someContactData", someContactData);
  // console.log("supplierData =>", supplierData);

  const supplierName = supplierData?.name || "";
  const supplierWebsite = supplierData?.website || "";
  const supplierMerchantLogo = supplierData?.merchantLogo || "";
  const supplierCountry = supplierData?.country || "";
  const supplierEmail = supplierData?.email || "";
  const supplierPrimaryCategory = supplierData?.primaryCategory || "";
  const supplierSecondaryCategory = supplierData?.secondaryCategory || "";
  const supplierPrimaryEmissionFactor =
    supplierData?.primaryEmissionFactor || 0;
  const supplierSecondaryEmissionFactor =
    supplierData?.secondaryEmissionFactor || 0;
  const contactScore = someContactData?.score || 0;
  const contactTotalEmissions = contactData?.totalEmissions || 0;

  const initialFavicon = supplierWebsite
    ? `${supplierWebsite}/favicon.ico`
    : "";
  const [faviconExists, setFaviconExists] = useState(false);
  const [merchantLogoExists, setMerchantLogoExists] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countryData, setCountryData] = useState(countryCodeList());

  // State to control the visibility of EditSupplierModal
  const [showEditSupplierModal, setShowEditSupplierModal] = useState(false);
  const [secondaryCatSet, setScondaryCatSet] = useState(true);
  const { getUnifiedData, updateChartData, get } = useContext(EmissionsContext);

  const [editableSupplierWebsite, setEditableSupplierWebsite] =
    useState(supplierWebsite);
  const [editableSupplierEmail, setEditableSupplierEmail] =
    useState(supplierEmail);
  const [editableSupplierCountry, setEditableSupplierCountry] =
    useState(supplierCountry);
  const [unifiedDataCalCompleted] = useState(
    JSON.parse(sessionStorage.getItem("unifiedDataCalCompleted"))
  );
  const [primaryCategory, setPrimaryCategory] = useState(
    supplierPrimaryCategory
  );
  const [secondaryCategory, setSecondaryCategory] = useState(
    supplierSecondaryCategory
  );

  const handleFaviconLoad = () => setFaviconExists(true);
  const handleFaviconError = () => setFaviconExists(false);

  const handleMerchantLogoLoad = () => setMerchantLogoExists(true);
  const handleMerchantLogoError = () => setMerchantLogoExists(false);

  // Function to toggle the visibility of EditSupplierModal
  const handleEditSupplierModalToggle = () => {
    setShowEditSupplierModal(!showEditSupplierModal);
  };

  const saveEditedInfo = async () => {
    setIsLoading(true);

    // console.log("Save clicked");

    const updatedSupplier = {
      id: supplierData.id,
      website: editableSupplierWebsite,
      email: editableSupplierEmail,
      country: editableSupplierCountry,
      primaryCategory: primaryCategory,
      secondaryCategory: secondaryCategory,
      primaryEmissionFactor: supplierPrimaryEmissionFactor,
      secondaryEmissionFactor: supplierSecondaryEmissionFactor,
    };

    const result = await updateSupplier(updatedSupplier);

    if (result === true) {
      console.log("Supplier updated successfully");
      // Possibly close the edit modal or notify the user of success here
      setIsChanged(false); // Indicate changes have been successfully saved
    } else if (result === null) {
      console.log(
        "Failed to update supplier - document may not exist or error occurred"
      );
      // Handle the case where the document doesn't exist or other errors occurred
    }

    if (unifiedDataCalCompleted) {
      await updateChartData();
      // console.log("Updated the chart data...");
    }

    await get();

    setIsLoading(false); // Stop loading irrespective of outcome
  };

  const renderSoftBadge = () => {
    if (contactScore >= CONFIDENT_THRESHOLD) {
      return <SoftBadge bg={"success"}>Supplier Found</SoftBadge>;
    } else if (contactScore >= NEEDS_REVIEW_THRESHOLD) {
      return <SoftBadge bg={"warning"}>Verify Supplier</SoftBadge>;
    } else {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <SoftBadge bg="dark">Draft Supplier</SoftBadge>
          <OverlayTrigger
            key="right"
            placement="right"
            overlay={
              <Tooltip id="tooltip-right">
                Supplier wasn't matched to an existing supplier so a new
                supplier was created. Please add relevant information in the
                fields below.
              </Tooltip>
            }
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              style={{ marginLeft: "5px" }}
            />
          </OverlayTrigger>
        </div>
      );
    }
  };

  const toSetSecondaryCategoriesByPrimaryCategory = (primaryCategory) => {
    if (primaryCategory) {
      let categories = combinedCategoryFactorsObj[primaryCategory];

      // console.log("categories=>", categories);
      // console.log("combinedCategoryFactorsObj=>", combinedCategoryFactorsObj);

      // Group by unit
      const groupedByUnit = categories.reduce((acc, item) => {
        // If the unit doesn't exist in the accumulator, add it as a key with an empty array
        if (!acc[item.unit]) {
          acc[item.unit] = [];
        }

        // Push the current item to the corresponding unit array
        acc[item.unit].push(item);
        return acc;
      }, {});
      // console.log("groupedByUnit=>", groupedByUnit);

      let secondaryCategories = [];

      //if the primary category is 'Electricity', then filter only unit 'kwh' for the year nearest to reporting year
      if (primaryCategory === "Electricity") {
        secondaryCategories = Object.keys(groupedByUnit)
          .filter((unit) => unit === "kwh")
          .map((unit) => ({
            groupName: "For input activity in " + unit,
            categories: groupedByUnit[unit],
          }));

        //find the categories whose year is nearest to reporting year
        let categories = secondaryCategories[0].categories;

        //sort the categories by year in descending order
        categories.sort((a, b) => parseInt(b.year) - parseInt(a.year));

        //fetch unique years
        const uniqueYears = [
          ...new Set(categories.map((item) => item.year)),
        ].sort((a, b) => b - a);

        let nearestLowerYear = null;
        //compare the unique years with reporting year and get the nearest lower year
        for (let i = 0; i < uniqueYears.length; i++) {
          if (uniqueYears[i] <= reportingYear) {
            nearestLowerYear = uniqueYears[i];
            break;
          }
        }
        // console.log("nearestLowerYear=>", nearestLowerYear);

        //filter the categories whose year is nearestLowerYear
        categories = categories.filter(
          (item) => item.year === nearestLowerYear
        );

        //update the secondaryCategories with the filtered categories
        secondaryCategories[0].categories = categories;

        // console.log("secondaryCategories=>", secondaryCategories);

        return secondaryCategories;
      }

      //if the primary category is 'Stationary energy (gaseous fuels)', then filter only unit 'GJ'
      if (primaryCategory === "Stationary energy (gaseous fuels)") {
        secondaryCategories = Object.keys(groupedByUnit)
          .filter((unit) => unit === "GJ")
          .map((unit) => ({
            groupName: "For input activity in " + unit,
            categories: groupedByUnit[unit].filter(
              (item) =>
                item.activity_name === "Natural Gas National average (GJ)"
            ),
          }));

        // console.log("secondaryCategories=>", secondaryCategories);

        return secondaryCategories;
      }

      //if others , then filter only unit '$'
      // Transform the grouped object back into an array suitable for the dropdown
      secondaryCategories = Object.keys(groupedByUnit)
        .filter((unit) => unit === "$")
        .map((unit) => ({
          groupName: "For input activity in " + unit,
          categories: groupedByUnit[unit],
        }));

      // console.log("secondaryCategories=>", secondaryCategories);

      return secondaryCategories;
    }
  };

  useEffect(() => {
    if (primaryCategory) {
      const secondaryCategories =
        toSetSecondaryCategoriesByPrimaryCategory(primaryCategory);
      setSecondaryCategories(secondaryCategories);
      // setShowSecondaryCategories(true);
    }
  }, [primaryCategory]);

  const handleCategoryChange = (event) => {
    event.preventDefault();
    setPrimaryCategory(event.target.value);
    const secondaryCategories = toSetSecondaryCategoriesByPrimaryCategory(
      event.target.value
    );

    setSecondaryCategories(secondaryCategories);
    setScondaryCatSet(false);
    setIsChanged(true);
  };

  //   console.log("supplierWebsite", supplierWebsite);
  //   console.log("initialFavicon", initialFavicon);
  return (
    <Container
      style={{
        // background: "#F5F5F5",
        border: "1px solid #E0E0E0",
        borderRadius: "4px",
        padding: "1rem",
        marginBottom: "1rem",
        paddingBottom: "20px",
        width: "80%",
      }}
    >
      <Row>
        <Col
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "50px" }}
        >
          <img
            src={supplierMerchantLogo}
            alt="logo"
            width={100} // Adjusted size
            height={100} // Adjusted size
            onError={handleMerchantLogoError}
            onLoad={handleMerchantLogoLoad}
            style={{
              borderRadius: "50%", // Make the image circular
              display: merchantLogoExists ? "inline" : "none",
            }}
          />
          {/* Fallback for favicon */}
          {!merchantLogoExists &&
            supplierWebsite !== null &&
            supplierWebsite !== "" && (
              <img
                src={initialFavicon}
                alt="favicon"
                width={100} // Adjusted size
                height={100} // Adjusted size
                onError={handleFaviconError}
                onLoad={handleFaviconLoad}
                style={{
                  borderRadius: "50%", // Make the image circular
                  display: faviconExists ? "inline" : "none",
                }}
              />
            )}
          {/* Fallback content when no logo or favicon exists */}
          {!merchantLogoExists && !faviconExists && (
            <div
              style={{
                width: "100px", // Adjusted size
                height: "100px", // Adjusted size
                backgroundColor: "#ff9418",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%", // Make the div circular
                fontSize: "48px", // Bigger font size
              }}
            >
              {supplierName[0]?.toUpperCase()}
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "50px" }}
        >
          <h3 style={{ marginBottom: 0 }} className="px-2">
            {supplierName}
          </h3>
          {renderSoftBadge()}
        </Col>
      </Row>
      <Row style={{ paddingBottom: "10px" }}>
        <Col xs className="text-center">
          <strong> Total Emissions: </strong>
          {contactTotalEmissions.toFixed(2)} tCO₂e
        </Col>
      </Row>

      <Row style={{ textAlign: "center" }}>
        {/* Use a span to wrap the interactive and non-interactive text for inline display */}
        <span>
          {/* Make this a button for accessibility, styled as text */}
          <button
            onClick={handleEditSupplierModalToggle}
            style={{
              background: "none",
              border: "none",
              padding: 0,
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
              marginRight: "5px", // Adjust as needed for spacing
            }}
          >
            Find supplier
          </button>
          {/* Plain text next to the button */}
          {contactScore <= NEEDS_REVIEW_THRESHOLD ? (
            <span>or add more information about this supplier below</span>
          ) : (
            <></>
          )}
        </span>
        {/* Pass the state and toggle function as props to EditSupplierModal */}
        <EditSupplierModal
          showEditSupplierModal={showEditSupplierModal}
          handleEditSupplierModalClose={handleEditSupplierModalToggle}
          contactData={contactData}
          handleCloseCalculationsModal={handleCloseCalculationsModal}
        />
      </Row>

      {contactScore >= NEEDS_REVIEW_THRESHOLD ? (
        <Row
          className="justify-content-md-center"
          style={{ paddingTop: "20px" }}
        >
          {supplierCountry && (
            <Col xs className="text-center">
              <FontAwesomeIcon icon={faLocationArrow} size="2x" />
              <div
                style={{
                  paddingTop: "8px",
                }}
              >
                {supplierCountry}
              </div>
            </Col>
          )}
          {supplierEmail && (
            <Col xs className="text-center">
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
              <div
                style={{
                  paddingTop: "8px",
                }}
              >
                {supplierEmail}
              </div>
            </Col>
          )}
          {supplierWebsite && (
            <Col xs className="text-center">
              <FontAwesomeIcon icon={faGlobe} size="2x" />
              <div
                style={{
                  paddingTop: "8px",
                }}
              >
                <a
                  href={supplierWebsite}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {supplierWebsite}
                </a>
              </div>
            </Col>
          )}
        </Row>
      ) : (
        // If the contact score is less than the NEEDS_REVIEW_THRESHOLD, show the editable fields view
        <>
          <Row
            className="justify-content-md-center"
            style={{ paddingTop: "20px" }}
          >
            <Col xs className="text-center">
              <FontAwesomeIcon icon={faLocationArrow} size="2x" />
              <Form.Select
                value={editableSupplierCountry}
                onChange={(e) => {
                  // console.log("Country selected", e.target.value);
                  setEditableSupplierCountry(e.target.value);
                  setIsChanged(true);
                }}
                required
              >
                <option>Country</option>
                {countryData.map((data, index) => (
                  <option key={index} value={data.name}>
                    {data.name}
                  </option>
                ))}
              </Form.Select>
            </Col>

            <Col xs className="text-center">
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
              <Form.Control
                type="email"
                value={editableSupplierEmail}
                onChange={(e) => {
                  setEditableSupplierEmail(e.target.value);
                  setIsChanged(true);
                }}
                placeholder="Email"
              />
            </Col>
            <Col xs className="text-center">
              <FontAwesomeIcon icon={faGlobe} size="2x" />
              <Form.Control
                type="text"
                value={editableSupplierWebsite}
                onChange={(e) => {
                  setEditableSupplierWebsite(e.target.value);
                  setIsChanged(true);
                }}
                placeholder="Website"
              />
            </Col>
          </Row>
          <Row
            className="justify-content-md-center"
            style={{ paddingTop: "20px" }}
          >
            <Col xs className="text-center">
              <Form.Label>Primary Category</Form.Label>
              <Form.Select
                aria-label="Default select"
                value={primaryCategory}
                onChange={handleCategoryChange}
              >
                <option value="">Please select</option>
                {primaryCategories.sort().map((cat, index) => (
                  <option value={primaryCategories[index]} key={index}>
                    {primaryCategories[index]}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col xs className="text-center">
              <Form.Label>Secondary Category</Form.Label>

              <Form.Select
                aria-label="Default select"
                value={secondaryCategory}
                onChange={(e) => {
                  // Find the secondary category object
                  const secondaryCategoryObj = secondaryCategories
                    .flatMap((group) => group.categories)
                    .find((cat) => cat._id === e.target.value);
                  setSecondaryCategory(e.target.value);
                  setSecondaryCategoryObject(secondaryCategoryObj);
                  setScondaryCatSet(true);
                  setIsChanged(true);
                }}
              >
                <option value="">Please select your factor</option>

                {secondaryCategories.map((group, index) => (
                  <optgroup label={group.groupName} key={index}>
                    {group.categories.map((cat) => (
                      <option key={cat._id} value={cat.activity_name}>
                        {cat.activity_name}
                        {cat.state_code !== "" && (
                          <span>
                            {" - "}({cat.state_code})
                          </span>
                        )}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="text-center">
              {isChanged && (
                <Button
                  onClick={saveEditedInfo}
                  disabled={isLoading || !secondaryCatSet}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Saving...</span>
                    </>
                  ) : (
                    "Save Changes"
                  )}{" "}
                </Button>
              )}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default SupplierIntroduction;
