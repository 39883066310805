import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faComments } from "@fortawesome/free-solid-svg-icons";
import BookMeeting from "../navbar/top/BookMeeting";
import { TransparencyContext } from "../../context/Context";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { getWhiteLabel } from "../../helpers/store";

const Step3TransparencyHub = () => {
  const { hasFiles, isLoading } = useContext(TransparencyContext);
  return (
    <>
      {isLoading ? (
        <>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <h2 className="placeholder-glow">
              <span className="placeholder col-8"></span>
            </h2>
            <p className="placeholder-glow">
              <span className="placeholder col-6"></span>
              <span className="placeholder col-7"></span>
              <span className="placeholder col-5"></span>
              <span className="placeholder col-4"></span>
            </p>
            <p className="placeholder-glow">
              <span className="placeholder col-5"></span>
              <span className="placeholder col-6"></span>
              <span className="placeholder col-8"></span>
            </p>
          </div>
        </>
      ) : (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          {hasFiles ? (
            <>
              <IoCheckmarkDoneCircleOutline
                style={{
                  marginBottom: 20,
                  fontSize: "3em",
                  color: "#00d27a",
                }}
              />

              <h2>Documents Submitted</h2>
              <p>
                We are now in the process of reviewing the documents submitted
                and prefilling the sustainability questionnaire on your behalf.
                If you missed something, you can{" "}
                <strong>go back to the previous step</strong> and upload any
                more documents. In the meantime, if you would like to book a
                meeting to discuss further or share your sustainability strategy
                please use the button below to meet with the{" "}
                {getWhiteLabel().name} team.
              </p>
              <BookMeeting />
            </>
          ) : (
            <>
              <IoCheckmarkDoneCircleOutline
                style={{
                  marginBottom: 20,
                  fontSize: "3em",
                  color: "#00d27a",
                }}
              />
              <p>
                Thanks for verifying your account. Disclosure is far better than
                non-disclosure and we appreciate your transparency.
              </p>
              <p>
                We have everything we need from you for the moment. If things
                change you can also go to the previous screen and upload
                documents.
              </p>
              <p>
                If you wish to start analysing your business and move forward
                with sustainability goals, you can book a meeting with our team
                below.
              </p>
              <BookMeeting />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Step3TransparencyHub;
