import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TransparencyContext } from "../../context/Context";
import {
  getActiveUserData,
  getSingleInventoryProgress,
  setSingleInventoryProgress,
} from "../../helpers/store";
import {
  getBusinessDetails,
  getTransparencyAssessment,
  updateWizardStepInDatabase,
} from "./transparencyHubAPI";
import { first } from "lodash";

const TransparencyHubProvider = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStep, setErrorStep] = useState(null);
  const [activeUser, setActiveUser] = useState(() =>
    JSON.parse(getActiveUserData())
  );
  const [currentInventoryProgress, setCurrentInventoryProgress] = useState(() =>
    getSingleInventoryProgress(activeUser?.inventoryId)
  );
  const [step, setStep] = useState(
    currentInventoryProgress?.transparencyStep || 1
  );
  const [supplierData, setSupplierData] = useState(null);
  const [assessmentData, setAssessmentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState(null);
  const [yearType, setYearType] = useState(null);

  const onLoad = async () => {
    setIsLoading(true);
    let transparencyAssessment = null;

    //getSupplierId
    const businessData = await getBusinessDetails();

    const newSupplierData = {
      primaryCategory: businessData?.primarycategory,
      secondaryCategory: businessData?.secondarycategory,
      name: businessData?.name,
      merchantLogo: businessData?.logourl,
      netnadaId: businessData?.id,
      supplierId: businessData?.supplierid,
    };

    //get TransparencyAssessments
    if (businessData?.supplierid) {
      transparencyAssessment = await getTransparencyAssessment(
        businessData.supplierid
      );
    }
    setAssessmentData(transparencyAssessment);
    setSupplierData(newSupplierData);
    setIsLoading(false);
  };
  useEffect(() => {
    onLoad();
    const yearTypeRaw = activeUser.selectedMeasurementYearType;
    let year = activeUser.selectedMeasurementYear;
    let yearType = yearTypeRaw === "calendar" ? "CY" : "FY";
    if (!year && assessmentData && assessmentData.length > 0) {
      const latestAssessment = assessmentData[assessmentData.length - 1];
      year = latestAssessment.reportingYear;
      yearType = latestAssessment.reportingType === "calendar" ? "CY" : "FY";
    } else if (
      !year &&
      !yearTypeRaw &&
      (!assessmentData || assessmentData.length === 0)
    ) {
      const currentDate = new Date();
      year = currentDate.getFullYear();
      yearType = "CY";
    }
    setYear(year);
    setYearType(yearType);
  }, [activeUser]);

  function checkIfAnyEntryHasFiles(assessmentData) {
    if (!assessmentData) return false;
    return assessmentData.some(
      (assessment) => assessment.files && assessment.files.length > 0
    );
  }
  const hasFiles = checkIfAnyEntryHasFiles(assessmentData);

  function isButtonDisabled(step, maxStep, hasFiles) {
    //Edge case that we do not have inventoryProgress
    //Some users with old token did not create inventoryProgress
    if (!activeUser.inventoryId) {
      if (step === 2 && hasFiles) {
        return false;
      } else if (step === 3) {
        return true;
      } else {
        return false;
      }
    }
    // Specific rules when the current step is 2
    if (step === 2) {
      if (hasFiles) {
        return false;
      } else if (maxStep > step) {
        return false;
      } else {
        return true;
      }
    }
    if (step === maxStep) {
      return true;
    }
    return false;
  }

  const handleNavs = (targetStep) => {
    if (!activeUser.inventoryId) {
      return false;
    }
    if (targetStep > currentInventoryProgress?.transparencyStep) {
      let message = "";

      if (targetStep === 2) {
        message = "You need to start the assessment first.";
      } else if (targetStep === 3) {
        message =
          "Please submit documents or click on 'no documents available' to proceed.";
      } else if (targetStep === 4) {
        if (hasFiles) {
          message = "Please wait for the documents to be reviewed.";
        } else {
          message = "Please book a meeting with our team.";
        }
      } else {
        message = "";
        setErrorStep(null);
        return; // Exit the function to prevent navigation
      }

      // Display the message
      setErrorMessage(message);
      setErrorStep(targetStep);
      return; // Exit the function to prevent navigation
    }

    // Clear any previous error messages if we're navigating successfully
    setErrorMessage("");
    setErrorStep(null);

    if (targetStep < step) {
      setStep(targetStep);
    } else if (step !== 4 || targetStep < step) {
      setStep(targetStep);
    }

    // if (targetStep > currentInventoryProgress.transparencyStep) {
    //   updateWizardStepInDatabase(activeUser.inventoryId, targetStep);
    //   let newInventoryProgress = {
    //     ...currentInventoryProgress,
    //     transparencyStep: targetStep,
    //   };
    //   setSingleInventoryProgress(activeUser.inventoryId, newInventoryProgress);
    //   setCurrentInventoryProgress(newInventoryProgress);
    // }
  };

  const nextStep = () => {
    const newStep = step + 1;
    setStep((prevStep) => prevStep + 1);
    if (activeUser.inventoryId) {
      if (newStep > currentInventoryProgress?.transparencyStep) {
        updateWizardStepInDatabase(activeUser.inventoryId, newStep);
        let newInventoryProgress = {
          ...currentInventoryProgress,
          transparencyStep: newStep,
        };
        setSingleInventoryProgress(
          activeUser.inventoryId,
          newInventoryProgress
        );
        setCurrentInventoryProgress(newInventoryProgress);
      }
    }
  };

  const prevStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const value = {
    step,
    maxStep: currentInventoryProgress?.transparencyStep || 1,
    setStep,
    nextStep,
    prevStep,
    handleNavs,
    errorMessage,
    errorStep,
    assessmentData,
    supplierData,
    isLoading,
    hasFiles,
    isButtonDisabled: isButtonDisabled,
    refetchData: onLoad,
    year,
    yearType,
  };

  return (
    <TransparencyContext.Provider value={value}>
      {children}
    </TransparencyContext.Provider>
  );
};

TransparencyHubProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TransparencyHubProvider;
