import React, { useContext } from "react";
import { WasteContext } from "../../../context/Context";
import { Button, Container, Row, Col } from "react-bootstrap";

function PageWasteQuestion() {
  const { setKey, nextStep } = useContext(WasteContext);

  const handleYes = () => {
    setKey("tonnesPage");
    nextStep();
  };

  const handleNo = () => {
    setKey("calculatorPage");
    nextStep();
  };

  return (
    <Container className="text-center mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <h3>Do you have data for waste during the selected time period?</h3>
          <p>
            For example, your waste contractor, office manager, or building
            manager has shared this information with you.
          </p>
          <Button variant="outline-primary" className="m-3" onClick={handleYes}>
            Yes, I have access to waste data
          </Button>
          <Button
            variant="outline-secondary"
            className="m-3"
            onClick={handleNo}
          >
            No, help me estimate my waste
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default PageWasteQuestion;
