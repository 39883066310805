import React, { useEffect, useState } from "react";
import { Card, Button, Badge, Row, Col } from "react-bootstrap";
import xeroLogo from "../../Assets/img/icons/xero.png";
import myobLogo from "../../Assets/img/icons/myob.png";
import PageHeader from "../common/PageHeader";
import SoftBadge from "../common/SoftBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getActiveUserData } from "../../helpers/store";
import app from "../../firebase";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { updateBusinessProcStatusAuthProvider } from "../new-overview/newOverviewAPI";
const fireStoreDB = getFirestore(app);

const IntegrationCard = ({
  logo,
  title,
  description,
  learnMore,
  enabled,
  link,
  missingPermission,
  lastUpdated,
  reconnectionAllowed,
}) => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));

  async function handleClick(url) {
    const businessId = activeUser?.businessId;
    const authProvider = title;
    const processingStatus = null;
    await updateBusinessProcStatusAuthProvider({
      businessId,
      processingStatus,
      authProvider,
    });
    window.location = url;
  }
  return (
    <Card style={{ width: "20rem", margin: "10px" }}>
      <Card.Header>
        <Card.Title>
          <img
            src={logo.object}
            className="me-2"
            style={{ width: logo.width, height: logo.height }}
            alt={logo.alt}
          />
          {title}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          {description}{" "}
          {learnMore && (
            <a href={learnMore.url} target="_blank" rel="noopener noreferrer">
              {learnMore.label} <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          )}
        </Card.Text>

        {enabled && (
          <>
            <Row>
              <Col>
                <SoftBadge bg="success" className="me-1 py-2" key="connected">
                  Connected
                </SoftBadge>
              </Col>
              <Col></Col>
            </Row>
            <br />
          </>
        )}
        {lastUpdated && (
          <Row>
            <Col>Last updated:</Col>
            <Col>{lastUpdated}</Col>
          </Row>
        )}
      </Card.Body>
      <Card.Footer>
        <Row>
          {!enabled && link && (
            <>
              <Col></Col>
              <Col>
                <Button as={Link} variant="link" to={link.url}>
                  {link.label}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="ms-1 fs--2"
                  />
                </Button>
              </Col>
            </>
          )}
          {reconnectionAllowed && (
            <Col style={{ display: "flex", justifyContent: "right" }}>
              <Button
                as={Link}
                variant="link"
                onClick={() => handleClick(link.url)}
              >
                Reconnect and update data
                <FontAwesomeIcon icon={faChevronRight} className="ms-1 fs--2" />
              </Button>
            </Col>
          )}

          {missingPermission && (
            <div className="mt-2">
              <Badge variant="danger">Required permissions missing</Badge>
            </div>
          )}
        </Row>
      </Card.Footer>
    </Card>
  );
};

const Index = () => {
  const [data, setData] = useState([
    {
      title: "Xero",
      description: "Pulls a summary of transaction from Xero.",
      enabled: false,
      link: { url: "/xero-sign-in", label: "Connect" },
      lastUpdated: "",
      reconnectionAllowed: false,
      logo: { object: xeroLogo, alt: "Xero", width: "30px", height: "30px" },
      missingPermission: false,
      learnMore: {
        url: "https://www.netnada.com.au/getting-started-with-xero",
        label: "Learn more",
      },
    },
    {
      title: "MYOB",
      description: "Pulls a summary of transaction from MYOB.",
      enabled: false,
      link: { url: "/myob-sign-in", label: "Connect" },
      lastUpdated: "",
      reconnectionAllowed: false,
      logo: { object: myobLogo, alt: "MYOB", width: "45px", height: "30px" },
      missingPermission: false,
      learnMore: {
        url: "https://www.netnada.com.au/getting-started-with-xero",
        label: "Learn more",
      },
    },
  ]);

  const [activeUser] = useState(JSON.parse(getActiveUserData()));

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = "" + d.getFullYear();

    return [day, month, year].join(".");
  }

  async function getLastUpdatedDate(businessId, authProvider) {
    // Reference to the document containing XeroLastUpdated
    const docRef = doc(fireStoreDB, "Journals", businessId);
    // Fetch the document
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let data = docSnap.data();

      let lastUpdated = data[`${authProvider}LastUpdated`] || "";

      if (lastUpdated) {
        //convert timestamp to date as 14.04.2021
        lastUpdated = formatDate(lastUpdated);
      }
      return lastUpdated;
    }
  }

  useEffect(() => {
    const authProvider = activeUser?.authProvider;
    const processingStatus = activeUser?.processingStatus;
    getLastUpdatedDate(activeUser?.businessId, authProvider).then(
      (lastUpdated) => {
        if (
          authProvider === "Xero" &&
          (processingStatus === "Completed" || "InProgress")
        ) {
          setData((prevData) => {
            return prevData.map((item) => {
              if (item.title === "Xero") {
                if (processingStatus === "InProgress") {
                  return {
                    ...item,
                    enabled: true,
                    lastUpdated: lastUpdated,
                    reconnectionAllowed: false,
                  };
                } else {
                  if (processingStatus === "Completed") {
                    return {
                      ...item,
                      enabled: true,
                      lastUpdated: lastUpdated,
                      reconnectionAllowed: true,
                    };
                  } else {
                    return {
                      ...item,
                      enabled: false,
                      lastUpdated: lastUpdated,
                      reconnectionAllowed: false,
                    };
                  }
                }
              }
              return item;
            });
          });
        }

        if (
          authProvider === "MYOB" &&
          (processingStatus === "Completed" || "InProgress")
        ) {
          setData((prevData) => {
            return prevData.map((item) => {
              if (item.title === "MYOB") {
                if (processingStatus === "InProgress") {
                  return {
                    ...item,
                    enabled: true,
                    lastUpdated: lastUpdated,
                    reconnectionAllowed: false,
                  };
                } else {
                  return {
                    ...item,
                    enabled: false,
                    lastUpdated: lastUpdated,
                    reconnectionAllowed: false,
                  };
                }
              }
              return item;
            });
          });
        }
      }
    );
  }, [activeUser]);

  return (
    <>
      <PageHeader
        title="Integrations"
        description="Review your integrations and enable new connections to streamline data collection for your sustainability analysis."
        link={{
          to: "https://calendly.com/netnada/customer-support",
          label: "Missing an integration? Let us know",
        }}
      ></PageHeader>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {data.map((cardData, index) => (
          <IntegrationCard key={index} {...cardData} />
        ))}
      </div>
    </>
  );
};

export default Index;
