import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, Outlet } from "react-router-dom";
import NavbarTop from "../components/navbar/top/NavbarTop";
import NavbarVertical from "../components/navbar/vertical/NavbarVertical";
import AppContext from "../context/Context";
import MainRoutes from "./MainRoutes";
import Footer from "../components/footer/Footer";
import classNames from "classnames";
import SolutionProvider from "../components/solutions/browse-all/SolutionProvider";
import { useAuth } from "../Contexts/AuthContext";
import { setIdToken } from "../helpers/store";
import { auth } from "../firebase";
import { Button, Modal } from "react-bootstrap";

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes("kanban");
  // const isChat = pathname.includes('chat');

  const {
    config: { isFluid, navbarPosition },
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //refresh idToken on page load
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
  const [tokenCheckIntervalId, setTokenCheckIntervalId] = useState(null);

  // Function to check token expiration and refresh it if needed
  const checkTokenExpiration = () => {
    console.log("Checking token expiration");
    if (auth.currentUser) {
      // console.log("User is signed in");
      auth.currentUser
        .getIdTokenResult()
        .then((tokenResult) => {
          if (tokenResult && tokenResult.expirationTime) {
            // console.log("tokenResult", tokenResult);
            const nowInSeconds = Math.floor(Date.now() / 1000);
            // console.log("nowInSeconds", nowInSeconds);
            const tokenExpirationTimeInSeconds = Math.floor(
              new Date(tokenResult.expirationTime).getTime() / 1000
            );

            console.log(
              `Token expires in ${
                tokenExpirationTimeInSeconds - nowInSeconds
              } seconds`
            );
            if (tokenExpirationTimeInSeconds - nowInSeconds < 600) {
              // Refresh the ID token if it's about to expire within the next 10 minutes ( 600 seconds)
              console.log("ID token is about to expire...");
              setShowSessionExpiredModal(true);
            }
          } else {
            console.error(
              "Error: tokenResult or tokenResult.expirationTime is undefined."
            );
          }
        })
        .catch((error) => {
          console.error("Error checking token expiration:", error);
        });
    } else {
      console.log("User is not signed in");
    }
  };

  // Function to set up the interval for token expiration check
  const setupTokenCheckInterval = () => {
    console.log("Setting up interval to check token expiration");
    const tokenCheckInterval = setInterval(() => {
      checkTokenExpiration();
    }, 600000); // Check every 10 minutes (60000 milliseconds)
    setTokenCheckIntervalId(tokenCheckInterval); // Store the interval ID in state
  };

  // Set up a setInterval to check the token expiration periodically
  useEffect(() => {
    // Call the function to set up the initial interval when the component mounts
    setupTokenCheckInterval();

    // Clean up the interval when the component unmounts
    return () => {
      console.log("Clearing interval to check token expiration");
      clearInterval(tokenCheckIntervalId);
    };
  }, [auth.currentUser]);

  return (
    <div className={isFluid ? "container-fluid" : "container-fluid"}>
      {(navbarPosition === "vertical" || navbarPosition === "combo") && (
        <NavbarVertical />
      )}
      <SolutionProvider>
        <div className={classNames("content", { "pb-0": isKanban })}>
          {showSessionExpiredModal && (
            <SessionExpiredModal
              showSessionExpiredModal={showSessionExpiredModal}
              setShowSessionExpiredModal={setShowSessionExpiredModal}
              setupTokenCheckInterval={setupTokenCheckInterval}
            />
          )}
          <NavbarTop />
          <Outlet />
        </div>
      </SolutionProvider>
    </div>
  );
};

export default MainLayout;

const SessionExpiredModal = ({
  showSessionExpiredModal,
  setShowSessionExpiredModal,
  setupTokenCheckInterval,
}) => {
  console.log("SessionExpiredModal", showSessionExpiredModal);
  const { logout } = useAuth();
  // Function to refresh the ID token
  const refreshIdToken = () => {
    console.log("Refreshing ID token");
    auth.currentUser
      .getIdToken(/* forceRefresh */ true)
      .then((token) => {
        // Store the ID token in the session storage
        console.log("ID token has been refreshed");
        setIdToken(token);
        // Call checkTokenExpiration to restart the process of checking token expiration
        setupTokenCheckInterval();
      })
      .catch((error) => {
        // Handle error (e.g., user is signed out)
        console.error("Error refreshing ID token:", error);
      });
  };

  const handleClose = () => {
    setShowSessionExpiredModal(false);
  };

  const handleExtendSession = () => {
    refreshIdToken();
    setShowSessionExpiredModal(false);
  };

  async function handleLogout() {
    try {
      await logout().then(() => {
        sessionStorage.clear();
        //to preserve 'skipProjectTour' value in localStorage
        const keyToPreserve = "skipProjectTour";

        // Iterate through localStorage keys
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);

          // Check if the key is the one to preserve
          if (key !== keyToPreserve) {
            localStorage.removeItem(key);
          }
        }
        window.location.href = "/";
      });
    } catch {
      console.log("Failed to log out");
    }
  }

  return (
    <Modal centered show={showSessionExpiredModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Session Expired</Modal.Title>
      </Modal.Header>
      <Modal.Body>Would you like to extend your session?</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleExtendSession();
          }}
        >
          OK
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            handleLogout();
          }}
        >
          Log Out
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
