import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  countryStateDataJSON,
  countryCodeList,
  CountryNameCodeObject,
} from "../../data/CountryStateData";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import { getUserBusinessLocations } from "../new-overview/newOverviewAPI";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../common/IconButton";
import { useAuth } from "../../Contexts/AuthContext";
import { UpdateLocation } from "../BusinessDetailWizard/BusinessDetailsAPI";

const RecentLocations = () => {
  const { currentUser } = useAuth();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editLocationData, setEditLocationData] = useState(null);

  const getLocations = async () => {
    try {
      const response = await getUserBusinessLocations();
      setData(response);
      setIsLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  function handleEditOpen(locationData) {
    //console.log("locationData=>", locationData);
    setEditLocationData(locationData);
    setShowEditModal(true);
  }

  async function handleEditClose(isRefresh) {
    setShowEditModal(false);
    if (isRefresh) {
      await getLocations();
    }
  }

  const columns = [
    {
      accessor: "",
      Header: "Actions",
      headerProps: { className: "text-center" },
      cellProps: {
        className: "fs-0 text-center",
      },
      Cell: (rowData) => {
        return (
          <div>
            <IconButton
              variant="falcon-default"
              className="p-0 me-2"
              icon={faEdit}
              onClick={() => handleEditOpen(rowData.row.original)}
            ></IconButton>
          </div>
        );
      },
    },
    {
      accessor: "officename",
      Header: "Location Name",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "country",
      Header: "Country",
    },
    {
      accessor: "location",
      Header: "State",
    },
    {
      accessor: "street",
      Header: "Street Address",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "city",
      Header: "City",
    },
    {
      accessor: "postcode",
      Header: "Postcode",
    },
  ];

  return (
    <>
      {showEditModal && (
        <EditLocationModal
          showEditModal={showEditModal}
          handleEditClose={handleEditClose}
          data={{
            businessId: currentUser.businessId,
            locationData: { ...editLocationData },
          }}
        />
      )}

      {isLoading ? (
        <div className="mb-3">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          sortable
          pagination
          perPage={7}
          rowCount={data.length}
        >
          <Card>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "sm",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={data.length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      )}
    </>
  );
};

const EditLocationModal = ({ showEditModal, handleEditClose, data }) => {
  const [countryData] = useState(countryCodeList());
  const [stateData, setStateData] = useState([]);
  const [showState, setShowState] = useState(false);
  const [countryStateJSON] = useState(countryStateDataJSON());
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState({
    locationName: "",
    country: "",
    countryCode: "",
    state: "",
    stateCode: "",
    streetAddress: "",
    city: "",
    postcode: "",
  });

  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.name === "locationName") {
      if (
        event?.nativeEvent?.data !== "," &&
        !event?.target?.value?.includes(",")
      ) {
        setValues((values) => ({
          ...values,
          [event.target.name]: event.target.value,
        }));
      }
    } else if (event.target.name === "country") {
      //console.log("country value", event.target.value.split(",").at(-1));
      setStateData(countryStateJSON[event.target.value.split(",")[0]]);
      setShowState(true);

      setValues((values) => ({
        ...values,
        ["countryCode"]: event.target.value.split(",").at(-1),
      }));
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value.split(",")[0],
      }));
    } else if (event.target.name === "state") {
      //console.log("state value", event.target.value.split(",").at(-1));

      setValues((values) => ({
        ...values,
        ["stateCode"]: event.target.value.split(",").at(-1),
      }));
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value.split(",")[0],
      }));
    } else {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    if (event) event.preventDefault();

    console.log("final value", values);

    //store updated values in db by office_id
    const body = {
      ...values,
      officeId: data.locationData.office_id,
      locationName: values.locationName || data.locationData.officename,
    };

    //update location details
    const response = await UpdateLocation(body);

    if (response.status === 200) {
      handleEditClose(true);
      toast.success("Successfully updated location details");
    } else {
      toast.error("Failed to update location details");
    }
  };

  useEffect(() => {
    setValues({
      locationName: data.locationData.officename,
      country: data.locationData.country,
      countryCode: CountryNameCodeObject()[data.locationData.country],
      state: data.locationData.location,
      stateCode: countryStateJSON[data.locationData.country].find(
        (o) => o.name === data.locationData.location
      ).code,
      streetAddress: data.locationData.street,
      city: data.locationData.city,
      postcode: data.locationData.postcode,
    });
    if (data.locationData.country) {
      setStateData(countryStateJSON[data.locationData.country]);
      setShowState(true);
    }
  }, [data.locationData]);

  return (
    <div>
      {" "}
      <Modal
        show={showEditModal}
        onHide={handleEditClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {/* Edit Unified data category - <i>{data.unifiedData.id}</i> */}
              Edit Location Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col sm>
              <FloatingLabel
                controlId="floatingInput"
                label="Location Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="locationName"
                  placeholder="Location Name"
                  value={values.locationName}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingSelect"
                label="Country"
                className="mb-3"
              >
                <Form.Select
                  name="country"
                  value={values.country + "," + values.countryCode}
                  onChange={handleChange}
                  required
                >
                  <option>Select the country</option>
                  {countryData.map((data, index) => {
                    return (
                      <option key={index} value={data.name + "," + data.code}>
                        {data.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>

              {showState ? (
                <FloatingLabel
                  controlId="floatingSelect"
                  label="State"
                  className="mb-3"
                >
                  <Form.Select
                    name="state"
                    onChange={handleChange}
                    value={values.state + "," + values.stateCode}
                    required
                  >
                    <option>Select the state</option>
                    {stateData.map((data, index) => {
                      return (
                        <option key={index} value={data.name + "," + data.code}>
                          {data.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              ) : null}

              <FloatingLabel
                controlId="floatingInput"
                label="Street address"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="streetAddress"
                  placeholder="Street address"
                  value={values.streetAddress}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label="City"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="City"
                  value={values.city}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingPassword"
                label="Postcode"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  name="postcode"
                  placeholder="Postcode"
                  value={values.postcode}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            {isLoading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ color: "#f5803e" }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <Button
                variant="primary"
                className="d-block w-100"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={
                  !values.city ||
                  !values.postcode ||
                  !values.locationName ||
                  !values.country ||
                  !values.state ||
                  !values.streetAddress
                }
              >
                Save Edit
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default RecentLocations;
