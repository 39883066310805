import React, { useContext, useState, createContext } from "react";
import { auth, anonymousAuth } from "../firebase";
import {
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

import {
  isLoginDataExists,
  getActiveUserData,
  clearLoginData,
} from "../helpers/store";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(getActiveUserData()) || null
  );
  const [isUserSignedIn, setIsUserSignedIn] = useState(
    isLoginDataExists() || false
  );
  const [loading, setLoading] = useState(false);

  // function signup(name, email, password, number, givenName, familyName) {
  //   console.log(typeof (number));
  //   // const user = auth.createUserWithEmailAndPassword(email, password).then(() => {
  //   //   updateProfile(auth.currentUser, { displayName: name, phoneNumber: number })
  //   // }).then((userCredential) => {
  //   //   // send verification mail.
  //   //   auth.currentUser.sendEmailVerification();
  //   //   //auth.signOut();
  //   //   //alert("Email sent");
  //   // }).then(() => { const { uid, email, phoneNumber } = auth.currentUser; registration(uid, email, givenName, familyName, number, name) }).catch(err => { console.log(err); return err })

  //   const user = auth.createUserWithEmailAndPassword(email, password).catch(err => { console.log(err); return err })

  //   return user;
  // }

  async function signup(email, password) {
    const user = await auth
      .createUserWithEmailAndPassword(email, password)
      .catch((err) => {
        console.log(err);
        return err;
      });

    const idToken = await auth.currentUser.getIdToken();
    return { user, idToken };
  }

  // Function to sign in anonymously using the secondary app
  async function signupAnonymously() {
    try {
      const userCredential = await anonymousAuth.signInAnonymously();

      // After fetching the UID, sign out from the secondary app
      await anonymousAuth.signOut();

      return { user: userCredential.user };
    } catch (error) {
      console.error("Error signing in anonymously:", error);
      return { error };
    }
  }

  async function login(email, password) {
    const result = await auth
      .signInWithEmailAndPassword(email, password)
      .then(async (user) => {
        const idToken = await auth.currentUser.getIdToken();
        return { user: user.user, idToken };
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return result;
  }

  async function loginWithGoogle() {
    await setPersistence(auth, browserLocalPersistence);
    const provider = new GoogleAuthProvider();
    const response = await signInWithPopup(auth, provider)
      .then(async (result) => {
        const idToken = await auth.currentUser.getIdToken();
        return { user: result.user, idToken };
      })
      .catch((error) => {
        console.log("loginWithGoogle", error);
      });
    return response;
  }

  async function logout() {
    await auth.signOut();
    clearLoginData();
    // removeIdToken();
    // removeUserData();
    // window.location.href = "/";
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function updateUserProfile(displayName, phoneNumber) {
    const user = updateProfile(auth.currentUser, { displayName, phoneNumber });
    return user;
  }

  function sendEmailVerificationMail() {
    return auth.currentUser.sendEmailVerification();
  }

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged((user) => {
  //     console.log("onAuthStateChanged");
  //     if (user) {
  //       setIsUserSignedIn(true);
  //       setCurrentUser(user);
  //     } else {
  //       setIsUserSignedIn(false);
  //     }

  //     setLoading(false);
  //   });

  //   return unsubscribe;
  // }, []);

  const value = {
    setCurrentUser,
    currentUser,
    setIsUserSignedIn,
    isUserSignedIn,
    login,
    loginWithGoogle,
    signup,
    signupAnonymously,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    updateUserProfile,
    sendEmailVerificationMail,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
