import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import Main from "./Main";
import "bootstrap/dist/css/bootstrap.min.css";

// ReactDOM.render(
//   <Auth0Provider
//     domain="dev-l8hdwgiz.us.auth0.com"
//     clientId="FwJWZlXAqI8IOSqmQXM4dB6tWdbzwFet"
//     redirectUri={window.location.origin}
//   >
//     <App />
//   </Auth0Provider>,
//   document.getElementById('root')
// );
ReactDOM.render(
  <React.StrictMode>
    <Main>
      <App />
    </Main>
  </React.StrictMode>,
  document.getElementById("root")
);
