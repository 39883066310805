import React, { useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCircle } from "@fortawesome/free-solid-svg-icons";
import UploadDataModal from "./UploadDataModal";
import { useNavigate } from "react-router-dom";
import { isAllDataZero } from "./chartHelpers";

// const data = [
//   {
//     month: "Jan",
//     consumption: 1200,
//     location_emissions: 0.6,
//     market_emissions: 0.5,
//     cost: 150,
//     sources: [
//       { name: "Xero", kWh: 300, cost: 45, kgCO2ePerDollar: 6.67 },
//       { name: "MYOB", kWh: 250, cost: 37.5, kgCO2ePerDollar: 6.67 },
//       { name: "Invoices", kWh: 400, cost: 60, kgCO2ePerDollar: 6.67 },
//       { name: "CSV", kWh: null, cost: 37.5, kgCO2ePerDollar: null },
//     ],
//   },
//   {
//     month: "Feb",
//     consumption: 1100,
//     location_emissions: 0.55,
//     market_emissions: 0.45,
//     cost: 140,
//     sources: [
//       { name: "Xero", kWh: null, cost: 40, kgCO2ePerDollar: null },
//       { name: "MYOB", kWh: 250, cost: 35, kgCO2ePerDollar: 7.14 },
//       { name: "Invoices", kWh: 300, cost: 45, kgCO2ePerDollar: 6.67 },
//     ],
//   },
//   {
//     month: "Mar",
//     consumption: 1300,
//     location_emissions: 0.65,
//     market_emissions: 0.55,
//     cost: 160,
//     sources: [
//       { name: "Xero", kWh: 350, cost: 50, kgCO2ePerDollar: 7.0 },
//       { name: "MYOB", kWh: 200, cost: 30, kgCO2ePerDollar: 6.67 },
//       { name: "Invoices", kWh: 450, cost: 65, kgCO2ePerDollar: 6.92 },
//     ],
//   },
//   {
//     month: "Apr",
//     consumption: 1250,
//     location_emissions: 0.62,
//     market_emissions: 0.52,
//     cost: 155,
//     sources: [
//       { name: "Xero", kWh: 300, cost: 45, kgCO2ePerDollar: 6.67 },
//       { name: "MYOB", kWh: null, cost: 37.5, kgCO2ePerDollar: null },
//       { name: "Invoices", kWh: 400, cost: 60, kgCO2ePerDollar: 6.67 },
//       { name: "CSV", kWh: 250, cost: 37.5, kgCO2ePerDollar: 6.67 },
//     ],
//   },
//   {
//     month: "May",
//     consumption: 1350,
//     location_emissions: 0.68,
//     market_emissions: 0.58,
//     cost: 165,
//     sources: [
//       { name: "Xero", kWh: 350, cost: 50, kgCO2ePerDollar: 7.0 },
//       { name: "MYOB", kWh: 300, cost: 45, kgCO2ePerDollar: 6.67 },
//       { name: "Invoices", kWh: null, cost: 60, kgCO2ePerDollar: null },
//     ],
//   },
//   {
//     month: "Jun",
//     consumption: 1400,
//     location_emissions: 0.7,
//     market_emissions: 0.6,
//     cost: 170,
//     sources: [
//       { name: "Xero", kWh: null, cost: 55, kgCO2ePerDollar: null },
//       { name: "MYOB", kWh: 400, cost: 60, kgCO2ePerDollar: 6.67 },
//       { name: "Invoices", kWh: 500, cost: 75, kgCO2ePerDollar: 6.67 },
//       { name: "CSV", kWh: 300, cost: 45, kgCO2ePerDollar: 6.67 },
//     ],
//   },
//   {
//     month: "Jul",
//     consumption: 1450,
//     location_emissions: 0.72,
//     market_emissions: 0.62,
//     cost: 175,
//     sources: [
//       { name: "Xero", kWh: 350, cost: 50, kgCO2ePerDollar: 7.0 },
//       { name: "MYOB", kWh: 250, cost: 37.5, kgCO2ePerDollar: 6.67 },
//       { name: "Invoices", kWh: 450, cost: 65, kgCO2ePerDollar: 6.92 },
//       { name: "CSV", kWh: null, cost: 37.5, kgCO2ePerDollar: null },
//     ],
//   },
//   {
//     month: "Aug",
//     consumption: 1500,
//     location_emissions: 0.75,
//     market_emissions: 0.65,
//     cost: 180,
//     sources: [
//       { name: "Xero", kWh: 400, cost: 60, kgCO2ePerDollar: 6.67 },
//       { name: "MYOB", kWh: 300, cost: 45, kgCO2ePerDollar: 6.67 },
//       { name: "Invoices", kWh: 500, cost: 75, kgCO2ePerDollar: 6.67 },
//     ],
//   },
//   {
//     month: "Sep",
//     consumption: 1350,
//     location_emissions: 0.68,
//     market_emissions: 0.58,
//     cost: 165,
//     sources: [
//       { name: "Xero", kWh: 350, cost: 50, kgCO2ePerDollar: 7.0 },
//       { name: "MYOB", kWh: 200, cost: 30, kgCO2ePerDollar: 6.67 },
//       { name: "Invoices", kWh: 450, cost: 65, kgCO2ePerDollar: 6.92 },
//       { name: "CSV", kWh: 300, cost: 45, kgCO2ePerDollar: 6.67 },
//     ],
//   },
//   {
//     month: "Oct",
//     consumption: 1300,
//     location_emissions: 0.65,
//     market_emissions: 0.55,
//     cost: 160,
//     sources: [
//       { name: "Xero", kWh: 300, cost: 45, kgCO2ePerDollar: 6.67 },
//       { name: "MYOB", kWh: null, cost: 37.5, kgCO2ePerDollar: null },
//       { name: "Invoices", kWh: 400, cost: 60, kgCO2ePerDollar: 6.67 },
//       { name: "CSV", kWh: 250, cost: 37.5, kgCO2ePerDollar: 6.67 },
//     ],
//   },
//   {
//     month: "Nov",
//     consumption: 1200,
//     location_emissions: 0.6,
//     market_emissions: 0.5,
//     cost: 150,
//     sources: [
//       { name: "Xero", kWh: 300, cost: 45, kgCO2ePerDollar: 6.67 },
//       { name: "MYOB", kWh: 250, cost: 37.5, kgCO2ePerDollar: 6.67 },
//       { name: "Invoices", kWh: 400, cost: 60, kgCO2ePerDollar: 6.67 },
//       { name: "CSV", kWh: null, cost: 37.5, kgCO2ePerDollar: null },
//     ],
//   },
//   {
//     month: "Dec",
//     consumption: 1250,
//     location_emissions: 0.62,
//     market_emissions: 0.52,
//     cost: 155,
//     sources: [
//       { name: "Xero", kWh: null, cost: 45, kgCO2ePerDollar: null },
//       { name: "MYOB", kWh: 250, cost: 37.5, kgCO2ePerDollar: 6.67 },
//       { name: "Invoices", kWh: 400, cost: 60, kgCO2ePerDollar: 6.67 },
//       { name: "CSV", kWh: 250, cost: 37.5, kgCO2ePerDollar: 6.67 },
//     ],
//   },
// ];

const EmissionsAreaChart = ({
  data,
  colorTheme,
  keys,
  title,
  small,
  skipTotals,
  leftLegendOffset = -40,
  calculator,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const allDataIsZero = isAllDataZero(data, keys);
  const [hoveredData, setHoveredData] = useState(data[0] || {});

  let transformedData = data.map((monthData) => {
    return {
      ...monthData,
      month: small ? monthData.month.substring(0, 3) : monthData.month,
    };
  });

  const lineData = [
    {
      id: "Location-Based Emissions",
      data: transformedData.map((d) => ({
        x: d.month,
        y: d.locationBasedEmissions,
      })),
    },
    {
      id: "Market-Based Emissions",
      data: transformedData.map((d) => ({
        x: d.month,
        y: d.marketBasedEmissions,
      })),
    },
  ];

  const handleModal = () => {
    if (calculator) {
      navigate("/welcome/data/calculations");
    } else {
      setShowModal(true);
    }
  };

  //calculate max value for y-axis
  let maxValue = 0;
  lineData.forEach((monthData) => {
    const totalEmissions = keys.reduce((acc, key) => {
      return acc + monthData[key];
    }, 0);
    if (totalEmissions > maxValue) {
      maxValue = totalEmissions;
    }
  });

  return (
    <>
      <div style={{ height: "360px" }}>
        <h5>{title}</h5>
        {!allDataIsZero ? (
          <ResponsiveLine
            data={lineData}
            margin={{ top: 50, right: 200, bottom: 50, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              stacked: false,
              min: "auto",
              max: "auto",
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Month",
              legendOffset: 32,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "CO₂e (tonnes)",
              legendOffset: leftLegendOffset,
              legendPosition: "middle",
            }}
            // colors={colorTheme}
            enableGridY={false}
            enableGridX={false}
            labelTextColor={{ from: "color", modifiers: [["darker", 3]] }}
            labelSkipWidth={small ? 1000 : 0}
            labelSkipHeight={small ? 1000 : 20}
            enableTotals={skipTotals ? false : true}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            useMesh={true}
            theme={{
              labels: {
                text: {
                  fontSize: 14,
                },
              },
            }}
            tooltip={({ point }) =>
              point.data.y > 0 ? (
                <Card style={{ opacity: 0.8 }}>
                  <div
                    style={{
                      display: "flex",
                      gap: 2,
                      padding: 3,
                    }}
                  >
                    <div style={{ paddingLeft: 8 }}>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="me-2"
                        style={{ color: point.color }}
                      />
                    </div>
                    <div>{point.serieId}:</div>
                    <div style={{ paddingRight: 8 }}>
                      <strong>{point.data.yFormatted}</strong> tonnes CO₂e
                    </div>
                  </div>
                </Card>
              ) : null
            }
            legends={[
              {
                anchor: "top-right",
                direction: "column",
                justify: false,
                translateX: 200,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 160,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
                data: [
                  {
                    id: "Month",
                    label: `Month: ${hoveredData.month || ""}`,
                    color: "black",
                  },
                  {
                    id: "Consumption",
                    label: `Consumption: ${
                      hoveredData.electricityConsumption || ""
                    } kWh`,
                    color: "black",
                  },
                ],
              },
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 200,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 160,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            onMouseMove={(point, event) => {
              const monthData = data.find(
                (d) =>
                  d.month === point.data.x ||
                  d.month.substring(0, 3) === point.data.x
              );
              if (monthData) {
                setHoveredData(monthData);
              }
            }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div>
              <p>No data available</p>
            </div>
            <div>
              {" "}
              <Button size="sm" onClick={() => handleModal()}>
                <FontAwesomeIcon icon={faAdd} /> Add Data
              </Button>
            </div>
          </div>
        )}
      </div>
      <UploadDataModal
        showSpendDataModal={showModal}
        toggleSpendDataModal={() => setShowModal(false)}
      />
    </>
  );
};

export default EmissionsAreaChart;
