import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  ProgressBar,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import app from "../../../firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  getDatabase,
  ref as dbRef,
  child,
  push,
  update,
} from "firebase/database";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { useAuth } from "../../../Contexts/AuthContext";
import { getUserBusinessLocations } from "../../new-overview/newOverviewAPI";
import { isProduction, isStaging, isLocal } from "../../../helpers/helper";
import { getAuthorizationHeaderWithContentType } from "../../../helpers/utils";
import { getUserData } from "../../../helpers/store";
import {
  faChevronLeft,
  faChevronRight,
  faFileExcel,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InvoiceFlatFileUploader from "./InvoiceFlatFileUploader";

const SERVER = isProduction()
  ? "Production"
  : isStaging()
  ? "Staging"
  : isLocal()
  ? "Development"
  : null;

const fileTableType = {
  Electricity: "netnada_v2.ElectricityBills",
  Gas: "netnada_v2.GasBills",
  "Business Expenses": "netnada_v2.CSVImports",
  "Supplier Information": "netnada_v2.CSVImports",
  Other: "netnada_v2.CSVImports",
};
const MIME_TYPE_NOT_INCLUDED = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv",
];

const MIME_TYPE_MAP = {
  "application/pdf": "pdf",
  "application/vnd.ms-excel": "xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "text/csv": "csv",
  "image/jpeg": "jpeg",
};

const CATEGORY_NOT_INCLUDED = [
  "Business Expenses",
  "Supplier Information",
  "Other",
];

function FileUploader() {
  const [step, setStep] = useState(1);
  const [data, setData] = useState();
  const [nameId, setNameId] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [files, setFiles] = useState([]); //for multiple files upload
  const [disableInput, setDisableInput] = useState(false); //for multiple files upload
  const [approvalStatus, setApprovalStatus] = useState("Pending");
  const { currentUser } = useAuth();
  const [business, setBusiness] = useState(currentUser.businessId);
  const userId = currentUser.uid;
  const database = getDatabase(app);
  const fireStoreDB = getFirestore(app);
  const [progress, setProgress] = useState(0);
  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    category: "",
    officeId: "",
    approvalStatus: "Pending",
  });

  // Function to handle the "Proceed" button click
  const handleProceed = () => {
    setValue({
      startDate: startDate,
      endDate: endDate,
      category: category,
      officeId: nameId[location] ?? "",
      approvalStatus: "Pending",
    });
    setStep(2);
  };

  //Function to handle the "Back" button click
  const handleBack = () => {
    setCategory("");
    setLocation("");

    setStep(1);
  };

  useEffect(() => {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser.uid);
    setUserProperties(analytics, { businessId: currentUser.businessId });
    logEvent(analytics, "page_view", {
      page_title: "File Uploader",
    });
  }, [currentUser]);

  async function sendToRossum(body) {
    try {
      const url = "https://netnada-rossum-production.herokuapp.com/";
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
        mode: "cors",
      });

      if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(
          `HTTP error! status: ${response.status}, details: ${errorDetails}`
        );
      }

      return response;
    } catch (err) {
      console.error(
        "Error occurred while sending data to Rossum:",
        err.message
      );
      console.log(err);
      throw err; // rethrow the error to handle it where this function is called
    }
  }

  async function uploadFiles(res) {
    try {
      //check if file is not included in MIME_TYPE_NOT_INCLUDED or CATEGORY_NOT_INCLUDED then send to rossum for extraction
      // if (
      //   !CATEGORY_NOT_INCLUDED.includes(category) &&
      //   !MIME_TYPE_NOT_INCLUDED.includes(res.file.type)
      // ) {
      //   const rossumBody = {
      //     userId,
      //     fileKey: res.key,
      //     fileName: res.file.name,
      //     fileURL: res.url,
      //     fileType: res.file.type,
      //     approvalStatus,
      //     category,
      //     startDate,
      //     endDate,
      //     businessId: business,
      //     officeId: nameId[location] ?? "",
      //     server: SERVER,
      //   };
      //   const rossumResp = sendToRossum(rossumBody);
      // }
      //store all data in Postgres
      const tableName = fileTableType[category];
      const body = {
        tableName,
        userId,
        fileKey: res.key,
        fileName: res.file.name,
        fileURL: res.url,
        fileType: MIME_TYPE_MAP[res.file.type],
        approvalStatus,
        category,
        startDate,
        endDate,
        businessId: business,
        officeId: nameId[location] ?? "",
        updatedBy: JSON.parse(getUserData()).uid,
      };
      try {
        await fetch("/api/files/upload-file", {
          method: "POST",
          headers: getAuthorizationHeaderWithContentType(),
          body: JSON.stringify(body),
          mode: "cors",
        });
      } catch (error) {
        console.error(error);
      }
    } catch (err) {
      console.log(err);
      console.error(err.message);
    }
  }

  async function storeFiles(files) {
    const uploadedFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileProgress = { index: i, name: file.name, progress: 0 }; // Progress state for each file
      // console.log("uploading file...", i + 1, "of", files.length);
      const uploadResult = await new Promise((resolve, reject) => {
        const storage = getStorage(app);
        const userFilePath = `users/${currentUser.uid}/${file.name}`;
        const storageRef = ref(storage, userFilePath);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            fileProgress.progress = prog; // Update the progress state for the file
            setProgress((prevProgress) => {
              // Update the progress state for all files
              const updatedProgress = [...prevProgress];
              updatedProgress[i] = fileProgress;
              return updatedProgress;
            });
          },
          (error) => {
            console.log(error);
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              const uploadData = {
                url: downloadURL,
                user: currentUser.uid,
              };
              // console.log("uploadData", uploadData);

              //create new key for the file --> used as document id in SQL
              const newPostKey = push(child(dbRef(database), "Files")).key;
              const updates = {};
              updates[`/Files/${newPostKey}`] = uploadData;
              update(dbRef(database), updates);

              const docRef = await addDoc(
                collection(fireStoreDB, "Files"),
                uploadData
              );

              resolve({
                key: docRef.id,
                file: file,
                url: downloadURL,
              });
            } catch (error) {
              console.error(error);
              reject(error);
            }
          }
        );
      });
      // console.log("uploading file...", i + 1, "of", files.length, "done");
      // console.log("uploadResult", uploadResult);
      uploadedFiles.push(uploadResult);
    }

    return uploadedFiles;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setDisableInput(true);

    if (files.length > 0) {
      const progressArray = files.map((file, index) => ({
        index,
        name: file.name,
        progress: 0,
      }));
      setProgress(progressArray);

      const uploadedFiles = await storeFiles(files);

      for (let i = 0; i < uploadedFiles.length; i++) {
        const result = uploadedFiles[i];
        await uploadFiles(result);
      }

      toast(`File Uploaded Successfully`);
      sessionStorage.setItem("fileUploadCompleted", true);
      window.location = "/welcome/data/all-invoices";
      return;
    }

    // Handle the case when no files are selected
  }

  //upload multiple files
  const uploadMultipleFiles = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  //delete file from list
  const handleFileDelete = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  //fetch list of offices by business ID
  async function getBusinessLocations() {
    const userOffices = await getUserBusinessLocations();
    //console.log(userOffices);
    let businessOffices = [];
    let nameIdDict = {};
    for (let i in userOffices) {
      businessOffices.push(
        userOffices[i]["officename"] + "," + userOffices[i]["office_id"]
      );
      let name = userOffices[i]["officename"];
      let id = userOffices[i]["office_id"];
      nameIdDict[name] = id;
    }
    //console.log(nameIdDict);
    setNameId(nameIdDict);
    //console.log(businessOffices);
    sessionStorage.setItem("businessOffices", JSON.stringify(businessOffices));

    //store all office names in session as string
    let officeName = [];
    for (let i in userOffices) officeName.push(userOffices[i]["officename"]);
    setData(officeName);
    sessionStorage.setItem("officeName", JSON.stringify(officeName));
  }

  //fetch list of offices for the user
  function getOffice() {
    const businessOffices = JSON.parse(
      sessionStorage.getItem("businessOffices")
    );
    let nameIdDict = {};
    for (let i = 0; i < businessOffices.length; i++) {
      let str = businessOffices[i].split(",");
      nameIdDict[str[0]] = str[1];
    }
    setNameId(nameIdDict);
    setData(JSON.parse(sessionStorage.getItem("officeName")));
  }

  useEffect(() => {
    getBusinessLocations();
    getOffice();
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      {step === 1 && (
        <Card>
          <Card.Body>
            <Card.Title>Upload Your Invoices</Card.Title>
            <p>Start by selecting which type of invoice you are uploading.</p>

            <Form.Label>
              Select data category. If not available select other
            </Form.Label>
            <Row className="g-2">
              <Col md>
                <FloatingLabel
                  controlId="floatingSelectGrid"
                  label="Category Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    required
                    onChange={(e) => setCategory(e.target.value)}
                    disabled={disableInput}
                  >
                    <option value="">Select...</option>
                    <option value="Electricity">Electricity</option>
                    <option value="Gas">Gas</option>
                    <option value="Business Expenses">Business Expenses</option>
                    <option value="Supplier Information">
                      Supplier Information
                    </option>
                    <option value="Other">Other</option>
                    {/* <option value="Internet" disabled>
                    Internet
                  </option>
                  <option value="Travel" disabled>
                    Travel
                  </option>
                  <option value="Fuel" disabled>
                    Fuel
                  </option> */}
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
            {category === "Business Expenses" && (
              <p
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  marginTop: "1rem",
                }}
              >
                If you are uploading business expenses, you need to upload a CSV
                file. You can download the template{" "}
                <a
                  href="https://docs.google.com/spreadsheets/d/1MRp2S2K_nVC_b6B2Qgw5bkkzxy08i7JRsfOQLBzrhwU/copy"
                  target="_blank"
                  rel="noreferrer"
                >
                  here <FontAwesomeIcon icon={faFileExcel} />
                </a>
                .
              </p>
            )}

            <Form.Label>
              Choose which location this invoice applies to (optional).
            </Form.Label>
            <Row className="g-2">
              <Col md>
                <FloatingLabel
                  controlId="floatingSelectGrid"
                  label="Location Selection"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    required
                    onChange={(e) => setLocation(e.target.value)}
                    disabled={disableInput}
                  >
                    <option value="">Select...</option>
                    {data
                      ? data.map((item, i) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))
                      : null}
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-2">
              <Col style={{ textAlign: "right" }}>
                <Button
                  as={Link}
                  variant="link"
                  to="/welcome/add-location"
                  onClick={() => {
                    sessionStorage.setItem("FileAddLocation", true);
                  }}
                >
                  Add Location
                </Button>
              </Col>
            </Row>
            <br />
            {/* <br /> */}
            <Card.Title>Billing period</Card.Title>
            <p>
              You can provide additional details for record-keeping purposes so
              you know what you have uploaded so far.
            </p>

            <Form.Label>Start of billing period</Form.Label>
            <DatePicker
              selected={startDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setStartDate(date)}
              className="form-control"
              placeholderText="Select Date"
              disabled={disableInput}
            />

            <Form.Label>End of billing period</Form.Label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control"
              placeholderText="Select Date"
              disabled={disableInput}
            />

            {/* <ProgressBar now={progress} label={`${progress}%`} /> */}

            <ToastContainer />
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end">
            <Button
              variant="primary"
              onClick={handleProceed}
              disabled={!category}
            >
              Proceed <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </Card.Footer>
        </Card>
      )}
      {step === 2 && (
        <>
          {category === "Business Expenses" && (
            <Card className="mt-4">
              <Card.Body>
                <InvoiceFlatFileUploader metadata={value} />
              </Card.Body>
              <Card.Footer>
                <Button variant="primary" onClick={handleBack}>
                  <FontAwesomeIcon icon={faChevronLeft} /> Back
                </Button>
              </Card.Footer>
            </Card>
          )}
          {category !== "Business Expenses" && category !== "" && (
            <Card className="mt-4">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Card.Title>Upload Files</Card.Title>
                  <Button
                    className="w-auto"
                    type="submit"
                    disabled={
                      !files.length > 0 ||
                      !category ||
                      !location ||
                      !startDate ||
                      !endDate ||
                      disableInput
                    }
                  >
                    Submit
                  </Button>
                </div>
                <p>Upload your invoices here.</p>

                <Form.Group controlId="formFileLg" className="mb-3">
                  <Form.Label>
                    Upload Your Invoices(only .jpg/.pdf/.csv/.xlsx/.xls files
                    accepted)
                  </Form.Label>

                  <Form.Control
                    type="file"
                    size="lg"
                    accept=".jpg,.pdf,.csv,.xlsx,.xls"
                    onChange={uploadMultipleFiles}
                    multiple
                    disabled={disableInput}
                  />
                </Form.Group>

                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Size</th>
                      <th>Type</th>
                      {!disableInput && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {files.length > 0 ? (
                      files.map((file, index) => (
                        <tr key={index}>
                          <td>
                            <div key={index}>
                              <span>{file.name}</span>
                              <ProgressBar now={progress[index]?.progress} />
                            </div>
                          </td>
                          <td>{(file.size / 1024).toFixed(2)} KB</td>
                          <td>{file.type}</td>
                          {!disableInput && (
                            <td>
                              <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => handleFileDelete(index)}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </button>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No files selected
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <Button variant="primary" onClick={handleBack}>
                  <FontAwesomeIcon icon={faChevronLeft} /> Back
                </Button>
              </Card.Footer>
            </Card>
          )}
        </>
      )}
    </Form>
  );
}

export default FileUploader;
