import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { getWhiteLabel } from "../../helpers/store";

const WelcomeModal = () => {
  const [modalShow, setModalShow] = React.useState(true);

  useEffect(() => {
    sessionStorage.setItem("showModal", false);
  }, []);

  return (
    <div>
      {" "}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Thank you for integrating with {getWhiteLabel().name}!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>We are now calculating your carbon footprint emissions</h4>
          <p>
            Businesses that provide financial accounting data to{" "}
            {getWhiteLabel().name} via our secure partners obtain a carbon
            footprint result via a consumption-based accounting approach. This
            approach considers the full life cycle emissions of all goods and
            services from production — no matter where — through to consumption
            within the business.{" "}
            <a
              href="https://www.netnada.com.au/certifications/methodology"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WelcomeModal;
