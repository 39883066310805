import React from "react";
import PageHeader from "../../components/common/PageHeader";
import FalconCardHeader from "../common/FalconCardHeader";
import { Card } from "react-bootstrap";
import screenshot1 from "../../Assets/screenshots/Screenshot1.png";
import screenshot2 from "../../Assets/screenshots/Screenshot2.png";
import screenshot3 from "../../Assets/screenshots/Screenshot3.png";
import screenshot4 from "../../Assets/screenshots/Screenshot4.png";
import screenshot5 from "../../Assets/screenshots/Screenshot5.png";
import { getWhiteLabel } from "../../helpers/store";

const GettingStarted = () => {
  return (
    <>
      <Card>
        <Card.Body className="position-relative">
          <h3 className="mb-0">Getting Started</h3>
          <br />
          <Card.Title>
            How to navigate to the integration and connect to Xero
          </Card.Title>
          <p>
            The user can navigate to Integration either from{" "}
            <a
              href="https://app.netnada.com.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sign-in
            </a>
            /
            <a
              href="https://app.netnada.com.au/authentication/card/register"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sign-up
            </a>{" "}
            or the{" "}
            <a
              href="https://app.netnada.com.au/welcome/overview"
              target="_blank"
              rel="noopener noreferrer"
            >
              overview
            </a>{" "}
            screen
          </p>

          <p>
            <img
              src={screenshot2}
              alt="signin"
              style={{
                height: "auto",
                maxHeight: "600px",
                width: "auto",
                maxWidth: "600px",
              }}
            />
          </p>

          <p>
            <img
              src={screenshot3}
              alt="signup"
              style={{
                height: "auto",
                maxHeight: "600px",
                width: "auto",
                maxWidth: "600px",
              }}
            />
          </p>

          <p>
            <img
              src={screenshot1}
              alt="overview"
              style={{
                height: "auto",
                maxHeight: "600px",
                width: "auto",
                maxWidth: "600px",
              }}
            />
          </p>
        </Card.Body>

        <Card.Body className="position-relative">
          <Card.Title>Authentication Flow</Card.Title>
          <p>
            The authentication flow starts when you click on Xero button for
            Integration.
          </p>
          <p>
            Step 1 : Enter your Xero log in details(Make sure you have a Xero
            account){" "}
          </p>
          <p>
            <img
              src={screenshot4}
              alt="signin"
              style={{
                height: "auto",
                maxHeight: "600px",
                width: "auto",
                maxWidth: "600px",
              }}
            />
          </p>
          <p>
            Step 2 : On allowing access, {getWhiteLabel().name} would be able to
            access your organisation's details along with journals to calculate
            emissions.{" "}
          </p>
          <p>
            <img
              src={screenshot5}
              alt="signup"
              style={{
                height: "auto",
                maxHeight: "600px",
                width: "auto",
                maxWidth: "600px",
              }}
            />
          </p>
        </Card.Body>
      </Card>
    </>
  );
};

export default GettingStarted;
