import React from "react";
import { Col, Row } from "react-bootstrap";
import BusinessDetailsWizard from "./BusinessDetailsWizard";
import AuthCardLayout from "../../layouts/AuthCardLayout";
import Logo from "../common/Logo";
import Section from "../common/Section";

import bgShape from "../../Assets/img/illustrations/bg-shape.png";
import shape1 from "../../Assets/img/illustrations/shape-1.png";
import halfCircle from "../../Assets/img/illustrations/half-circle.png";
import Flex from "../common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
const FormTitle = ({ icon, title }) => {
  return (
    <Flex className="mb-4">
      <span className="fa-stack me-2 ms-n1">
        {/* <FontAwesomeIcon icon="circle" className="fa-stack-2x text-300" />
        <FontAwesomeIcon
          icon={icon}
          className="fa-inverse fa-stack-1x text-primary"
        /> */}
      </span>
      <div className="flex-1">
        <h5 className="mb-0 text-primary position-relative">
          <span className="bg-200 dark__bg-1100 pe-3">{title}</span>
          <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
        </h5>
        <p className="mb-0">
          Add some additional details to help set up your account. This will
          ensure that we can provide you with a tailored sustainability
          experience.
        </p>
      </div>
    </Flex>
  );
};
const BusinessDetailsForm = () => {
  return (
    // <AuthCardLayout
    //   leftSideContent={
    //     <p className="pt-3 text-white">
    //       Have an account?
    //       <br />
    //     </p>
    //   }
    //   footer={false}
    // >
    //   {/* <h3>Register</h3> */}
    //   {/* <RegistrationForm layout="card" hasLabel /> */}
    //   <BusinessDetailsWizard validation={true} />
    // </AuthCardLayout>
    <Section className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col sm={10} lg={7} className="col-xxl-5">
          <img
            className="bg-auth-circle-shape"
            src={bgShape}
            alt=""
            width="250"
          />
          <img
            className="bg-auth-circle-shape-2"
            src={shape1}
            alt=""
            width="150"
          />
          <Logo width={50} textClass="fs-4" />
          <FormTitle
            icon={faCheckDouble}
            title="Ready to win in sustainability?"
          />
          <BusinessDetailsWizard validation={true} />
        </Col>
      </Row>
    </Section>
  );
};

export default BusinessDetailsForm;
