import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useProjectHook from "../project/useProjectHook";
import { ProjectContext } from "../../../context/Context";
import PortfolioPercentageController from "../PortfolioPercentageController";

const CartItem = ({ project }) => {
  const {
    id,
    imagesrc,
    title,
    link,
    type,
    costpertonne,
    description,
    country,
    projectCost,
    percentage,
    totalEmission,
  } = project;

  const {
    projectsState: { projects, cartItems, totalEmissions },
    projectsDispatch,
  } = useContext(ProjectContext);

  const handleRemove = () => {
    projectsDispatch({
      type: "REMOVE_FROM_CART",
      payload: {
        project,
      },
    });

    //update percentage for each project in cart on addition of new project
    if (cartItems.length > 0) {
      cartItems.forEach((item) => {
        const percentage = 100 / (cartItems.length - 1);
        const totalEmission = totalEmissions * (percentage / 100);
        const calculatedCost = Math.round(totalEmission * item.costpertonne);
        const projectCost = totalEmission * item.costpertonne;
        projectsDispatch({
          type: "UPDATE_CART_ITEM",
          payload: {
            project: {
              ...item,
              projectCost,
              percentage,
              totalEmission,
            },
          },
        });
      });
    }
  };

  const handlePercentageChange = (e) => {
    const percentage = parseInt(e.target.value);
    const totalEmission = totalEmissions * (percentage / 100);
    const projectCost = totalEmission * project.costpertonne;
    projectsDispatch({
      type: "UPDATE_CART_ITEM",
      payload: {
        project: {
          ...project,
          projectCost,
          percentage,
          totalEmission,
        },
      },
    });
  };

  const handleIncrease = () => {
    const newPercentage = parseInt(percentage + 1);
    const totalEmission = totalEmissions * (newPercentage / 100);
    const projectCost = project.costpertonne * totalEmission;
    projectsDispatch({
      type: "UPDATE_CART_ITEM",
      payload: {
        project: {
          ...project,
          projectCost,
          percentage: newPercentage,
          totalEmission,
        },
      },
    });
  };

  const handleDecrease = () => {
    const newPercentage = parseInt(percentage - 1);
    const totalEmission = totalEmissions * (newPercentage / 100);
    const projectCost = project.costpertonne * totalEmission;
    if (percentage > 1) {
      projectsDispatch({
        type: "UPDATE_CART_ITEM",
        payload: {
          project: {
            ...project,
            projectCost,
            percentage: newPercentage,
            totalEmission,
          },
        },
      });
    }
  };

  return (
    <Row className="gx-card mx-0 align-items-center border-bottom border-200">
      <Col xs={6} lg={5} className="py-3">
        <div className="d-flex align-items-center">
          <img
            src={imagesrc}
            width="60"
            alt={title}
            className="img-fluid rounded-1 me-3"
          />

          <div>
            <h5 className="fs-0">
              <Link to={link} target="_blank" className="text-900">
                {title}
              </Link>
            </h5>
            <Button
              variant="link"
              size="sm"
              className="text-danger fs--2 p-0"
              onClick={handleRemove}
            >
              Remove
            </Button>
          </div>
        </div>
      </Col>
      <Col xs={6} lg={7} className="py-3">
        <Row className="align-items-center h-100">
          <Col xs={6} lg={3} className="text-center">
            {costpertonne}
          </Col>
          <Col lg={3} className="py-3 text-center">
            <PortfolioPercentageController
              percentage={percentage}
              handlePercentageChange={handlePercentageChange}
              handleIncrease={handleIncrease}
              handleDecrease={handleDecrease}
              btnClassName="px-2"
            />
          </Col>
          <Col xs={6} lg={3} className="text-center py-3">
            {Math.round(totalEmission)}
          </Col>
          <Col lg={3} className="text-end py-3">
            ${projectCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CartItem.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    imagesrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    costpertonne: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    totalPrice: PropTypes.number.isRequired,
  }),
};

export default CartItem;
