import { useEffect, useState } from "react";
import { Modal, Form, Button, Spinner, Alert, Row, Col } from "react-bootstrap";
import { createCustomEmissionFactor } from "../../helpers/suppliersHelper";
import { countryCodeList } from "../../data/CountryStateData";
import { set } from "lodash";

const CustomEmissionFactorModal = ({
  showCustomEmissionFactorModal,
  handleCloseCustomEmissionFactorModal,
  data,
}) => {
  const [primaryCategory, setPrimaryCategory] = useState("");
  const [secondaryCategory, setSecondaryCategory] = useState("");
  const [year, setYear] = useState("");
  const [emissionFactor, setEmissionFactor] = useState("");
  const [unit, setUnit] = useState("");
  const [co2eUnit, setCo2eUnit] = useState("kg CO₂e");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [countryData, setCountryData] = useState(countryCodeList());

  const units = [
    "$",
    "GJ",
    "kg",
    "kL",
    "km",
    "kwh",
    "m³",
    "night",
    "pax.km",
    "t.km",
    "tonne",
  ]; // Add more units as needed

  const handleCountryChange = (event) => {
    // Find the country data from the countryData state that matches the selected value
    const selectedCountry = countryData.find(
      (country) => country.name === event.target.value
    );

    if (selectedCountry) {
      setCountry(selectedCountry.name);
      setCountryCode(selectedCountry.code);
    }
  };

  async function handleSubmit(event) {
    setIsLoading(true);
    event.preventDefault();
    // console.log("Submitting custom emission factor data...");
    // console.log("Primary Category:", primaryCategory);
    // console.log("Secondary Category:", secondaryCategory);
    // console.log("Year:", year);
    // console.log("Emission Factor:", emissionFactor);
    // console.log("Unit:", unit);
    // console.log("CO₂e Unit:", co2eUnit);
    // console.log("Country:", country);
    // console.log("Country Code:", countryCode);
    // console.log("Business ID:", data.businessId);

    try {
      const response = await createCustomEmissionFactor({
        categoryName: primaryCategory,
        activityName: secondaryCategory,
        year: year,
        co2e_total: emissionFactor,
        co2e_unit: co2eUnit,
        unit: unit,
        region_name: country,
        region_code: countryCode,
        businessId: data.businessId,
      });

      handleCloseCustomEmissionFactorModal();
    } catch (error) {
      console.error("Error submitting custom emission factor:", error);
      alert("Failed to submit the data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // This effect will check if all required fields have values to enable the submit button
    const checkFields =
      primaryCategory &&
      secondaryCategory &&
      year &&
      emissionFactor &&
      unit &&
      country;
    setIsButtonDisabled(!checkFields);
  }, [primaryCategory, secondaryCategory, year, emissionFactor, unit, country]);

  return (
    <Modal
      show={showCustomEmissionFactorModal}
      onHide={handleCloseCustomEmissionFactorModal}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Row className="justify-content-center">
          {/* <Col> */}
          <Modal.Title>Custom Emission Factor</Modal.Title>

          {/* </Col> */}
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">
          <Alert.Heading>Instructions</Alert.Heading>
          <p>
            Please fill in the form below to add a custom emission factor for
            your business. This will help you calculate your carbon footprint
            more accurately. Ensure that the emission factor you enter results
            in an output of kg CO₂e.
          </p>
        </Alert>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Primary Category Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="E.g. Construction Materials"
              value={primaryCategory}
              onChange={(e) => setPrimaryCategory(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Secondary Category Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="E.g. Aluminium"
              value={secondaryCategory}
              onChange={(e) => setSecondaryCategory(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Year</Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="E.g. 2023"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Emission Factor</Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="E.g. 0.54"
              value={emissionFactor}
              onChange={(e) => setEmissionFactor(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Unit</Form.Label>
            <Form.Select
              required
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            >
              <option value="">Select unit of measure</option>
              {units.map((unit, index) => (
                <option key={index} value={unit}>
                  {unit}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>CO₂e Unit</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter CO₂e unit"
              value={co2eUnit}
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Country</Form.Label>

            <Form.Select
              required
              name="country"
              value={country}
              onChange={(e) => handleCountryChange(e)}
            >
              <option>Select the country</option>
              {countryData.map((data, index) => {
                return (
                  <option key={index} value={data.name}>
                    {data.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <br />
          <Button
            variant="primary"
            type="submit"
            disabled={isButtonDisabled || isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Submit"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomEmissionFactorModal;
