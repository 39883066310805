import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faBan, faStream } from "@fortawesome/free-solid-svg-icons";
import SoftBadge from "../../components/common/SoftBadge";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import PartnerTableHeader from "./partnerTableHeader";
import AdvanceTableFooter from "../../components/common/advance-table/AdvanceTableFooter";
import { getSelectedPartners } from "./partnersAPI";
import { useAuth } from "../../Contexts/AuthContext";

const recentPurchaseTableBadge = {
  Completed: { content: "Success", type: "success", icon: faCheck },
  InProgress: { content: "In-Progress", type: "warning", icon: faStream },
};

const columns = [
  {
    accessor: "location",
    Header: "Location",
    headerProps: { className: "pe-7" },
  },
  {
    accessor: "name",
    Header: "Partner Name",
    headerProps: { className: "pe-1" },
    cellProps: {
      className: "fw-semi-bold",
    },
  },
  // {
  //   accessor: "patnerprovider",
  //   Header: "Provider",
  //   headerProps: { className: "pe-7" },
  // },
  {
    accessor: "startDateString",
    Header: "Date Added",
  },
  {
    accessor: "approval_status",
    Header: "Status",

    headerProps: { className: "text-center" },
    cellProps: {
      className: "fs-0",
    },
    Cell: (rowData) => {
      const { status } = rowData.row.original;
      return (
        <SoftBadge pill bg={status.type} className="d-block">
          {status.content}
          <FontAwesomeIcon
            icon={status.icon}
            transform="shrink-2"
            className="ms-1"
          />
        </SoftBadge>
      );
    },
  },
];

const PartnerTable = () => {
  const { currentUser } = useAuth();
  const [business, setBusiness] = useState(currentUser.businessId);
  const [data, setData] = useState(null);

  //fetch list of offices for the business
  const [nameId, setNameId] = useState(() => {
    const businessOffices = JSON.parse(
      sessionStorage.getItem("businessOffices")
    );
    let nameIdDict = {};
    for (let i = 0; i < businessOffices.length; i++) {
      let str = businessOffices[i].split(",");
      console.log(str);
      nameIdDict[str[1]] = str[0];
    }
    return nameIdDict;
  });

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  }

  const getPartners = async () => {
    try {
      const partners = await getSelectedPartners();
      console.log(partners);

      //set status partners
      for (var i = 0; i < partners.length; i++) {
        const status = partners[i]["progress_status"];
        //console.log(recentPurchaseTableBadge[status])
        partners[i]["status"] = recentPurchaseTableBadge[status];
      }
      //format start date
      for (var j = 0; j < partners.length; j++) {
        const startDate = partners[j]["date_added"];
        partners[j]["startDateString"] = formatDate(startDate);
      }

      //set location name
      for (var l = 0; l < partners.length; l++) {
        const locationId = partners[l]["office_id"];
        partners[l]["location"] = nameId[locationId];
      }
      //store all selected partners in session as string
      let selectedPartners = [];
      for (let i in partners) selectedPartners.push(partners[i]);
      sessionStorage.setItem(
        "selectedPartners",
        JSON.stringify(selectedPartners)
      );
      console.log(partners);
      setData(partners);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getPartners();
  }, []);
  return (
    <>
      {data ? (
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          selection
          sortable
          pagination
          perPage={7}
          rowCount={data.length}
        >
          <Card>
            <Card.Header>
              <PartnerTableHeader table />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "sm",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={data.length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : null}
    </>
  );
};

export default PartnerTable;
