import React from "react";
import PropTypes from "prop-types";

import { ToastContainer } from "react-toastify";
import { Button, Form } from "react-bootstrap";
import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";
import { clearLoginData, setIdToken, setUserData } from "../../helpers/store";
import { auth } from "../../firebase";

const MYOBCompanyListForm = ({ hasLabel, companyList }) => {
  console.log("companyList=>", companyList);
  const [company, setCompany] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    console.log("company=>", company);
    setIsLoading(true);
    //filter companyList to get selected company
    const selectedCompany = companyList.filter(
      (item) => item.Id === company
    )[0];
    console.log("selectedCompany=>", selectedCompany);

    const response = await fetch("/api/myob/get-selected-company-data", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(selectedCompany),
      mode: "cors",
    });
    if (response.status !== 200) {
      clearLoginData();
    }
    const data = await response.json();
    if (!data || !data.customToken)
      throw new Error("customToken should not be empty");
    await auth.signInWithCustomToken(data.customToken);
    const idToken = await auth.currentUser.getIdToken(true);
    setIdToken(idToken);
    setUserData(JSON.stringify(data.user));
    setIsLoading(false);
    window.location.href = "/myob-account-list";
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        {hasLabel && (
          <Form.Label>
            Select from the drop down menu which business you are connecting to
            NetNada. If you only have one business under this email account,
            only one option will be available.
          </Form.Label>
        )}
        <Form.Select required onChange={(e) => setCompany(e.target.value)}>
          <option>Select...</option>
          {companyList
            ? companyList.map((item, i) => (
                <option key={i} value={item.Id}>
                  {item.Name}
                </option>
              ))
            : null}
        </Form.Select>
      </Form.Group>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!company}
        >
          {isLoading && (
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Allow Access
        </Button>
      </Form.Group>
      <ToastContainer />
    </Form>
  );
};

MYOBCompanyListForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
};

MYOBCompanyListForm.defaultProps = {
  layout: "simple",
  hasLabel: false,
};

export default MYOBCompanyListForm;
