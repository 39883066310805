import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import Lottie from "lottie-react";
import celebration from "./lottie/celebration.json";
import { AuthWizardContext } from "../../context/Context";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  UpdateBusinessDetails,
  UpdateLocation,
  UpdateSuppliersCollection,
} from "./BusinessDetailsAPI";
import {
  getActiveUserData,
  getWhiteLabel,
  setActiveUserData,
} from "../../helpers/store";
import { createContentImageAPI } from "../content/contentApi";

const Success = ({ reset, getValues }) => {
  const navigate = useNavigate();
  const { setStep, setUser } = useContext(AuthWizardContext);
  const [isLoading, setIsLoading] = useState(false);

  const emptyData = () => {
    setStep(1);
    setUser({});
    reset();
  };

  async function createContentImage(userData) {
    const body = {
      requestedBy: userData.uid,
      contentType: "Milestone",
      title: `Welcome to ${getWhiteLabel().name}`,
      description: `Welcome to ${getWhiteLabel().name}`,
      imageName: "welcome.jpg",
      modifications: {
        companyName: userData.businessName,
        whitelabelName: getWhiteLabel().name,
      },
    };
    const response = await createContentImageAPI(body);
    //console.log("response", response);
  }

  const storeData = async () => {
    // console.log("getValues", getValues());

    setIsLoading(true);
    let userData = JSON.parse(getActiveUserData());

    const {
      ESGManager,
      ESGStrategy,
      carbonFootprint,
      country,
      state,
      numberOfEmployees,
      organisation,
      industryFields,
      primaryCategory,
      secondaryCategory,
    } = getValues();

    let body = "";
    let email = userData["email"];

    //update business details
    body = {
      ESGManager,
      ESGStrategy,
      carbonFootprint,
      numberOfEmployees,
      organisation,
      industryFields,
      primaryCategory,
      secondaryCategory,
      email,
    };
    await UpdateBusinessDetails(body);

    //add new office for the business
    const countryName = country.split(",")[0];
    const countryCode = country.split(",").at(-1);

    const stateName = state.split(",")[0];
    const stateCode = state.split(",").at(-1);

    console.log("Active user data", userData);
    //update business name
    userData["businessName"] = organisation;

    body = {
      officeId: userData["defaultOfficeId"],
      locationName: "Location 1 - " + stateName,
      country: countryName,
      countryCode,
      state: stateName,
      stateCode,
      streetAddress: "",
      city: "",
      postcode: "",
    };

    //update location details
    const response = await UpdateLocation(body);

    body = {
      businessId: userData["businessId"],
      businessName: organisation,
      country: countryName,
      state: stateName,
      primaryCategory,
      secondaryCategory,
    };

    // add new client ot Supplier collection
    await UpdateSuppliersCollection(body);

    // console.log(response);

    //update 'hasDefaultOffice' to false
    userData["hasDefaultOffice"] = false;
    // setUserData(JSON.stringify(userData));
    setActiveUserData(JSON.stringify(userData));

    //create content image
    createContentImage(userData);

    setIsLoading(false);

    //navigate to overview screen
    toast(`Successfully completed steps`);
    window.location.href = "/welcome";
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">Your account is all set!</h4>
          <p className="fs-0">Now you can access to your account</p>
          {/* <Button color="primary" className="px-5 my-3" onClick={storeData}>
            Get Started
          </Button> */}
          <Button
            color="primary"
            className="px-5 my-3"
            onClick={storeData}
            disabled={isLoading}
          >
            {isLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {isLoading ? " Loading..." : "Get Started"}
          </Button>

          <ToastContainer />
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired,
};

export default Success;
