import { Link } from "react-router-dom";
import { wasteTypes } from "../ClimateActive/Waste/wasteData";

export function getTopThreeLabels(data) {
  // Sort the array in descending order based on the 'value' property
  const sortedData = data.sort((a, b) => b.value - a.value);

  // Extract the top three labels
  const [first, second, third] = sortedData
    .slice(0, 3)
    .map((item) => item.label);

  return { first, second, third };
}

export function computePercentages(data) {
  // Calculate the total emissions
  const totalEmissions = data.reduce((sum, { value }) => sum + value, 0);

  // Get the emissions for the top 10 suppliers
  const topTenEmissions = data
    .slice(1, 11)
    .reduce((sum, { value }) => sum + value, 0);

  // Get the emissions for "Others"
  const othersEmissions = data.find(({ id }) => id === "Others").value;

  // Calculate the percentages
  const percentageTopTen = (topTenEmissions / totalEmissions) * 100;
  const percentageOthers = (othersEmissions / totalEmissions) * 100;

  return {
    percentageTopTen: percentageTopTen.toFixed(0),
    percentageOthers: percentageOthers.toFixed(0),
  };
}

export function extractDataBarChart(id, data) {
  // Find the entry with the most datalines
  const maxDatalinesEntry = data?.reduce((maxEntry, entry) => {
    return entry.datalines > maxEntry.datalines ? entry : maxEntry;
  }, data[0]);

  // Find the entry with the specified id
  const specifiedEntry = data?.find((entry) => entry.id === id);

  if (!specifiedEntry) {
    return `Entry with id: ${id} not found.`;
  }

  return {
    maxDatalinesLabel: maxDatalinesEntry.label,
    maxDatalines: maxDatalinesEntry.datalines,
    specifiedLabel: specifiedEntry.label,
    specifiedDatalines: specifiedEntry.datalines,
    isSameAsMax: specifiedEntry.id === maxDatalinesEntry.id,
  };
}

export const isAllDataZero = (data, keys, threshold = 0.001) => {
  return (
    !data ||
    data.length === 0 ||
    data.every((item) => keys.every((key) => item[key] < threshold))
  );
};

export const wasteCategories = [
  "General waste",
  "Commercial and Industrial Waste",
  "Construction and demolition waste",
  "Food",
  "Paper and cardboard",
  "Garden and green",
  "Wood",
  "Textiles",
  "Sludge",
  "Sanitary and nappies",
  "Rubber and leather",
  "Recycling",
  "Glass",
];

export const generateCalculatorText = (keyProp, data) => {
  // Count months with missing or zero data
  const missingMonths = data?.filter(
    (month) => parseFloat(month[keyProp]) === 0
  ).length;

  // Function to generate the specific text based on keyProp using a switch statement
  const getText = (key, missingMonths) => {
    let message = "";
    let baseLink = `/welcome/data/calculations`; // Default link
    if (key === "EmployeeCommute") {
      baseLink = "/welcome/data/employee-engagement";
    }
    // let baseLink =
    //   key === "EmployeeCommute"
    //     ? `/welcome/data/climate-active/staff-form`
    //     : `/welcome/data/climate-active/${key.toLowerCase()}-form`; // Default link

    // /data/climate-active/flights-form
    // data/climate-active/paper-form
    // data/climate-active/staff-form
    // data/climate-active/waste-form
    // data/wfh
    // Adjusting the base link for EmployeeCommute
    if (key === "WFH") {
      // baseLink = "/welcome/data/wfh";
      baseLink = "/welcome/data/employee-engagement";
    }

    switch (key) {
      case "Flights":
      case "Paper":
      case "Water":
        if (missingMonths === data?.length) {
          message = (
            <p>
              No data inputted in the {key} calculator.{" "}
              {key === "Flights"
                ? "Flights are a big contributor to emissions. You can input any flight data as it significantly impacts your carbon footprint."
                : "Please consider tracking your paper usage to manage your environmental impact."}
              Input data <Link to={baseLink}>here.</Link>
            </p>
          );
        } else {
          message = (
            <p>
              Data is available for {key}. Please review to ensure it's
              up-to-date.
            </p>
          );
        }
        break;
      default:
        if (missingMonths >= 2) {
          message = (
            <p>
              Data is missing for some months. Accurate tracking is crucial for
              effective carbon management. You can update your data in the {key}{" "}
              calculator <Link to={baseLink}>here</Link>.
            </p>
          );
        } else if (missingMonths === 0) {
          message = <p>All data for {key} is correct and up-to-date.</p>;
        } else {
          message = (
            <p>
              Some data is available for {key}, but there is a month with no
              data. Please review and{" "}
              <Link to={baseLink}>update your data if necessary here</Link> to
              ensure accuracy.
            </p>
          );
        }
        break;
    }

    return message;
  };

  return getText(keyProp, missingMonths);
};
export const getOfficeName = (id) => {
  const storedLocationsJSON = sessionStorage.getItem("userBusinessLocations");
  if (!storedLocationsJSON) return null; // Check if the data exists in sessionStorage

  const locations = JSON.parse(storedLocationsJSON);
  const office = locations.find((office) => office.office_id === id);

  return office ? office.officename : null; // Return null if no office found
};

export const getColorForCategory = (category) => {
  const wasteType = wasteTypes.find(
    (type) => type.value.toLowerCase() === category.toLowerCase()
  );
  return wasteType ? wasteType.color : "#000000"; // Default to black if not found
};
