import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { clearLoginData, getActiveUserData } from "../../helpers/store";
import {
  getAuthorizationHeader,
  getAuthorizationHeaderWithContentType,
} from "../../helpers/utils";
import app from "../../firebase";

const fireStoreDB = getFirestore(app);

export const sendLoopsEvent = async (eventName, contactProperties) => {
  const activeUser = JSON.parse(getActiveUserData());
  const email = activeUser.email;

  let data = {
    email: email,
    eventName: eventName,
    contactProperties: contactProperties,
  };

  // console.log("Sending data to Loops", data);

  try {
    const response = await fetch("/api/loops/send-event", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(data),
      mode: "cors",
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateLoopsContact = async (contactProperties) => {
  const activeUser = JSON.parse(getActiveUserData());
  const email = activeUser.email;

  let data = {
    email: email,
    contactProperties: contactProperties,
  };

  // console.log("Sending data to Loops", data);

  try {
    const response = await fetch("/api/loops/update-contact", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(data),
      mode: "cors",
    });
  } catch (error) {
    console.error(error);
  }
};

export const getTransparencyAssessment = async (supplierId) => {
  try {
    // Properly reference the Surveys subcollection under a specific TransparencyAssessment document
    const surveysCollectionRef = collection(
      fireStoreDB,
      "TransparencyAssessment",
      supplierId,
      "Surveys"
    );

    // Fetch all documents from the Surveys subcollection
    const querySnapshot = await getDocs(surveysCollectionRef);

    // Extract data from each document
    const surveys = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return surveys;
  } catch (error) {
    console.error("Failed to retrieve surveys:", error);
    throw error; // Rethrow the error if you want to handle it in the calling context
  }
};

export const updateSupplierId = async (businessId, supplierId) => {
  try {
    const response = await fetch(
      `/api/users/update-supplier-id-business/${businessId}/${supplierId}`,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getBusinessDetails = async () => {
  try {
    const response = await fetch("/api/users/get-business", {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return;
  } catch (error) {
    console.error(error);
  }
};

export const createSupplier = async (supplierData) => {
  try {
    // Create a new document in the Suppliers collection with the supplier data
    const docRef = await addDoc(collection(fireStoreDB, "Suppliers"), {
      primaryCategory: supplierData.primaryCategory,
      secondaryCategory: supplierData.secondaryCategory,
      name: supplierData.name,
      merchantLogo: supplierData.merchantLogo,
      netnadaId: supplierData.netnadaId,
      status: "drafted",
      isSupplier: true,
    });

    // console.log("Supplier created with ID: ", docRef.id);
    return docRef.id; // Return the new document's ID
  } catch (error) {
    console.error("Error adding document: ", error);
    return null; // Or handle the error as needed
  }
};

export const addFilesToSurvey = async (supplierId, surveyId, filesData) => {
  try {
    const response = await fetch("/api/transparencyHub/add-files", {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify({ supplierId, surveyId, filesData }),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    }
  } catch (error) {
    console.error(error);
  }
};

export const addSurvey = async (data) => {
  try {
    const response = await fetch("/api/transparencyHub/add-survey", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(data),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    }
  } catch (error) {
    console.error(error);
  }
};

export const createTransparencyAssessment = async (data) => {
  try {
    const response = await fetch(
      "/api/transparencyHub/create-tranparency-assessment",
      {
        method: "POST",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(data),
        mode: "cors",
      }
    );

    if (response.status === 401) {
      clearLoginData();
    }
  } catch (error) {
    console.error(error);
  }
};

export async function initializeAssessment(
  supplierId,
  businessId,
  year,
  yearType,
  assessmentData
) {
  let assessmentInitialized = false;
  if (!Array.isArray(assessmentData)) {
    assessmentData = [];
  }
  // Check if a survey already exists
  const surveyExists = assessmentData?.some(
    (survey) => survey.id === `${yearType}${year}`
  );

  if (!surveyExists) {
    if (assessmentData.length === 0) {
      // Create a new transparency assessment
      let newAssessmentData = {
        supplierId,
        surveyId: `${yearType}${year}`,
        reportingYear: year,
        reportingType: yearType,
        businessId: businessId,
        email: "",
        status: "REGISTERED",
        score: 0,
        files: [],
        invitedBy: "",
      };
      // console.log("new TransparencyAssessment", newAssessmentData)
      await createTransparencyAssessment(newAssessmentData);
      assessmentInitialized = true;
    } else {
      // Add a new survey
      let newSurveyData = {
        supplierId,
        surveyId: `${yearType}${year}`,
        reportingYear: year,
        reportingType: yearType,
        status: "REGISTERED",
        score: 0,
        files: [],
        invitedBy: "",
      };
      // console.log("surveyYear", newSurveyData)
      await addSurvey(newSurveyData);
      assessmentInitialized = true;
    }
  }
  return assessmentInitialized;
}

export const updateWizardStepInDatabase = async (inventoryId, targetStep) => {
  try {
    const response = await fetch(`/api/transparencyHub/update-wizard-step`, {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
      body: JSON.stringify({ inventoryId, targetStep }),
    });
    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const jsonData = await response.json();
        return jsonData;
      } else {
        // Handle non-JSON response
        const textData = await response.text();
        return { message: textData };
      }
    } else if (response.status === 401) {
      clearLoginData();
      return { error: "Unauthorized" };
    } else {
      return { error: `Error: ${response.status}` };
    }
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
};
