import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BusinessDetailsWizardInput from "./BusinessDetailsWizardInput";
import { Industries, australianStates } from "./BusinessDetailsData";
import {
  countryStateDataJSON,
  countryNameList,
  countryCodeList,
} from "../../data/CountryStateData";
import app from "../../firebase";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { filter, groupBy, map, omit, sumBy } from "lodash";

const fireStoreDB = getFirestore(app);

const Screen1 = ({ register, errors, setValue }) => {
  const [countryData, setCountryData] = useState(countryCodeList());
  const [countryStateJSON, setCountryStateJSON] = useState(
    countryStateDataJSON()
  );
  const [stateData, setStateData] = useState(australianStates);
  const [primaryCategories, setPrimaryCategories] = useState([]);
  const [secondaryCategories, setSecondaryCategories] = useState([]);
  const [combinedCategoryFactorsObj, setCombinedCategoryFactorsObj] = useState(
    {}
  );
  const [selectedPrimaryCategory, setSelectedPrimaryCategory] = useState(null);

  // Add a function to fetch primary categories
  async function fetchCategories() {
    const q = query(collection(fireStoreDB, "EmissionsFactors"));
    const querySnapshot = await getDocs(q);

    let primaryCategories = [];
    let combinedCategoryFactorsObj = {};

    querySnapshot.forEach((doc) => {
      primaryCategories.push(doc.id);
      combinedCategoryFactorsObj[doc.id] = doc.data();
    });
    // console.log("combinedCategoryFactorsObj", combinedCategoryFactorsObj);
    // console.log("primaryCategories", primaryCategories);
    setPrimaryCategories(primaryCategories);
    setCombinedCategoryFactorsObj(combinedCategoryFactorsObj);

    // return primaryCategories;
  }

  const handleChange = (event) => {
    let stateNameList = [];
    // console.log(event.target.value);
    const stateNameCodeList =
      countryStateJSON[event.target.value.split(",")[0]];
    // if (stateNameCodeList !== undefined) {
    //   for (let i = 0; i < stateNameCodeList.length; i++) {
    //     stateNameList.push(stateNameCodeList[i]["name"]+",");
    //   }
    // }
    //console.log(stateNameCodeList);
    setStateData(stateNameCodeList);
  };

  const handleCategoryChange = (event) => {
    // console.log(event.target.value);
    // setSelectedPrimaryCategory(event.target.value);

    const selectedCategory = event.target.value;
    setSelectedPrimaryCategory(selectedCategory);
    let categories = omit(
      combinedCategoryFactorsObj[event.target.value],
      "average_emission_factor"
    );
    // delete categories["average_emission_factor"];
    setSecondaryCategories(Object.keys(categories));
    // console.log("categories", categories);
  };

  // Fetch primary categories when the component mounts
  useEffect(() => {
    fetchCategories();
  }, []); // Empty dependency array to run only once

  // // Update secondary categories whenever the selected primary category changes
  // useEffect(() => {
  //   if (selectedPrimaryCategory) {
  //     const categories = combinedCategoryFactorsObj[selectedPrimaryCategory];
  //     const secondaryCatList = categories ? Object.keys(categories) : [];
  //     setSecondaryCategories(secondaryCatList);
  //   }
  // }, [selectedPrimaryCategory, combinedCategoryFactorsObj]); // Dependency array

  return (
    <>
      <BusinessDetailsWizardInput
        label="Organisation name *"
        name="organisation"
        placeholder="So we know who is changing the world!"
        errors={errors}
        formGroupProps={{ className: "mb-3" }}
        formControlProps={{
          ...register("organisation", {
            required: "Organisation name is requred",
          }),
        }}
      />

      <BusinessDetailsWizardInput
        type="selectCountry"
        label="Country *"
        name="country"
        handleChange={handleChange}
        placeholder="Please select a country"
        errors={errors}
        options={countryData}
        setValue={setValue}
        formGroupProps={{
          className: "mb-3",
        }}
        formControlProps={{
          ...register("country", {
            required: "Select a country",
          }),
        }}
      />

      <BusinessDetailsWizardInput
        type="selectState"
        label="State *"
        name="state"
        placeholder="Please select a state"
        errors={errors}
        disable={stateData && stateData?.length === 0 ? true : false}
        options={stateData}
        formGroupProps={{
          className: "mb-3",
        }}
        formControlProps={{
          ...register("state", {
            required: "Select a state",
          }),
        }}
      />

      <BusinessDetailsWizardInput
        type="select"
        label="Number of employees *"
        name="numberOfEmployees"
        placeholder="Please select a range"
        errors={errors}
        options={["1-5", "5-20", "20-50", "50-100", "100+"]}
        formGroupProps={{
          className: "mb-3",
        }}
        formControlProps={{
          ...register("numberOfEmployees", {
            required: "Select a range",
          }),
        }}
      />
      {/* 
      <BusinessDetailsWizardInput
        type="select"
        label="Industry fields *"
        name="industryFields"
        placeholder="Please select a category. Select other if none apply."
        errors={errors}
        options={Industries}
        formGroupProps={{
          className: "mb-3",
        }}
        formControlProps={{
          ...register("industryFields", {
            required: "Select an industry",
          }),
        }}
      /> */}

      <BusinessDetailsWizardInput
        type="selectPrimaryCategory"
        label="Primary category *"
        name="primaryCategory"
        handleChange={handleCategoryChange}
        placeholder="Please select a category for your business."
        errors={errors}
        options={primaryCategories}
        setValue={setValue}
        formGroupProps={{
          className: "mb-3",
        }}
        formControlProps={{
          ...register("primaryCategory", {
            required: "Select a primary category",
          }),
        }}
      />

      <BusinessDetailsWizardInput
        type="selectSecondaryCategory"
        label="Secondary category *"
        name="secondaryCategory"
        placeholder="Please select a category for your business."
        errors={errors}
        options={secondaryCategories}
        formGroupProps={{
          className: "mb-3",
        }}
        formControlProps={{
          ...register("secondaryCategory", {
            required: "Select a secondary category",
          }),
        }}
      />
    </>
  );
};

Screen1.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
};

export default Screen1;
