import React, { useEffect, useState } from "react";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import { ToastContainer } from "react-toastify";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Col, Row, Spinner } from "react-bootstrap";
import IconButton from "../common/IconButton";
import Flex from "../common/Flex";
import { faEdit, faList, faUsers } from "@fortawesome/free-solid-svg-icons";
import { getAllWfhFormData } from "./wfhAPI";
import { getFormattedDate } from "../../helpers/utils";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";

const WfhAllFormData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allWfhForms, setAllWfhForms] = useState([]);

  const queryParams = new URLSearchParams(window.location.search);
  const formId = queryParams.get("wfhFormId");
  async function get(formId) {
    setIsLoading(true);
    const userWfhForms = await getAllWfhFormData(formId);
    setAllWfhForms(userWfhForms || []);
    setIsLoading(false);
  }
  useEffect(() => {
    get(formId);
  }, [formId]);

  const columns = [
    {
      accessor: "people_id",
      Header: "User Id",
      disableSortBy: true,
    },
    {
      accessor: "email",
      Header: "Email",
    },
    {
      accessor: "business_id",
      Header: "Business Id",
      disableSortBy: true,
    },
    {
      accessor: "state",
      Header: "State",
      disableSortBy: true,
    },
  ];

  return (
    <>
      {" "}
      <WidgetSectionTitle
        icon={faList}
        title="WFH Emissions calculator - User submitted"
        subtitle="List of users submitted."
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      <AdvanceTableWrapper
        columns={columns}
        data={allWfhForms}
        sortable
        pagination
        perPage={7}
        rowCount={allWfhForms.length}
      >
        <Card>
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  WFH Form - Users
                </h5>
              </Col>
              <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                {isLoading ? (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    style={{ color: "#f5803e" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <></>
                )}
              </Col>
              <Col xs={4} sm="auto" className="ms-auto text-end ps-0"></Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="btn-reveal-trigger text-nowrap align-middle"
              tableProps={{
                size: "lg",
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={allWfhForms.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      <ToastContainer />
    </>
  );
};

export default WfhAllFormData;
