import React, { useEffect, createContext } from "react";
import {
  Spinner,
  Col,
  Row,
  Modal,
  Button,
  DropdownButton,
  Dropdown,
  Card,
  OverlayTrigger,
  Tooltip,
  ProgressBar,
} from "react-bootstrap";
import { useState } from "react";
import { filter, groupBy, map, sumBy } from "lodash";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  writeBatch,
} from "firebase/firestore";
import app from "../../firebase";
import AdvanceTableSearchBox from "../common/advance-table/AdvanceTableSearchBox";
import SoftBadge from "../common/SoftBadge";

import Flex from "../common/Flex";
import CountUp from "react-countup";
import { BsInfoCircle } from "react-icons/bs";
import { toast } from "react-toastify";
import {
  getActiveUserData,
  getUserData,
  isDataFrozen,
  isImpersonationModeEnabled,
  setActiveUserData,
} from "../../helpers/store";
import {
  fetchDashboardDataFromFirebaseByBusinessId,
  getUnifiedDateFromStore,
  removeUnifiedDateFromStore,
} from "../../helpers/unifiedDataHelper";
import {
  fetchContactFromFirebaseById,
  fetchSupplierFromFirebaseById,
  groupUnifiedData,
  fetchTransparencyAssessmentBySupplierId,
  fetchNetnadaFactorsFromMongoDB,
} from "../../helpers/suppliersHelper";
import { MdEdit } from "react-icons/md";
import { BsFillSendArrowUpFill } from "react-icons/bs";
import TransparencyScoreSendModal from "./TransparencyScoreSendModal";

import { BsFillSendCheckFill } from "react-icons/bs";
import { TbProgressCheck } from "react-icons/tb";
import { IoCheckmarkDone } from "react-icons/io5";
import { formatDateToYYYYMMDD } from "../../helpers/utils";

import {
  deleteLinesGroupedUnifiedData,
  updateGroupedUnifiedData,
} from "../../helpers/groupedUnifiedDataHelper";
import SupplierDetailModal from "../suppliers/SupplierDetailModal";
import EditEmissionCategoryModal from "../suppliers/EditEmissionCategoryModal";
import FrozenDataModal from "../utilities/FrozenDataModal";

//To measure loading time

const fireStoreDB = getFirestore(app);
export const EmissionsContext = createContext();

const EmissionsBySupplier = ({
  display,
  startDate,
  endDate,
  emissionsByCategory,
  setEmissionsByCategory,
  onDataChange,
}) => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [unifiedDataCalCompleted] = useState(
    JSON.parse(sessionStorage.getItem("unifiedDataCalCompleted"))
  );
  const [isLoading, setIsLoading] = useState(true);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showTransparencyScoreModal, setShowTransparencyScoreModal] =
    useState(false);
  const [editUnifiedData, setEditUnifiedData] = useState(null);
  const [showCalculationsModal, setShowCalculationsModal] = useState(false);
  const [primaryCategories, setPrimaryCategories] = useState([]);
  const [combinedCategoryFactorsObj, setCombinedCategoryFactorsObj] = useState(
    {}
  );
  const [reviewCount, setReviewCount] = useState(0);
  const [confidentCount, setConfidentCount] = useState(0);

  const [contactData, setContactData] = useState({});
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showFrozenDataModal, setShowFrozenDataModal] = useState(false);

  async function updateChartData() {
    await removeUnifiedDateFromStore();
    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      startDate,
      endDate
    );

    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);

    toast.success("Data updated successfully");
  }

  async function handleExclusion(unifiedData) {
    // console.time("Exclusion");
    setShowLoadingModal(true);
    const userData = JSON.parse(getActiveUserData());
    let updatedBy = userData.displayName;
    if (isImpersonationModeEnabled()) {
      const parentUserData = JSON.parse(getUserData());
      updatedBy = parentUserData.displayName;
    }

    const startDateString = `${formatDateToYYYYMMDD(
      new Date(startDate)
    )}T00:00:00.000Z`;
    const endDateString = `${formatDateToYYYYMMDD(
      new Date(endDate)
    )}T00:00:00.000Z`;
    try {
      //fetch all the data lines for the supplier
      const qry = query(
        collection(
          fireStoreDB,
          "UnifiedData",
          activeUser.businessId,
          "DataLines"
        ),
        where("contactName", "==", unifiedData.contactName),
        where("inclusionStatus", "==", "INCLUDED"),
        where("date", ">=", startDateString),
        where("date", "<=", endDateString)
      );

      const querySnapshot = await getDocs(qry);
      const unifiedDataList = [];

      // Batched updates for updating inclusion status to EXCLUDED
      const batch = writeBatch(fireStoreDB);

      querySnapshot.forEach(async (queryDoc) => {
        const unifiedDataDocRef = doc(
          fireStoreDB,
          "UnifiedData",
          activeUser.businessId,
          "DataLines",
          queryDoc.id
        );

        //add to unifiedDataList
        unifiedDataList.push(queryDoc.data());

        // Update the inclusion status to EXCLUDED
        await updateDoc(unifiedDataDocRef, {
          inclusionStatus: "EXCLUDED",
          reason: "Excluded by " + updatedBy,
        });
      });

      await batch.commit();

      // console.log("Exclusion completed");

      //group unified data
      // console.time("Grouping");
      const groupedUnifiedDataList = await groupUnifiedData(unifiedDataList);
      // console.timeEnd("Grouping");
      // console.log("groupedUnifiedDataList=>", groupedUnifiedDataList);

      // Update grouped unified data for each item in groupedUnifiedDataList
      // console.time("Updating Grouped Unified Data");
      for (const groupedUnifiedData of groupedUnifiedDataList) {
        //update grouped unified data

        await deleteLinesGroupedUnifiedData(groupedUnifiedData);
      }
      // console.timeEnd("Updating Grouped Unified Data");

      if (unifiedDataCalCompleted) {
        await updateChartData();
      }
      setShowLoadingModal(false);
      handleEditClose(true);
    } catch (error) {
      console.log(error);
    }
    // console.timeEnd("Exclusion");
  }

  // change the categoryMatchingSource for this row to MANUAL
  async function handleApprove(unifiedData) {
    try {
      //fetch all the data lines for the supplier
      const qry = query(
        collection(
          fireStoreDB,
          "UnifiedData",
          activeUser.businessId,
          "DataLines"
        ),
        where("contactName", "==", unifiedData.contactName),
        where("inclusionStatus", "==", "INCLUDED")
      );

      const querySnapshot = await getDocs(qry);
      querySnapshot.forEach(async (queryDoc) => {
        // console.log(queryDoc.id, "=>", queryDoc.data());

        const unifiedDataDocRef = doc(
          fireStoreDB,
          "UnifiedData",
          activeUser.businessId,
          "DataLines",
          queryDoc.id
        );

        await updateDoc(unifiedDataDocRef, {
          categoryMatchingSource: "MANUAL",
        });
      });

      let unifiedDataList = [];

      const updatedUnifiedDataQuery = query(
        collection(
          fireStoreDB,
          "UnifiedData",
          activeUser.businessId,
          "DataLines"
        ),
        where("contactName", "==", unifiedData.contactName),
        where("inclusionStatus", "==", "INCLUDED")
      );
      const updatedUnifiedDataQuerySnapshot = await getDocs(
        updatedUnifiedDataQuery
      );

      for (const doc of updatedUnifiedDataQuerySnapshot.docs) {
        const data = doc.data();
        unifiedDataList.push(data);
      }

      const groupedData = await groupUnifiedData(unifiedDataList);

      await updateGroupedUnifiedData(groupedData);

      if (unifiedDataCalCompleted) {
        await updateChartData();
      }
    } catch (error) {
      console.log(error);
    }
    // await getUnifiedData();
    await fetchAndMergeGroupedUnifiedDataFromCacheAndFirebase();
  }

  async function handleOpenCalculationsModal(supplierData) {
    setContactData(supplierData);
    setShowCalculationsModal(true);

    // TODO: DELETE THESE
    // console.log("contact data in handle Open =>", contactData);
    // setContactId(supplierData.contactId);
  }

  async function handleCloseCalculationsModal(isRefresh) {
    // setCurrentPage(0);
    setShowCalculationsModal(false);
    if (isRefresh) {
      await fetchAndMergeGroupedUnifiedDataFromCacheAndFirebase();
      // await getUnifiedData();
    }
  }

  const handleOpenTransparencyScoreModal = (contactData) => {
    setContactData(contactData);
    setShowTransparencyScoreModal(true);
  };

  const columns = [
    {
      accessor: "contactName",
      Header: "Contact name",
      cellProps: {
        className: "text-wrap",
      },
      Cell: (rowData) => {
        const contactName = rowData.row.original.contactName;
        const website = rowData.row.original.supplier?.website || "";
        const merchantLogo = rowData.row.original.supplier?.merchantLogo || "";
        const initialFavicon = website + "/favicon.ico";

        const [faviconExists, setFaviconExists] = React.useState(false);
        const [merchantLogoExists, setMerchantLogoExists] =
          React.useState(false);

        const handleFaviconLoad = () => {
          setFaviconExists(true);
        };

        const handleFaviconError = () => {
          setFaviconExists(false);
        };

        const handleMerchantLogoLoad = () => {
          setMerchantLogoExists(true);
        };

        const handleMerchantLogoError = () => {
          setMerchantLogoExists(false);
        };

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={merchantLogo}
              alt="logo"
              width={20}
              height={20}
              onError={handleMerchantLogoError}
              onLoad={handleMerchantLogoLoad}
              style={{
                marginRight: "10px",
                display: merchantLogoExists ? "inline" : "none",
              }}
            />
            {!merchantLogoExists && website !== null && website !== "" && (
              <img
                src={initialFavicon}
                alt="favicon"
                width={20}
                height={20}
                onError={handleFaviconError}
                onLoad={handleFaviconLoad}
                style={{
                  marginRight: "10px",
                  display: faviconExists ? "inline" : "none",
                }}
              />
            )}
            {!faviconExists && !merchantLogoExists && (
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                  backgroundColor: "#ff9418",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                }}
              >
                {contactName[0]?.toUpperCase()}
              </div>
            )}

            <a
              href="#"
              // onClick={() => handleOpenSupplierByCategory(primaryCategory)}
              // Open the edit ctaegory modal
              onClick={() => handleOpenCalculationsModal(rowData.row.original)}
            >
              {contactName}
            </a>
          </div>
        );
      },
    },
    {
      accessor: "primaryCategory",
      // Header: "Primary Category",
      Header: "Category",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "fw-semi-bold",
      },
      Cell: (rowData) => {
        const row = rowData.row.original;
        const { primaryCategory } = row;

        // console.log("row=>", row);

        return (
          <a
            href="#"
            // onClick={() => handleOpenSupplierByCategory(primaryCategory)}
            // Open the edit ctaegory modal
            onClick={() => {
              if (isDataFrozen()) {
                setShowFrozenDataModal(true);
                return;
              }
              handleEditOpen(rowData.row.original);
            }}
          >
            {primaryCategory}
          </a>
        );
      },
    },
    {
      accessor: "totalEmissions",
      Header: "Emissions (tonnes/CO₂e)",
      sortType: "basic",
      Cell: (rowData) => {
        const totalEmissions = rowData.row.original.totalEmissions;
        const lessThanOne = "<0.1";
        if (totalEmissions < 0.1) {
          return (
            <>
              {lessThanOne}{" "}
              <BsInfoCircle
                style={{ color: "#2D7FDE" }}
                onClick={() =>
                  handleOpenCalculationsModal(rowData.row.original)
                }
              />
            </>
          );
        } else {
          return (
            <>
              {parseFloat(rowData.row.original.totalEmissions?.toFixed(1))}{" "}
              <BsInfoCircle
                style={{ color: "#2D7FDE" }}
                onClick={() =>
                  handleOpenCalculationsModal(rowData.row.original)
                }
              />
            </>
          );
        }
      },
      cellProps: {
        className: "align-middle text-center",
      },
      headerProps: {
        className: "text-center",
        style: { minWidth: "150px" },
      },
    },
    {
      accessor: "confidenceString",
      Header: "Confidence",
      sortType: "basic",
      Cell: (rowData) => {
        const myScore = rowData.row.original.score?.toFixed(6);
        const source = rowData.row.original.categoryMatchingSource;
        if (source === "FIREBASE") {
          return <SoftBadge bg={"success"}>Confident</SoftBadge>;
        } else if (source === "DIRECT") {
          return <SoftBadge bg={"success"}>Confident</SoftBadge>;
        } else if (source === "LOOKWHOISCHARGING") {
          return <SoftBadge bg={"success"}>Confident</SoftBadge>;
        } else if (source === "MANUAL") {
          return <SoftBadge bg={"success"}>Confident</SoftBadge>;
        } else if (myScore >= 0.99) {
          return <SoftBadge bg={"success"}>Confident</SoftBadge>;
        } else if (myScore < 0.99) {
          return <SoftBadge bg={"warning"}>Needs Review</SoftBadge>;
        } else {
          return <SoftBadge bg={"warning"}>Needs Review</SoftBadge>;
        }
        // return <SoftBadge bg={rowData.row.original.categoryMatchingSource === "ML" ? "success" : "warning"}>{rowData.row.original.categoryMatchingSource}</SoftBadge>;
      },
    },
    {
      accessor: "",
      Header: "Action",
      Cell: (rowData) => {
        const row = rowData.row.original;
        const confidenceString = rowData.row.original.confidenceString;
        return (
          <>
            <DropdownButton
              id={`dropdown-button-drop-start`} // Use a unique id for each dropdown if they are being mapped
              title={<MdEdit color={"dimgray"} />}
              variant="falcon-default"
              drop="start"
            >
              {/* <Dropdown.ItemText>Dropdown item text</Dropdown.ItemText> */}
              <Dropdown.Item
                as="button"
                onClick={() => {
                  if (isDataFrozen()) {
                    setShowFrozenDataModal(true);
                    return;
                  }
                  handleEditOpen(rowData.row.original);
                }}
              >
                Change Categories
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger"
                as="button"
                onClick={() => {
                  if (isDataFrozen()) {
                    setShowFrozenDataModal(true);
                    return;
                  }
                  handleExclusion(rowData.row.original);
                }}
              >
                Exclude Supplier
              </Dropdown.Item>
              {confidenceString === "Needs Review" && (
                <Dropdown.Item as="button" onClick={() => handleApprove(row)}>
                  Approve
                </Dropdown.Item>
              )}
            </DropdownButton>
            {/* {confidenceString === "Needs Review" && (
              <Button
                variant="falcon-default"
                size="sm"
                icon={faCheck}
                onClick={() => handleApprove(row)}
                transform="shrink-3"
              >
                Approve
              </Button>
            )} */}
          </>
        );
      },
    },
    {
      accessor: "transparencyAssessment.scoreForSorting",
      Header: "Transparency Score",
      cellProps: {
        className: "align-middle text-center",
      },
      headerProps: {
        className: "text-center",
        style: { minWidth: "150px" },
      },
      Cell: (rowData) => {
        const row = rowData.row.original;
        const transparencyAssessment = row.transparencyAssessment;
        let latestTransparencyAssessment;
        if (transparencyAssessment && transparencyAssessment.surveys) {
          latestTransparencyAssessment = transparencyAssessment.surveys
            .slice()
            .sort((a, b) => b.reportingYear.localeCompare(a.reportingYear))[0];
        }

        // Condition to handle data from "Bill"
        const isBillDataSource = false;

        const getTooltipMessage = () => {
          if (isBillDataSource) {
            return "Engagement not possible with Bill's information at the moment";
          } else if (transparencyAssessment === null) {
            return "Send Survey";
          } else {
            switch (latestTransparencyAssessment.status) {
              case "SENT":
                return "Sent";
              case "REGISTERED":
                return "Registered";
              case "SUBMITTED":
                return "Submitted";
              case "DRAFTED":
                return "Drafted";
              case "PENDING":
                return "Pending";
              default:
                return "Send Survey";
            }
          }
        };

        const iconContainerStyle = {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
          height: "100%",
        };

        let getIconStyles = () => ({});

        if (transparencyAssessment !== null) {
          // const lastItem = transparencyAssessment.surveys.length - 1;
          // const status = transparencyAssessment.surveys[lastItem].status;

          getIconStyles = (iconOrder) => {
            let iconColor = "#6c757d"; // Darker grey for icons
            let bgColor = "#e9ecef"; // Light grey for background

            if (
              latestTransparencyAssessment.status === "SENT" &&
              iconOrder === 1
            ) {
              iconColor = "green";
              bgColor = "lightgreen";
            } else if (
              ["DRAFTED", "REGISTERED", "PENDING"].includes(
                latestTransparencyAssessment.status
              ) &&
              iconOrder <= 2
            ) {
              iconColor = "green";
              bgColor = "lightgreen";
            } else if (latestTransparencyAssessment.status === "SUBMITTED") {
              iconColor = "green";
              bgColor = "lightgreen";
            }

            return {
              color: iconColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "30px",
              height: "30px",
              backgroundColor: bgColor,
              borderRadius: "50%",
            };
          };
        }

        return (
          <>
            {latestTransparencyAssessment?.score &&
            latestTransparencyAssessment?.score !== 0 ? (
              <Flex alignItems="center">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <ProgressBar
                    now={latestTransparencyAssessment.score}
                    style={{ width: "80px", height: 5 }}
                  />
                  <div
                    className="fw-semi-bold ms-3"
                    // style={{ fontSize: "1.05rem" }}
                  >
                    {latestTransparencyAssessment.score}
                  </div>
                </div>
              </Flex>
            ) : (
              // <div>{latestTransparencyAssessment.score}</div>
              <OverlayTrigger
                overlay={
                  <Tooltip
                    style={{ position: "fixed" }}
                    id="overlay-trigger-example"
                  >
                    {getTooltipMessage()}
                  </Tooltip>
                }
              >
                {isBillDataSource ? (
                  <div style={iconContainerStyle}>
                    <BsInfoCircle
                      style={{ fontSize: "20px", color: "dimgray" }}
                    />
                  </div>
                ) : transparencyAssessment === null ||
                  latestTransparencyAssessment.status === "NOTSENT" ? (
                  <Button
                    variant="falcon-primary"
                    className="me-2 mb-1"
                    onClick={() => {
                      handleOpenTransparencyScoreModal(row);
                    }}
                  >
                    <BsFillSendArrowUpFill style={{ color: "dimgray" }} />
                  </Button>
                ) : (
                  <div style={iconContainerStyle}>
                    <div style={getIconStyles(1)}>
                      <BsFillSendCheckFill />
                    </div>
                    <div style={getIconStyles(2)}>
                      <TbProgressCheck />
                    </div>
                    <div style={getIconStyles(3)}>
                      <IoCheckmarkDone />
                    </div>
                  </div>
                )}
              </OverlayTrigger>
            )}
          </>
        );
      },

      // Cell: ({ rowData }) => (
      //   <TransparencyScoreCellTable
      //     key={rowData.row.original.contactId}
      //     rowData={rowData}
      //     onClick={handleOpenTransparencyScoreModal}
      //   />
      // ),
    },
    // {
    //   accessor: "Approve",
    //   Header: "Approve",
    //   Cell: (rowData) => {
    //     const row = rowData.row.original;
    //     const categoryMatchingSource = row.categoryMatchingSource;
    //     const confidenceString = rowData.row.original.confidenceString;

    //     return confidenceString === "Needs Review" ? (
    //       <Button variant="falcon-default" size="sm" icon={faCheck} onClick={() => handleApprove(row)} transform="shrink-3">
    //         Approve
    //       </Button>
    //     ) : null;
    //   },
    // },
  ];

  const stats = [
    {
      title: "Total Suppliers",
      value: reviewCount + confidentCount,
      color: "secondary",
    },
    {
      title: "Confident",
      value: confidentCount,
      color: "success",
    },
    {
      title: "Needs Review",
      value: reviewCount,
      color: "warning",
    },
  ];

  function handleEditOpen(unifiedData) {
    // console.log("unifiedData=>", unifiedData);
    setEditUnifiedData(unifiedData);
    setShowEditModal(true);
  }

  async function handleEditClose(isRefresh) {
    // console.log("Inside handleEditClose()", isRefresh)
    // setCurrentPage(0);
    setShowEditModal(false);
    if (isRefresh) {
      await fetchAndMergeGroupedUnifiedDataFromCacheAndFirebase();
    }
  }

  function getMostRepeatedCategories(dataEntries) {
    const categoryCounts = dataEntries.reduce((acc, entry) => {
      const key = `${entry.primaryCategory};${entry.secondaryCategory}`;
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});

    const sortedCategories = Object.keys(categoryCounts).sort(
      (a, b) => categoryCounts[b] - categoryCounts[a]
    );

    const mostCommon = sortedCategories[0] || "";
    const [primary, secondary] = mostCommon.split(";");

    return { primary, secondary };
  }

  function updateDashboardOnDateConditions(
    startDate,
    endDate,
    yearType,
    dashboardInfo
  ) {
    const activeUser = JSON.parse(getActiveUserData());
    const isStartJanFirst =
      startDate.getDate() === 1 && startDate.getMonth() === 0; // January is 0
    const isEndDecThirtyFirst =
      endDate.getDate() === 31 && endDate.getMonth() === 11; // December is 11
    const isStartJulyFirst =
      startDate.getDate() === 1 && startDate.getMonth() === 6; // July is 6
    const isEndJuneThirty =
      endDate.getDate() === 30 && endDate.getMonth() === 5; // June is 5

    if (activeUser?.supplyDashbboard) {
      onDataChange(activeUser?.supplyDashbboard);
    }
    if (yearType === "calendar" && isStartJanFirst && isEndDecThirtyFirst) {
      onDataChange(dashboardInfo);
      activeUser.supplyDashbboard = dashboardInfo;
      setActiveUserData(JSON.stringify(activeUser));
    } else if (
      yearType === "financial" &&
      isStartJulyFirst &&
      isEndJuneThirty
    ) {
      onDataChange(dashboardInfo);
      activeUser.supplyDashbboard = dashboardInfo;
      setActiveUserData(JSON.stringify(activeUser));
    }
  }

  function getCategoryConfidenceMatchDetails(categoryMatchingSource, score) {
    let incrementConfidentCount = 0;
    let incrementReviewCount = 0;
    let confidenceString = "";

    if (
      ["FIREBASE", "DIRECT", "LOOKWHOISCHARGING", "MANUAL"].includes(
        categoryMatchingSource
      ) ||
      score >= 0.99
    ) {
      incrementConfidentCount = 1;
      confidenceString = "Confident";
    } else if (score < 0.99) {
      confidenceString = "Needs Review";
      incrementReviewCount = 1;
    } else {
      confidenceString = "Needs Review";
      incrementReviewCount = 1;
    }

    return { incrementConfidentCount, incrementReviewCount, confidenceString };
  }

  const fetchAndMergeGroupedUnifiedDataFromCacheAndFirebase = async () => {
    // setShowLoadingModal(true);
    const activeUser = JSON.parse(getActiveUserData());
    const startDateString = `${formatDateToYYYYMMDD(new Date(startDate))}`;
    const endDateString = `${formatDateToYYYYMMDD(new Date(endDate))}`;
    let tempReviewCount = 0;
    let tempConfidentCount = 0;
    let dashboardInfo = {
      totalEmissions: 0,
      surveysSent: 0,
      surveysSubmitted: 0,
      averageScore: 0,
      totalScore: 0, // New field to keep track of the total score
      scoredAssessmentsCount: 0, // New field to count assessments that have a score
    };

    const fullData = await getUnifiedDateFromStore();

    let data = filter(fullData, (item) => {
      return (
        item.date >= startDateString &&
        item.date <= endDateString &&
        item.contactId !== ""
      );
    });
    // console.log("STORED DATA=>", data);
    const groupedData = groupBy(data, "contactName");
    // console.log("grouped Stored Data=>", groupedData);

    // const allTransparencyAssessments = await fetchAllTransparencyAssessmentsWithLatestSurvey()
    // console.log("All transparencyAssesmnets",transparencyAssessments)

    const groupedMergedData = await Promise.all(
      map(groupedData, async function (val) {
        const accountName = val[0]?.accountName;
        const contactId = val[0]?.contactId;
        const contactName = val[0]?.contactName;
        const dataSource = val[0]?.dataSource;
        const officeId = val[0]?.officeId;
        // console.log("contactId=>", contactId);
        // console.log("contactName=>", contactName);
        // console.log("dataSource=>", dataSource);
        // console.log("emissionFactorId=>", emissionFactorId);

        //Dashboard total Score

        val.forEach(
          (dataEntry) =>
            (dashboardInfo.totalEmissions += dataEntry.totalEmissions)
        );

        //fetch website
        let firebaseSupplier = null;
        let myContactData = null;
        let transparencyAssessment = null;
        // if (dataSource !== "Bill") {
        if (contactId !== "") {
          myContactData = await fetchContactFromFirebaseById(
            activeUser.businessId,
            dataSource,
            contactId
          );
          // console.log("myContactData=>", myContactData);

          const supplierId = myContactData?.supplierId;
          // console.log("supplierId=>", supplierId);

          if (supplierId) {
            firebaseSupplier = await fetchSupplierFromFirebaseById(supplierId);
            // console.log("firebaseSupplier=>", firebaseSupplier);
            //Also status needs review
            if (firebaseSupplier?.status === "approved") {
              // console.log("firebaseSupplier=>", firebaseSupplier);
              transparencyAssessment =
                await fetchTransparencyAssessmentBySupplierId(supplierId);
              // console.log("transparencyAssessment=>", transparencyAssessment);
              // let reportingYearAssessment =
              //   transparencyAssessment?.surveys?.find(
              //     (survey) =>
              //       survey.reportingYear ===
              //         activeUser.selectedMeasurementYear &&
              //       survey.reportingType ===
              //         activeUser.selectedMeasurementYearType
              //   );
              //We pick the latest survey for the supplier
              let reportingYearAssessment = null;
              if (transparencyAssessment && transparencyAssessment.surveys) {
                reportingYearAssessment = transparencyAssessment.surveys
                  .slice()
                  .sort((a, b) =>
                    b.reportingYear.localeCompare(a.reportingYear)
                  )[0];
              }

              if (reportingYearAssessment) {
                // console.log("Assessment Found =>", reportingYearAssessment);
                transparencyAssessment.scoreForSorting =
                  reportingYearAssessment.score;
                if (reportingYearAssessment.status !== "NOTSENT") {
                  dashboardInfo.surveysSent++;
                  if (reportingYearAssessment.status === "SUBMITTED") {
                    dashboardInfo.surveysSubmitted++;
                  }
                }
                if (reportingYearAssessment.score !== 0) {
                  dashboardInfo.totalScore += +reportingYearAssessment.score;
                  dashboardInfo.scoredAssessmentsCount++;
                }
              }
              //
            }
          }
        }
        // }

        const { primary, secondary } = getMostRepeatedCategories(val);
        const {
          incrementConfidentCount,
          incrementReviewCount,
          confidenceString,
        } = getCategoryConfidenceMatchDetails(
          val[0]?.categoryMatchingSource,
          val[0]?.score
        );
        tempConfidentCount += incrementConfidentCount;
        tempReviewCount += incrementReviewCount;

        return {
          accountName: accountName,
          contactId: contactId,
          contactName: contactName,
          dataSource: dataSource,
          primaryCategory: primary,
          secondaryCategory: secondary,
          totalEmissions: sumBy(val, "totalEmissions"),
          categoryMatchingSource: val[0]?.categoryMatchingSource,
          confidenceString: confidenceString,
          score: val[0]?.score,
          supplier: firebaseSupplier,
          contact: myContactData,
          officeId: officeId,
          transparencyAssessment: transparencyAssessment,
        };
      })
    );

    if (dashboardInfo.scoredAssessmentsCount > 0) {
      dashboardInfo.averageScore =
        dashboardInfo.totalScore / dashboardInfo.scoredAssessmentsCount;
    }
    // console.log("Dashboard Data", dashboardInfo);

    updateDashboardOnDateConditions(
      new Date(startDate),
      new Date(endDate),
      activeUser.selectedMeasurementYearType,
      dashboardInfo
    );

    setReviewCount(tempReviewCount);
    setConfidentCount(tempConfidentCount);
    // console.log(`There are ${tempReviewCount} lines with score less than 0.99`);
    // console.log(`There are ${tempConfidentCount} lines with score greater than 0.99`);

    // console.log("groupedMergedData=>", groupedMergedData);
    //To display top 10 suppliers
    // console.log(display);
    if (display !== undefined) {
      setEmissionsByCategory(
        groupedMergedData
          .sort((a, b) => b.totalEmissions - a.totalEmissions)
          .slice(0, display)
      );
      // setShowLoadingModal(false);
    } else {
      setEmissionsByCategory(
        groupedMergedData.sort((a, b) => b.totalEmissions - a.totalEmissions)
      );
      // setShowLoadingModal(false);
    }
  };

  // useEffect(() => {
  //   if (emissionsByCategory) {
  //     const endTime = performance.now();
  //     console.log(`Time to last render: ${endTime - startTime}ms`);
  //   }
  // }, [emissionsByCategory]); // This effect runs only when 'emissionsByCategory' changes

  // async function fetchNetnadaFactorsFromMongoDB() {
  //   const targetUrl = `https://netnada-factor-production-8ed52ccdfd5e.herokuapp.com/factors/allGroupByCategory`;
  //   try {
  //     const response = await fetch(targetUrl, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "x-api-key": process.env.REACT_APP_NETNADA_API_KEY,
  //       },
  //     });
  //     if (!response.ok) {
  //       throw new Error("Something went wrong");
  //     }

  //     const data = await response.json();
  //     // console.log("data", data);

  //     return data;
  //   } catch (error) {
  //     console.error("Failed to fetch emission factors:", error);
  //     return null;
  //   }
  // }

  async function get() {
    setShowLoadingModal(true);
    setIsLoading(true);

    // await getUnifiedData();
    await fetchAndMergeGroupedUnifiedDataFromCacheAndFirebase();

    let data = await fetchNetnadaFactorsFromMongoDB();

    // console.log("data=>", data);
    // console.log("data=>", data);

    //omit the category_name whose activities array has no item with unit as '$', except for category_name="Electricity" and "Stationary energy (gaseous fuels)"
    const filteredData = data.filter((item) => {
      if (
        item.category_name !== "Electricity" &&
        item.category_name !== "Stationary energy (gaseous fuels)"
      ) {
        const activities = item.activities;
        // Check if activities array has no item with unit as '$'
        const hasDollar = activities.some((activity) => activity.unit === "$");
        return hasDollar;
      }
      return true; // Keep "Electricity" and "Stationary energy (gaseous fuels)"
    });
    // console.log("filteredData=>", filteredData);

    //fetch all the primary and secondary categories
    let primaryCategories = [];
    let combinedCategoryFactorsObj = {};
    for (let i = 0; i < filteredData.length; i++) {
      let factorObject = filteredData[i];
      let primaryCategory = factorObject.category_name;
      primaryCategories.push(primaryCategory);
      //omit the activities whose reference is "CA old" and default_scope is "SCOPE2"
      // factorObject["activities"] = factorObject["activities"].filter(
      //   (item) => item.reference !== "CA old" && item.default_scope !== "SCOPE2"
      // );

      combinedCategoryFactorsObj[primaryCategory] = factorObject["activities"];
    }
    // console.log("primaryCategories=>", primaryCategories);
    // console.log("combinedCategoryFactorsObj=>", combinedCategoryFactorsObj);

    setPrimaryCategories(primaryCategories);
    setCombinedCategoryFactorsObj(combinedCategoryFactorsObj);

    setIsLoading(false);
    setShowLoadingModal(false);
  }

  useEffect(() => {
    get();
  }, [activeUser, display, startDate, endDate]);

  const noDataMessages = isLoading
    ? {
        title: " ",
        subtitle: " ",
      }
    : {
        title: "No Supplier Found",
        subtitle: "Try searching again or expanding the range of your filters",
      };

  return (
    <>
      <EmissionsContext.Provider
        // value={{ getUnifiedData, updateChartData, get }}
        value={{
          fetchAndMergeGroupedUnifiedDataFromCacheAndFirebase,
          updateChartData,
          get,
        }}
      >
        {showFrozenDataModal && (
          <FrozenDataModal
            show={showFrozenDataModal}
            onHide={() => {
              setShowFrozenDataModal(false);
            }}
          />
        )}
        {showLoadingModal && <LoadingModal show={showLoadingModal} />}

        {showEditModal && (
          <EditEmissionCategoryModal
            showEditModal={showEditModal}
            handleEditClose={handleEditClose}
            data={{
              businessId: activeUser.businessId,
              unifiedData: { ...editUnifiedData },
              primaryCategories: primaryCategories,
              combinedCategoryFactorsObj: combinedCategoryFactorsObj,
            }}
            updateChartData={updateChartData}
            startDate={startDate}
            endDate={endDate}
          />
        )}

        {showCalculationsModal && (
          <SupplierDetailModal
            showCalculationsModal={showCalculationsModal}
            handleCloseCalculationsModal={handleCloseCalculationsModal}
            contactData={contactData}
            data={{
              primaryCategories: primaryCategories,
              combinedCategoryFactorsObj: combinedCategoryFactorsObj,
              businessId: activeUser.businessId,
            }}
            startDate={startDate}
            endDate={endDate}
          />
        )}

        {showTransparencyScoreModal && (
          <TransparencyScoreSendModal
            show={showTransparencyScoreModal}
            onHide={() => setShowTransparencyScoreModal(false)}
            contactData={contactData}
          />
        )}

        <div className="mb-3">
          <AdvanceTableWrapper
            columns={columns}
            data={emissionsByCategory}
            sortable
            pagination
            perPage={10}
            rowCount={emissionsByCategory.length}
          >
            <Card>
              <Card.Header>
                <Row className="flex-start-center mb-1">
                  <Col xs={4} lg={6}>
                    <Row className="flex-start-center mb-1">
                      {stats.map((stat, index) => (
                        <Col xs={4} lg={4} key={index}>
                          <div className="position-relative ps-3">
                            <div
                              className={`position-absolute h-100 start-0 rounded bg-${stat.color}`}
                              style={{ width: "4px" }}
                            />
                            <h6 className="fs--2 text-600 mb-1">
                              {stat.title}
                            </h6>
                            <Flex alignItems="center">
                              <CountUp
                                start={0}
                                end={stat.value}
                                duration={2.75}
                                className="text-700 fs-2 me-2"
                                separator=","
                              />
                            </Flex>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col>
                    {isLoading && (
                      <div className="mb-3">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    )}
                  </Col>
                  <Col xs={6} lg={4}>
                    <AdvanceTableSearchBox table />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                {/* {currentPageDataLength ? ( */}
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="btn-reveal-trigger text-nowrap align-middle"
                  tableProps={{
                    size: "lg",
                    className: "fs--1 mb-0 overflow-hidden",
                  }}
                  noDataMessage={noDataMessages}
                />
                {/* ) : ( !isLoading && (
              <div className="text-center p-3">
                <h4>No Supplier found</h4>
                <h6>Try searching again or expanding the range of your filters</h6>
              </div>
              ) )} */}
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  data={emissionsByCategory}
                  rowCount={emissionsByCategory.length}
                  table
                  rowInfo
                  navButtons
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </div>
      </EmissionsContext.Provider>
    </>
  );
};

const LoadingModal = ({ show }) => {
  return (
    <Modal show={show} centered>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
          <div className="ms-3">
            <span className="small">
              One moment please, we're updating your information to ensure
              everything is up-to-date.
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EmissionsBySupplier;
