import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import BusinessDetailsWizardInput from "./BusinessDetailsWizardInput";
import { CountryRegionData } from "react-country-region-selector";

const Screen2 = ({ register, errors, setValue, getValues }) => {
  return (
    <>
      <BusinessDetailsWizardInput
        type="select"
        label="Do you measure your businesses carbon footprint? *"
        name="carbonFootprint"
        placeholder="Please select..."
        errors={errors}
        options={[
          "No, we have never tried",
          "Yes, but it is not done regularly",
          "Yes, we measure every year",
        ]}
        formGroupProps={{
          className: "mb-3",
        }}
        formControlProps={{
          ...register("carbonFootprint", {
            required: "Select an option",
          }),
        }}
      />
    </>
  );
};

Screen2.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
};
export default Screen2;
