import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthCardLayout from "../../../layouts/AuthCardLayout";
import {
  clearLoginData,
  getActiveUserData,
  isImpersonationModeEnabled,
  setActiveUserData,
  setIdToken,
  setInventoryProgress,
  setUserData,
} from "../../../helpers/store";
import { auth } from "../../../firebase";
import { Form } from "react-bootstrap";

const XeroCallback = () => {
  const navigate = useNavigate();
  const queryParams = useLocation().search;
  //console.log("queryParams=>", queryParams);
  //const code = new URLSearchParams(search).get("code");
  //console.log("code=>", code);

  useEffect(() => {
    !!queryParams && getToken(navigate, queryParams);
  }, [queryParams]);

  const getToken = async (navigate, queryParams) => {
    const response = await fetch("/api/xero/xero-token" + queryParams);
    if (response.status !== 200) {
      clearLoginData();
    }
    const data = await response.json();
    if (!data || !data.customToken)
      throw new Error("customToken should not be empty");

    if (!isImpersonationModeEnabled()) {
      await auth.signInWithCustomToken(data.customToken);
      const idToken = await auth.currentUser.getIdToken(true);
      setIdToken(idToken);
      setUserData(JSON.stringify(data.user));
      setInventoryProgress(JSON.stringify(data.inventoryProgress));
    } else {
      const userData = JSON.parse(getActiveUserData());
      userData.processingStatus = data.user.processingStatus;
      userData.authProvider = data.user.authProvider;
      setActiveUserData(JSON.stringify(userData));
    }
    const processingStatus = data.user.processingStatus;
    if (!processingStatus) {
      window.location.href = "/xero-account-list";
    } else {
      window.location.href = "/welcome";
    }
  };

  return (
    <AuthCardLayout>
      <Form.Group>
        <button class="btn btn-primary mt-3 w-100" type="button" disabled>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            style={{ marginRight: "10px" }}
          ></span>
          <span class="sr-only" style={{ marginLeft: "20px" }}>
            Xero login in progress...
          </span>
        </button>
      </Form.Group>
    </AuthCardLayout>
  );
};

export default XeroCallback;
