import { clearLoginData } from "../../helpers/store";
import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";

export const saveOrganisationDetails = async (data) => {
  try {
    const response = await fetch(
      "/api/organisationDetails/save-organisation-details",
      {
        method: "POST",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(data),
      }
    );

    const responseData = await response.json();

    if (response.status !== 201) {
      throw new Error(responseData.message || "Failed to save data.");
    }

    return {
      success: true,
      message: responseData.message,
      data: responseData.updatedData,
    };
  } catch (error) {
    console.error("There was an error:", error);
    return { success: false, message: error.message };
  }
};

export const updateOrganisationRevenue = async (data) => {
  try {
    const response = await fetch(
      "/api/organisationDetails/update-organisation-revenue",
      {
        method: "POST",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(data),
      }
    );

    const responseData = await response.json();

    if (response.status !== 201) {
      throw new Error(responseData.message || "Failed to save data.");
    }

    return {
      success: true,
      message: responseData.message,
      data: responseData.updatedData,
    };
  } catch (error) {
    console.error("There was an error:", error);
    return { success: false, message: error.message };
  }
};

export const updateOrganisationEmployeeNumber = async (data) => {
  try {
    const response = await fetch(
      "/api/organisationDetails/update-organisation-employee-number",
      {
        method: "POST",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(data),
      }
    );

    const responseData = await response.json();

    if (response.status !== 201) {
      throw new Error(responseData.message || "Failed to save data.");
    }

    return {
      success: true,
      message: responseData.message,
      data: responseData.updatedData,
    };
  } catch (error) {
    console.error("There was an error:", error);
    return { success: false, message: error.message };
  }
};

export const updateOrganisationTotalEmissions = async (data) => {
  try {
    const response = await fetch(
      "/api/organisationDetails/update-organisation-total-emissions",
      {
        method: "POST",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(data),
      }
    );

    const responseData = await response.json();

    if (response.status !== 201) {
      throw new Error(responseData.message || "Failed to save data.");
    }

    return {
      success: true,
      message: responseData.message,
      data: responseData.updatedData,
    };
  } catch (error) {
    console.error("There was an error:", error);
    return { success: false, message: error.message };
  }
};
