import React, { useContext, useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Card } from "react-bootstrap";
import Select from "react-select";
import { WaterContext } from "../../context/Context";
import { getActiveUserData } from "../../helpers/store";
// import DataDateFilter from "../../helpers/DataDateFilter";
import ReactDatePicker from "react-datepicker";
import { GreetingsDateInput } from "../../helpers/DataDateFilter";

//  THIS IS FOR WATER

function PageDateOfficeQuestion() {
  const { formData, setFormData, nextStep } = useContext(WaterContext);
  const [office, setOffice] = useState(formData.office || null);
  const [formName, setFormName] = useState(formData.formName || "");
  const [dateRange, setDateRange] = useState(
    formData.dateRange || [new Date(), new Date()]
  );
  const [activeUser, setActiveUser] = useState(JSON.parse(getActiveUserData()));
  const storedLocationsJSON = sessionStorage.getItem("userBusinessLocations");
  const locations = JSON.parse(storedLocationsJSON);

  const officeData = locations.map((office) => ({
    label: `${office.officename} (${office.location}, ${office.country})`,
    value: office.office_id,
    country: office.country,
    state: office.location,
  }));

  const getNextDay = (date) => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay;
  };
  const [selectedDates, setSelectedDates] = useState({
    startDate: formData.startDate ? new Date(formData.startDate) : new Date(),
    endDate: formData.endDate
      ? new Date(formData.endDate)
      : getNextDay(new Date()),
  });

  const handleFormNameChange = (event) => {
    setFormName(event.target.value);
  };

  const handleAddEntry = () => {
    // if (office && dateRange[0] && dateRange[1]) {
    if (
      office &&
      formName &&
      selectedDates.startDate &&
      selectedDates.endDate
    ) {
      setFormData({
        ...formData,
        formName,
        userId: activeUser.uid,
        office,
        startDate: selectedDates.startDate.toISOString().substring(0, 10),
        endDate: selectedDates.endDate.toISOString().substring(0, 10),
        update_date: new Date().toISOString().substring(0, 10),
      });
      // console.log("office", office);
      nextStep();
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setSelectedDates({
      startDate: start,
      endDate: end,
    });
  };

  return (
    <Container className="mt-5">
      <h3 className="mb-4 text-center">Water Emissions Calculator</h3>
      <p className="text-center mb-4">
        Please fill out the information below to start calculating the emissions
        for your water
      </p>
      <Form.Group as={Row} className="mb-4">
        <Col sm="12">
          <Form.Label column sm="4">
            Name your water submission:
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter a memorable name"
            value={formName}
            onChange={handleFormNameChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-4">
        <Col sm="6">
          <Form.Label>Choose an Office Location:</Form.Label>
          <Select
            value={office}
            onChange={setOffice}
            options={officeData}
            placeholder="Select office"
            classNamePrefix="react-select"
          />
        </Col>
        <Col sm="6">
          <Form.Label>Select a Date Range for Water Data:</Form.Label>
          <ReactDatePicker
            selected={selectedDates.startDate}
            onChange={onChange}
            selectsRange
            startDate={selectedDates.startDate}
            endDate={selectedDates.endDate}
            showMonthDropdown
            showYearDropdown
            dateFormat="dd/MM/yyyy"
            customInput={<GreetingsDateInput />}
          />
        </Col>
      </Form.Group>
      <Row>
        <Col className="d-flex justify-content-center">
          <Button variant="primary" onClick={handleAddEntry} className="mt-3">
            Continue
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default PageDateOfficeQuestion;
