import React, { useEffect, useState } from "react";
import EmissionsBySupplier from "./emissions-by-supplier";
import { getActiveUserData, setActiveUserData } from "../../helpers/store";
import PageHeader from "../common/PageHeader";
import DataDateFilter from "../../helpers/DataDateFilter";
import { isLocal, isProduction, isStaging } from "../../helpers/helper";
import TransparencyScoreDashboard from "./TransparencyScoreDashboard";
import { MdOutlineFactory, MdOutlineCalculate } from "react-icons/md";
import { BsFillSendArrowUpFill } from "react-icons/bs";
import { AiOutlineFileDone } from "react-icons/ai";
import { Button, Col, Row } from "react-bootstrap";
import SendSupplierDetailsModal from "./SendSupplierDetailsModal";
import { MdDownload } from "react-icons/md";
import EngageSuppliersModal from "../suppliers/EngageSuppliersModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
const BUSINESS_IDS = [
  "BusinesshvsLOYNnB9WhUAN39nNuLfClBs03",
  "Business7vCCFPHY6tUdUmAAHy16yDY4t5k2",
  "BusinessvqJIFlix55eESXaZLJB079KfTi42",
];
const SERVER = isProduction()
  ? "Production"
  : isStaging()
  ? "Staging"
  : isLocal()
  ? "Development"
  : null;
const SupplyChain = () => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [selectedDates, setSelectedDates] = useState({
    startDate: new Date(activeUser.currentStartDate),
    endDate: new Date(activeUser.currentEndDate),
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDescription, setShowDescription] = useState(false);
  const [emissionsByCategory, setEmissionsByCategory] = useState([]);
  const [showOld, setShowOld] = useState(false);
  const [supplierDetails, setSupplierDetails] = useState([]);
  const [showSendSupplierDetailsModal, setShowSendSupplierDetailsModal] =
    useState(false);
  const [showEngageSuppliersModal, setShowEngageSuppliersModal] =
    useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [suppliersEngaged, setSuppliersEngaged] = useState(
    activeUser.suppliersEngaged
  );
  const onChange = (dates) => {
    const [start, end] = dates;
    setSelectedDates({
      startDate: start,
      endDate: end,
    });
    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);
  };
  useEffect(() => {
    if (selectedDates.startDate !== null && selectedDates.endDate !== null) {
      //update user data in session storage
      let userData = JSON.parse(getActiveUserData());
      userData.currentStartDate = selectedDates.startDate;
      userData.currentEndDate = selectedDates.endDate;
      setActiveUserData(JSON.stringify(userData));
      setStartDate(selectedDates.startDate);
      setEndDate(selectedDates.endDate);
    }
  }, [selectedDates]);
  useEffect(() => {
    if (SERVER === "Production") {
      if (BUSINESS_IDS.includes(activeUser.businessId)) {
        setShowOld(false);
      } else {
        setShowOld(true);
      }
    }
  }, [SERVER, activeUser.businessId]);
  useEffect(() => {
    if (emissionsByCategory.length > 0) {
      //Step1. Filter out suppliers with no supplier
      let filteredEmissionsBySupplier = emissionsByCategory.filter(
        (contact) => contact.supplier !== "" && contact.supplier !== null
      );
      // console.log("filteredEmissionsBySupplier", filteredEmissionsBySupplier);
      //Step2. Generate a list of suppliers from filteredEmissionsBySupplier.contact & filteredEmissionsBySupplier.supplier
      let suppliers = [];
      filteredEmissionsBySupplier.forEach((item) => {
        let contact = item.contact;
        let supplier = item.supplier;
        if (item.dataSource === "Xero") {
          suppliers.push({
            contactId: contact.contactID,
            preferredName: contact.name || "",
            preferredEmail: contact.emailAddress || "",
            preferredABN: contact.taxNumber || "",
            supplierId: supplier.id || "",
            ...supplier,
          });
        } else if (item.dataSource === "MYOB") {
          suppliers.push({
            contactId: contact.UID,
            preferredName: contact.CompanyName || "",
            preferredEmail: "",
            preferredABN: contact.BuyingDetails.ABN || "",
            supplierId: supplier.id || "",
            ...supplier,
          });
        } else {
          suppliers.push({
            contactId: contact.id,
            preferredName: contact.name || "",
            preferredEmail: "",
            preferredABN: "",
            supplierId: supplier.id || "",
            ...supplier,
          });
        }
      });
      //Step3. Save the list of suppliers
      setSupplierDetails(suppliers);
    }
  }, [emissionsByCategory]);
  const dashboardProps = [
    {
      id: 1,
      title: "Supply Chain Emissions",
      amount: dashboardData?.totalEmissions?.toFixed(2),
      units: "tonnes CO2",
      icon: MdOutlineFactory,
      color: "var(--falcon-headings-color)",
    },
    {
      id: 2,
      title: "Surveys Sent",
      amount: dashboardData.surveysSent,
      units: "sent",
      icon: BsFillSendArrowUpFill,
      color: "var(--falcon-headings-color)",
    },
    {
      id: 3,
      title: "Survey Submitted",
      amount: dashboardData.surveysSubmitted,
      units: "submitted",
      icon: AiOutlineFileDone,
      color: "var(--falcon-headings-color)",
    },
    {
      id: 4,
      title: "Average Survey Score",
      amount: dashboardData.averageScore,
      units: "out of 100",
      icon: MdOutlineCalculate,
      color: "var(--falcon-headings-color)",
    },
  ];
  const handleDataChange = (newData) => {
    // console.log("Data drilled up =>",newData)
    setDashboardData(newData);
  };
  const setEngagement = (engaged) => {
    setSuppliersEngaged(engaged);
  };
  return (
    <>
      <PageHeader
        title="Supply Chain Breakdown"
        description={
          showDescription
            ? `The table below displays the various sources of emissions and their respective contributions, allowing you to identify areas for improvement and track progress over time. With this information, you can make informed decisions which of your suppliers contribute the most and where you can focus to reduce your carbon footprint and meet your sustainability goals.`
            : ""
        }
        className="mb-3"
      >
        <span
          onClick={() => setShowDescription(!showDescription)}
          style={{
            color: "grey",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {showDescription ? "Show Less" : "Show More"}
        </span>{" "}
      </PageHeader>
      <Col md="auto" className="mb-3 mb-md-0">
        <Row className="g-3 gy-md-0 h-100 align-items-center">
          <Col xs="auto">
            <h5 className="text-700 mb-3 text-nowrap">
              {`Showing Data For ${activeUser.selectedMeasurementYear} ${
                activeUser.selectedMeasurementYearType.charAt(0).toUpperCase() +
                activeUser.selectedMeasurementYearType.slice(1)
              } Year:`}
            </h5>
          </Col>
        </Row>
      </Col>
      <TransparencyScoreDashboard data={dashboardProps} />
      <div
        className="d-flex align-items-center justify-content-between mb-3"
        style={{ gap: 10 }}
      >
        <div style={{ flex: 1 }}>
          <DataDateFilter
            startDate={selectedDates.startDate}
            endDate={selectedDates.endDate}
            onChange={onChange}
          />
        </div>
        <div>
          {emissionsByCategory.length > 0 && (
            <Button
              variant="light"
              onClick={() => setShowSendSupplierDetailsModal(true)}
            >
              <MdDownload style={{ marginRight: 10 }} />
              Download Table
            </Button>
          )}
        </div>
        <div>
          {!suppliersEngaged ? (
            <Button
              className="cool-gradient-button"
              variant="primary"
              onClick={() => setShowEngageSuppliersModal(true)}
            >
              Engage Suppliers
            </Button>
          ) : (
            <Button
              variant="outline-success"
              onClick={() => setShowEngageSuppliersModal(true)}
            >
              <FontAwesomeIcon icon={faCheck} style={{ marginRight: "5px" }} />
              Suppliers Engaged
            </Button>
          )}
        </div>
      </div>
      <br />
      {startDate && endDate && (
        <>
          {/* <div className="alert alert-info">
            <p>
              <strong>NOTE:</strong> The data shown below is for the period{" "}
              {startDate.toLocaleDateString()} to {endDate.toLocaleDateString()}
            </p>
          </div> */}
          {/* {!showOld ? (
            <EmissionsBySupplier
              display={undefined}
              startDate={startDate}
              endDate={endDate}
            />
          ) : (
            <EmissiondBySupplierOld
              display={undefined}
              startDate={startDate}
              endDate={endDate}
            />
          )} */}
          <EmissionsBySupplier
            display={undefined}
            startDate={startDate}
            endDate={endDate}
            emissionsByCategory={emissionsByCategory}
            setEmissionsByCategory={setEmissionsByCategory}
            onDataChange={handleDataChange}
          />
          {showSendSupplierDetailsModal && (
            <SendSupplierDetailsModal
              show={showSendSupplierDetailsModal}
              handleClose={() => setShowSendSupplierDetailsModal(false)}
              suppliers={supplierDetails}
              businessId={activeUser.businessId}
              businessName={activeUser.businessName}
              reportingType={activeUser.selectedMeasurementYearType}
              reportingYear={activeUser.selectedMeasurementYear}
            />
          )}
          {showEngageSuppliersModal && (
            <EngageSuppliersModal
              show={showEngageSuppliersModal}
              handleClose={() => setShowEngageSuppliersModal(false)}
              setSuppliersEngaged={setEngagement}
              suppliersEngaged={suppliersEngaged}
            />
          )}
        </>
      )}
    </>
  );
};
export default SupplyChain;
